import React, { useState } from 'react';
import '../App.css';

const ColdGrid2 = ({ ColdGridData, grid2, ColdGridHeader }) => {

    const [columnWidths, setColumnWidths] = useState([200, 200]);

    const ResizeColumnWidth = (event, colIndex) => {
        event.preventDefault();

        const startX = event.clientX;
        const startWidth = columnWidths[colIndex];

        const handleMouseMove = (moveEvent) => {
            const newWidth = Math.max(50, startWidth + (moveEvent.clientX - startX));
            setColumnWidths((prev) => {
                const updated = [...prev];
                updated[colIndex] = newWidth;
                return updated;
            });
        };

        const handleMouseUp = () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

    return (
        <>

            <div>
                <div id="Cavity_Grid">
                    <div className="table-container"
                    >
                        <div className="table-scroll-wrapper">
                            <table className="excel-table">
                                <thead>
                                    <tr>
                                        {[
                                            `Weight Increase [${ColdGridHeader}]`,
                                            `% Weight Increase [${ColdGridHeader}]`,
                                        ].map((header, i) => (
                                            <th key={i} style={{ width: columnWidths[i] }}>
                                                {header}
                                                <div
                                                    className="column-resizer"
                                                    onMouseDown={(e) => ResizeColumnWidth(e, i)}
                                                />
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {ColdGridData.map((ColdData, key1) => {
                                        const prevValue = key1 > 0 ? ColdGridData[key1 - 1][grid2] : null;
                                        const currentValue = ColdData[grid2];

                                        const diff = key1 === 0
                                            ? "-"
                                            : isNaN(currentValue)
                                                ? "-"
                                                : (currentValue - prevValue).toFixed(3);

                                        const percentDiff = key1 === 0
                                            ? "-"
                                            : isNaN(currentValue)
                                                ? "-"
                                                : (((currentValue - prevValue) / prevValue) * 100).toFixed(3);

                                        return (
                                            <tr key={key1}>
                                                <td>{grid2 ? (currentValue ? diff : "--") : "-"}</td>
                                                <td>{grid2 ? (currentValue ? percentDiff : "--") : "-"}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default ColdGrid2;