import React, { useState, useRef, useEffect } from "react";
import "../App.css";
import "./database.css"
import { Button, Input, Label } from "reactstrap";

// Tab view component from syncfusion to navigate through six steps study
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";

import { ToastContainer } from "react-toastify";
import Header2 from "../common/header-component/header2";
import {
  get_user_units,
  get_units,
  get_materials,
  get_machines,
  get_molds,
} from "../../actions/auth";
import { connect } from "react-redux";
import MaterialDatabase from "./MaterialDatabase/Materialdb";
import MoldDatabase from "./MoldDatabase/MoldDB";
import MachineDatabase from "./MachineDatabase/Machinedb";

import { useHistory } from "react-router-dom";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import DatabasePrintable from "./DatabasePrintable";

const Database = ({
  user,
  get_user_units,
  get_units,
  get_materials,
  get_machines,
  get_molds,
  ...props
}) => {

  const tabInstance = useRef(null);
  let { TabIdx } = props.location;
  let TabName = ["Material Database", "Mold Database", "Machine Database"];

  const [ActiveTab, setActiveTab] = useState(TabName[0]);

  const materialData = useRef({
    GridColumn: [],
    GridData: []
  });
  const moldData = useRef({
    GridColumn: [],
    GridData: []
  });
  const machineData = useRef({
    GridColumn: [],
    GridData: []
  });

  const [selectedDatabases, setSelectedDatabases] = useState({
    material: false,
    mold: false,
    machine: false,
  });

  // Handle Checkbox Change
  const handleCheckboxChange = (e) => {
    setSelectedDatabases((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const history = useHistory();

  const BackToDashboard = () => {
    history.push("/dashboard");
  };

  let headertext = [
    { text: "Material Database" },
    { text: "Mold Database" },
    { text: "Machine Database" },
  ];

  const content0 = () => {
    return (
      <div id="material-table">
        <div>
          <MaterialDatabase materialData={materialData} />
        </div>
        <div className="m-2" id="PrintablePart">
          <DatabasePrintable
            materialData={materialData.current}
            moldData={moldData.current}
            machineData={machineData.current}
            selectedDatabases={selectedDatabases}
          />
        </div>
      </div>
    );
  }

  const content1 = () => {
    return (
      <div id="mold-table">
        <MoldDatabase moldData={moldData} />
      </div>
    );
  }

  const content2 = () => {
    return (
      <div id="machine-table">
        <MachineDatabase machineData={machineData} />
      </div>
    )
  }

  const tabSelected = (args) => {
    setActiveTab(TabName[args.selectedIndex]);
  };

  useEffect(() => {
    if (TabIdx) {
      tabInstance.current.select(TabIdx);
      setActiveTab(TabName[TabIdx]);
    }
    get_units();
  }, []);

  useEffect(() => {
    if (user) {
      get_user_units(user.id);
      get_materials(user.id);
      get_machines(user.id);
      get_molds(user.id);
    }
  }, [user]);

  useEffect(() => {
    sessionStorage.removeItem("SelectedMoldData");
  }, []);

  // Event to close the saving confirmation dialog
  const OpenDialog = () => {

    const dialogBox = document.getElementById("dialogBox");

    dialogBox.classList.remove("hidden");

  }

  // Event to close the saving confirmation dialog
  const closeDialog = () => {

    const dialogBox = document.getElementById("dialogBox");

    dialogBox.classList.add("hidden");

  }

  const handlePrint = async () => {

    closeDialog();

    // Create a new PDF
    const pdf = new jsPDF("l", "mm", "a4");

    let yOffset = 10; // Initial Y-position in PDF

    const element = document.getElementById("DatabasePrintable");

    // Capture the section as an image
    const canvas = await html2canvas(element);
    const imgData = canvas.toDataURL("image/jpeg");

    let imgWidth = 190; // Adjust width
    let imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

    // If image exceeds page, add new page
    if (yOffset + imgHeight > 280) { // A4 page height limit
      pdf.addPage();
      yOffset = 10;
    }

    // Add image to PDF
    pdf.addImage(imgData, "JPEG", 10, yOffset, imgWidth, imgHeight);
    yOffset += imgHeight + 10; // Add spacing

    // Save the PDF
    pdf.save("database.pdf");

    setSelectedDatabases({
      material: false,
      mold: false,
      machine: false,
    })
  };

  return (
    <>
      <Header2 Title="Databases" />

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex">
            <span
              onClick={BackToDashboard}
              className="BreadCrum"
              style={{ fontSize: "14px", color: "blue", cursor: "pointer" }}
            >
              Dashboard
            </span>
            <span className="BreadCrum" style={{ fontSize: "16px" }}>
              {" > "}
            </span>
            <span style={{ fontSize: "14px" }}> {ActiveTab} </span>
          </div>
        </div>

        <div className="position-relative">
          <div className="d-flex">
            <div className="col-md-12">
              <TabComponent
                ref={tabInstance}
                heightAdjustMode="Auto"
                id="defaultTab"
                selected={tabSelected}
              >
                <TabItemsDirective>
                  <TabItemDirective header={headertext[0]} content={content0} />
                  <TabItemDirective header={headertext[1]} content={content1} />
                  <TabItemDirective header={headertext[2]} content={content2} />
                </TabItemsDirective>
              </TabComponent>
            </div>
          </div>

          {/* Print Button - Opens Modal */}
          <div className="position-absolute" style={{ top: "10px", right: "20px" }}>
            <button className="btn btn-secondary btn-air-secondary" type="button" onClick={OpenDialog}>
              Print
            </button>
          </div>
        </div>

      </div>

      {/* To alert while going back to sessions page */}
      <div id="dialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <p> Select Databases to Print </p>
        <div className="dialog-content">
          <div className="form-check">
            <Input
              type="checkbox"
              name="material"
              id="material"
              checked={selectedDatabases.material} // ✅ Corrected Binding
              onChange={handleCheckboxChange}
            />
            <Label for="material" className="form-check-label">
              Material Database
            </Label>
          </div>
          <div className="form-check">
            <Input
              type="checkbox"
              name="mold"
              id="mold"
              checked={selectedDatabases.mold} // ✅ Corrected Binding
              onChange={handleCheckboxChange}
            />
            <Label for="mold" className="form-check-label">
              Mold Database
            </Label>
          </div>
          <div className="form-check">
            <Input
              type="checkbox"
              name="machine"
              id="machine"
              checked={selectedDatabases.machine} // ✅ Corrected Binding
              onChange={handleCheckboxChange}
            />
            <Label for="machine" className="form-check-label">
              Machine Database
            </Label>
          </div>

          <div className="d-flex justify-content-end">
            <Button className="mr-4" color="secondary" onClick={handlePrint}>
              Print Selected
            </Button>
            <Button color="secondary" onClick={closeDialog}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  get_units,
  get_user_units,
  get_materials,
  get_machines,
  get_molds,
})(Database);
