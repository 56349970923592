export let columndata = [
    {
        "id": 0,
        "header": "Cavity No",
        "edit": false,
        "delete": false
    },
    {
        "id": 1,
        "header": "Weight 1",
        "edit": true,
        "delete": false
    }
];

export let data2 = [
    [
       
    ],
    [
        
    ],
    [
        
    ]
];