import React from "react";

const InjectionPhaseGrid = ({ PPS_Data, Machine_Unit_Data }) => {
  const CreateInputData = (event) => {
    const { name, value, dataset } = event.target;

    let data = {
      paramID: dataset.id,
      paramName: name,
      paramUnit:
        dataset.section === "InjSpeed"
          ? Machine_Unit_Data.velocity.unit_id
          : dataset.section === "InjPress"
            ? Machine_Unit_Data.pressure.unit_id
            : "",
      paramValue: value,
    };

    PPS_Data.Inputs = [
      {
        ...PPS_Data.Inputs[0],
        [dataset.section]: [
          {
            ...PPS_Data.Inputs[0][dataset.section][0],
            [name]: data,
          },
        ],
      },
    ];
  };

  return (
    <div id="PPSGrid" className="InjectionPhaseGrid InputsGrid">
      <table>
        <thead>
          <tr>
            <th colSpan="14"> Injection Phase</th>
          </tr>
        </thead>
        <tbody>
          {/* <tr>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"43"}
                data-section={"InjSpeed"}
                name="fldInjSpeed5"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed5?.paramValue
                }
              />
            </td>
            <td rowSpan={2}></td>

            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"44"}
                data-section={"InjSpeed"}
                name="fldInjSpeed4"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed4?.paramValue
                }
              />
            </td>
            <td rowSpan={2}></td>

            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"45"}
                data-section={"InjSpeed"}
                name="fldInjSpeed3"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed3?.paramValue
                }
              />
            </td>
            <td rowSpan={2}></td>

            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"46"}
                data-section={"InjSpeed"}
                name="fldInjSpeed2"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed2?.paramValue
                }
              />
            </td>
            <td rowSpan={2}></td>

            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"47"}
                data-section={"InjSpeed"}
                name="fldInjSpeed1"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed1?.paramValue
                }
              />
            </td>
          </tr>

          <tr>
            <td> Inj Vel5 </td>

            <td> Inj Vel4 </td>

            <td> Inj Vel3 </td>

            <td> Inj Vel2 </td>

            <td> Inj Vel1 </td>
          </tr>

          <tr>
            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"48"}
                data-section={"InjPress"}
                name="fldInjPress5"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].InjPress[0].fldInjPress5?.paramValue
                }
              />
            </td>
            <td rowSpan={2}></td>

            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"49"}
                data-section={"InjPress"}
                name="fldInjPress4"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].InjPress[0].fldInjPress4?.paramValue
                }
              />
            </td>
            <td rowSpan={2}></td>

            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"50"}
                data-section={"InjPress"}
                name="fldInjPress3"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].InjPress[0].fldInjPress3?.paramValue
                }
              />
            </td>
            <td rowSpan={2}></td>

            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"51"}
                data-section={"InjPress"}
                name="fldInjPress2"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].InjPress[0].fldInjPress2?.paramValue
                }
              />
            </td>
            <td rowSpan={2}></td>

            <td>
              {" "}
              <input
                className="form-control"
                tabIndex={1}
                onKeyPress={(event) => {
                  if (!/[-0.0-9.0]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                data-id={"52"}
                data-section={"InjPress"}
                name="fldInjPress1"
                onChange={CreateInputData}
                defaultValue={
                  PPS_Data.Inputs[0].InjPress[0].fldInjPress1?.paramValue
                }
              />
            </td>
          </tr>

          <tr>
            <td> Inj Press5 </td>

            <td> Inj Press4 </td>

            <td> Inj Press3 </td>

            <td> Inj Press2 </td>

            <td> Inj Press1 </td>
          </tr> */}

          <tr>
            {[
              { name: "fldInjSpeed5", dataId: "43", section: "InjSpeed", value: PPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed5?.paramValue },
              { name: "fldInjSpeed4", dataId: "44", section: "InjSpeed", value: PPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed4?.paramValue },
              { name: "fldInjSpeed3", dataId: "45", section: "InjSpeed", value: PPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed3?.paramValue },
              { name: "fldInjSpeed2", dataId: "46", section: "InjSpeed", value: PPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed2?.paramValue },
              { name: "fldInjSpeed1", dataId: "47", section: "InjSpeed", value: PPS_Data.Inputs[0].InjSpeed[0].fldInjSpeed1?.paramValue }
            ].map(({ name, dataId, section, value }, index) => (
              <td key={index}>
                <input
                  className="form-control"
                  tabIndex={1}
                  onKeyPress={(event) => {
                    if (!/[-0.0-9.0]/.test(event.key)) {
                      event.preventDefault(); // Only numbers, negative sign and period are allowed
                    }
                  }}
                  onPaste={(event) => {
                    const pastedData = event.clipboardData.getData("text");

                    // Prevent pasting if the data contains any alphabetic characters
                    if (/[a-zA-Z]/.test(pastedData)) {
                      event.preventDefault(); // Block paste if alphanumeric characters are found
                    }
                  }}
                  data-id={dataId}
                  data-section={section}
                  name={name}
                  onChange={CreateInputData}
                  defaultValue={value}
                />
              </td>
            ))}
          </tr>

          <tr>
            <td>Inj Vel5</td>
            <td>Inj Vel4</td>
            <td>Inj Vel3</td>
            <td>Inj Vel2</td>
            <td>Inj Vel1</td>
          </tr>

          <tr>
            {[
              { name: "fldInjPress5", dataId: "48", section: "InjPress", value: PPS_Data.Inputs[0].InjPress[0].fldInjPress5?.paramValue },
              { name: "fldInjPress4", dataId: "49", section: "InjPress", value: PPS_Data.Inputs[0].InjPress[0].fldInjPress4?.paramValue },
              { name: "fldInjPress3", dataId: "50", section: "InjPress", value: PPS_Data.Inputs[0].InjPress[0].fldInjPress3?.paramValue },
              { name: "fldInjPress2", dataId: "51", section: "InjPress", value: PPS_Data.Inputs[0].InjPress[0].fldInjPress2?.paramValue },
              { name: "fldInjPress1", dataId: "52", section: "InjPress", value: PPS_Data.Inputs[0].InjPress[0].fldInjPress1?.paramValue }
            ].map(({ name, dataId, section, value }, index) => (
              <td key={index}>
                <input
                  className="form-control"
                  tabIndex={1}
                  onKeyPress={(event) => {
                    if (!/[-0.0-9.0]/.test(event.key)) {
                      event.preventDefault(); // Only numbers, negative sign and period are allowed
                    }
                  }}
                  onPaste={(event) => {
                    const pastedData = event.clipboardData.getData("text");

                    // Prevent pasting if the data contains any alphabetic characters
                    if (/[a-zA-Z]/.test(pastedData)) {
                      event.preventDefault(); // Block paste if alphanumeric characters are found
                    }
                  }}
                  data-id={dataId}
                  data-section={section}
                  name={name}
                  onChange={CreateInputData}
                  defaultValue={value}
                />
              </td>
            ))}
          </tr>

          <tr>
            <td>Inj Press5</td>
            <td>Inj Press4</td>
            <td>Inj Press3</td>
            <td>Inj Press2</td>
            <td>Inj Press1</td>
          </tr>

        </tbody>
      </table>
    </div>
  );
};

export default InjectionPhaseGrid;
