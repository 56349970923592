import React from "react";
import {
  ChartComponent,
  LineSeries,
  Inject,
  SeriesCollectionDirective,
  ScatterSeries,
  Category,
  DataLabel,
  SeriesDirective,
} from "@syncfusion/ej2-react-charts";
import {
  HtmlEditor,
  RichTextEditorComponent,
} from "@syncfusion/ej2-react-richtexteditor";

import "./CosmeticPrint.css"; // Custom CSS for styling

const CosmeticPrint = ({
  Melting,
  Hydraulic,
  CosmeticGridData,
  centerPoints,
  chartData,
  XniceNumbers,
  Mold_Name,
  Session_Name,
  YniceNumbers,
  textRender,
  Comment,
  PressureUnits,
  TemperatureUnits,
}) => {
  return (
    <div className="print-container">
      {/* Header Section */}
      <div className="header">
        <h5>Scientific Molding Worksheet No. 4 - Cosmetic Process</h5>
      </div>

      {/* Input Details */}
      <div className="section">
        <h3>Input Details</h3>
        <table className="styled-table">
          <thead>
            <tr>
              <th>Mold ID</th>
              <th>Session Name</th>
              <th>Pressure Units</th>
              <th>Temperature Units</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{Mold_Name}</td>
              <td>{Session_Name}</td>
              <td>{PressureUnits}</td>
              <td>{TemperatureUnits}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Data Grid Section */}
      <div className="section">
        <h3>Data View</h3>
        <table className="styled-table">
          <thead>
            <tr>
              <th>{Melting}</th>
              <th>Low {Hydraulic}</th>
              <th>High {Hydraulic}</th>
            </tr>
          </thead>
          <tbody>
            {CosmeticGridData.map((NewRow, rowId) => (
              <tr key={rowId}>
                <td>{NewRow.Melt_Temp}</td>
                <td>{NewRow.Low}</td>
                <td>{NewRow.High}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Chart Section */}
      <div className="section">
        <h3>Cosmetic Process Study Chart</h3>
        <ChartComponent
          width="100%"
          title="Cosmetic Process Study"
          primaryXAxis={{
            title: `${Melting}`,
            minimum: XniceNumbers.min,
            maximum: XniceNumbers.max,
            interval: XniceNumbers.step,
            lineStyle: { color: "black" },
          }}
          primaryYAxis={{
            title: `${Hydraulic}`,
            minimum: YniceNumbers.min,
            maximum: YniceNumbers.max,
            interval: YniceNumbers.step,
            lineStyle: { color: "black" },
          }}
          tooltip={{ enable: true, shared: false }}
          textRender={textRender}
        >
          <Inject services={[LineSeries, Category, DataLabel, ScatterSeries]} />
          <SeriesCollectionDirective>
            <SeriesDirective
              type="Line"
              dataSource={chartData}
              xName="x"
              yName="y"
              marker={{ visible: true }}
              fill="rgb(255,0,0)"
              width={2.5}
            />
            <SeriesDirective
              dataSource={centerPoints}
              xName="x"
              yName="y"
              width={2}
              marker={{
                dataLabel: { visible: true },
                shape: "Diamond",
                width: 10,
                height: 10,
              }}
              type="Scatter"
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>

      {/* Comments Section */}
      <div className="section">
        <h3>Comments</h3>
        <div className="comment-box">
          <RichTextEditorComponent value={Comment} height={160} width={800}>
            <Inject services={[HtmlEditor]} />
          </RichTextEditorComponent>
        </div>
      </div>
    </div>
  );
};

export default CosmeticPrint;
