import React, { useEffect, useState } from "react";
import {
    GridComponent,
    Inject,
    Toolbar,
    Freeze,
    Sort,
    ColumnDirective,
    ColumnsDirective,
} from "@syncfusion/ej2-react-grids";

// CSS
import "../App.css";

import { connect } from "react-redux";

const CompatibilityPrintable = ({
    MachineDataList,
    SelectedMold,
    CustomColumnData,
    MMData,
    PopulateMMGrid,
}) => {
    var grid;

    let StoredUnits = JSON.parse(sessionStorage.getItem("StoredUnits")) || [];

    let Suitable_Molds = [];

    const Can_Run_On_This_Mold = (props) => {
        // console.log(props)

        let ColObj = {
            "Type(Platen_Orientation)":
                props["Type(Platen_Orientation)"] ===
                SelectedMold[0]?.Platen_Orientation,

            Tonnage:
                !isNaN(parseFloat(props.Tonnage)) &&
                !isNaN(parseFloat(SelectedMold[0]?.Tonnage)) &&
                parseFloat(props.Tonnage) >= parseFloat(SelectedMold[0]?.Tonnage),

            "Max_shot_Capacity(Wt)": !isNaN(
                parseFloat(props["Max_shot_Capacity(Wt)"])
            )
                ? !isNaN(parseFloat(SelectedMold[0]?.Shot_Wt_GPPS))
                    ? parseFloat(props["Max_shot_Capacity(Wt)"]) >=
                    parseFloat(SelectedMold[0]?.Shot_Wt_GPPS)
                    : null // or handle the case when SelectedMold[0]?.Shot_Wt_GPPS is NaN
                : null, // or handle the case when props["Max_shot_Capacity(Wt)"] is NaN

            "Shot_Wt_(ACT)": !isNaN(parseFloat(props["Shot_Wt_(ACT)"]))
                ? !isNaN(parseFloat(SelectedMold[0]?.Shot_Wt_ACT))
                    ? parseFloat(props["Shot_Wt_(ACT)"]) >=
                    parseFloat(SelectedMold[0]?.Shot_Wt_ACT)
                    : null
                : null,

            "Shot_Wt_(PCT)":
                !isNaN(parseFloat(props["Shot_Wt_(PCT)"])) &&
                    !isNaN(parseFloat(MMData.Specify_Min)) &&
                    !isNaN(parseFloat(MMData.Specify_Max))
                    ? parseFloat(props["Shot_Wt_(PCT)"]) > parseInt(MMData.Specify_Min)
                        ? parseFloat(props["Shot_Wt_(PCT)"]) < parseInt(MMData.Specify_Max)
                        : null
                    : null,

            "Res_Time_(MACH)": !isNaN(parseFloat(props["Res_Time_(MACH)"]))
                ? !isNaN(parseFloat(SelectedMold[0]?.Material_Data.Max_Residence_Time))
                    ? parseFloat(props["Res_Time_(MACH)"]) <
                    parseFloat(SelectedMold[0]?.Material_Data.Max_Residence_Time)
                    : null
                : null,

            "Res_Time_(Hot_R)": !isNaN(parseFloat(props["Res_Time_(Hot_R)"]))
                ? !isNaN(parseFloat(SelectedMold[0]?.Material_Data.Max_Residence_Time))
                    ? parseFloat(props["Res_Time_(Hot_R)"]) <
                    parseFloat(SelectedMold[0]?.Material_Data.Max_Residence_Time)
                    : null
                : null,

            Total_Res_Time: !isNaN(parseFloat(props["Total_Res_Time"]))
                ? !isNaN(parseFloat(SelectedMold[0]?.Material_Data.Max_Residence_Time))
                    ? parseFloat(props["Total_Res_Time"]) <
                    parseFloat(SelectedMold[0]?.Material_Data.Max_Residence_Time)
                    : null
                : null,

            Min_allowable_Mold_Stack_Height: !isNaN(
                parseFloat(props.Min_allowable_Mold_Stack_Height)
            )
                ? !isNaN(parseFloat(SelectedMold[0]?.Mold_Stack_Height))
                    ? parseFloat(props.Min_allowable_Mold_Stack_Height) <
                    parseFloat(SelectedMold[0]?.Mold_Stack_Height)
                    : null
                : null,

            Max_allowable_Mold_Stack_Height: !isNaN(
                parseFloat(props.Max_allowable_Mold_Stack_Height)
            )
                ? !isNaN(parseFloat(SelectedMold[0]?.Mold_Stack_Height))
                    ? parseFloat(props.Max_allowable_Mold_Stack_Height) >
                    parseFloat(SelectedMold[0]?.Mold_Stack_Height)
                    : null
                : null,

            Max_Mold_Open_Daylight: !isNaN(parseFloat(props.Max_Mold_Open_Daylight))
                ? !isNaN(
                    parseFloat(SelectedMold[0]?.Mold_Stack_Height) +
                    parseFloat(SelectedMold[0]?.Mold_Vertical_Height)
                )
                    ? parseFloat(props.Max_Mold_Open_Daylight) >=
                    parseFloat(SelectedMold[0]?.Mold_Stack_Height) +
                    parseFloat(SelectedMold[0]?.Mold_Vertical_Height)
                    : null
                : null,

            Max_Mold_Width: !isNaN(parseFloat(props.Max_Mold_Width))
                ? !isNaN(parseFloat(SelectedMold[0]?.Mold_Width))
                    ? parseFloat(props.Max_Mold_Width) >
                    parseFloat(SelectedMold[0]?.Mold_Width)
                    : null
                : null,

            Max_Mold_Vertical_Height: !isNaN(
                parseFloat(props.Max_Mold_Vertical_Height)
            )
                ? !isNaN(parseFloat(SelectedMold[0]?.Mold_Vertical_Height))
                    ? parseFloat(props.Max_Mold_Vertical_Height) >
                    parseFloat(SelectedMold[0]?.Mold_Vertical_Height)
                    : null
                : null,

            Number_of_Core_Pulls: !isNaN(parseFloat(props.Number_of_Core_Pulls))
                ? !isNaN(parseFloat(SelectedMold[0]?.Number_of_Core_Pulls))
                    ? parseFloat(props.Number_of_Core_Pulls) >=
                    parseFloat(SelectedMold[0]?.Number_of_Core_Pulls)
                    : null
                : null,

            Material_Name: props.Material_Id.length > 0,
        };

        if (CustomColumnData.length > 0) {
            for (let i = 0; i < CustomColumnData.length; i++) {
                const operator = CustomColumnData[i].Operator;

                const fieldValue = props[CustomColumnData[i].field];

                const moldValue = SelectedMold[0]
                    ? parseFloat(SelectedMold[0][CustomColumnData[i].MoldField])
                    : "";

                ColObj[CustomColumnData[i].field] = compareValues(
                    operator,
                    fieldValue,
                    moldValue
                );

                // console.log( CustomColumnData[i] )

                // console.log(operator, fieldValue, moldValue);
            }
        }

        const allConditionsMet = Object.values(ColObj).every(
            (value) => value === true || value === null
        );

        if (allConditionsMet) {
            Suitable_Molds.push(props["Machine_Number"]);
        }

        sessionStorage.setItem("Suitable_Molds", JSON.stringify(Suitable_Molds));

        return (
            <div style={{ backgroundColor: allConditionsMet ? "green" : "red" }}>
                {allConditionsMet ? (
                    <span style={{ color: "white", fontWeight: "bold" }}>Yes</span>
                ) : (
                    <span style={{ color: "white", fontWeight: "bold" }}>No</span>
                )}
            </div>
        );
    };

    const [column, setColumn] = useState([
        {
            field: "Machine_Number",
            headerText: "Machine Number",
            textAlign: "center",
            width: "220",
        },
        {
            field: "Can_Run_On_This_Machine",
            headerText: "Can mold run on this machine",
            textAlign: "center",
            width: "200",
        },
        {
            field: "Type(Platen_Orientation)",
            headerText: "Machine Type",
            textAlign: "center",
            width: "120",
        },
        {
            field: "Tonnage",
            headerText: "Tonnage",
            textAlign: "center",
            width: "100",
        },
        {
            field: "Max_shot_Capacity(Wt)",
            headerText: "Shot Wt (GPPS)",
            textAlign: "center",
            width: "120",
        },
        {
            field: "Shot_Wt_(ACT)",
            headerText: "Shot Wt (ACT)",
            textAlign: "center",
            width: "100",
        },
        {
            field: "Shot_Wt_(PCT)",
            headerText: "% ShotSize",
            textAlign: "center",
            width: "100",
        },
        {
            field: "Res_Time_(MACH)",
            headerText: "Res Time (Mach)",
            textAlign: "center",
            width: "120",
        },
        {
            field: "Res_Time_(Hot_R)",
            headerText: "Res Time (Hot R)",
            textAlign: "center",
            width: "120",
        },
        {
            field: "Total_Res_Time",
            headerText: "Total Res Time",
            textAlign: "center",
            width: "100",
        },
        {
            field: "Min_allowable_Mold_Stack_Height",
            headerText: "Min Mold Stack Ht",
            textAlign: "center",
            width: "150",
        },
        {
            field: "Max_allowable_Mold_Stack_Height",
            headerText: "Max Mold Stack Ht",
            textAlign: "center",
            width: "130",
        },
        {
            field: "Max_Mold_Open_Daylight",
            headerText: "Max Mold Daylight",
            textAlign: "center",
            width: "130",
        },
        {
            field: "Max_Mold_Width",
            headerText: "Mold Width",
            textAlign: "center",
            width: "100",
        },
        {
            field: "Max_Mold_Vertical_Height",
            headerText: "Max Mold Vertical Height",
            textAlign: "center",
            width: "200",
        },
        {
            field: "Number_of_Core_Pulls",
            headerText: "Core Pulls",
            textAlign: "center",
            width: "100",
        },
        {
            field: "Material_Name",
            headerText: "Material",
            textAlign: "center",
            width: "110",
        },
    ]);

    const operators = {
        ">": (a, b) => a > b,
        "<": (a, b) => a < b,
        ">=": (a, b) => a >= b,
        "<=": (a, b) => a <= b,
        "===": (a, b) => a === b,
        "!==": (a, b) => a !== b,
    };

    const compareValues = (operator, a, b) => {
        // console.log(operator, a, b);

        if (operators[operator]) {
            return operators[operator](a, b);
        } else {
            throw new Error(`Unknown operator: ${operator}`);
        }
    };

    useEffect(() => {
        // console.log(CustomColumnData);
        // console.log(MachineDataList);
        // console.log(SelectedMold[0])
        // console.log(StoredUnits?.Unit_Data[1])
        // console.log(column)
    }, [SelectedMold]);

    const calculateGridWidth = (columns) => `${columns.length * 150}px`;

    return (
        <>
            {SelectedMold.length > 0 ? (
                <div
                    id="MMPrintGrid"
                    className="b-primary b-r-4 p-3 ml-2 MMTable"
                    style={{ border: "1px grey solid", width: calculateGridWidth(column) }}
                >
                    <div>
                        <h4> Mold - Machine Compatibility </h4>
                    </div>
                    <div className="d-flex">
                        <div>
                            <span className="mr-1" style={{ color: "red", fontSize: "20px" }}>
                                {" "}
                                *{" "}
                            </span>
                        </div>{" "}
                        <div>
                            <span style={{ color: "red" }}>
                                {" "}
                                Indicates data for that field is not available.{" "}
                            </span>
                        </div>
                    </div>
                    <GridComponent
                        id="MMPrintGrid"
                        dataSource={PopulateMMGrid ? MachineDataList : []}
                        columns={column}
                        width={calculateGridWidth(column)}
                        frozenColumns={2}
                    >
                        <ColumnsDirective>
                            <ColumnDirective
                                columns={[
                                    {
                                        field: "Machine_Number",
                                        headerText: "Machine Number",
                                        textAlign: "center",
                                        width: "220",
                                        template: (props) => {
                                            return (
                                                <div>
                                                    <span style={{ fontWeight: "bold" }}>
                                                        {" "}
                                                        {props["Machine_Number"]}{" "}
                                                    </span>
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                headerText="Mold Information - Requirement"
                            />

                            <ColumnDirective
                                columns={[
                                    {
                                        field: "Can_Run_On_This_Machine",
                                        headerText: "Can mold run on this machine",
                                        textAlign: "center",
                                        width: "200",
                                        template: Can_Run_On_This_Mold,
                                    },
                                ]}
                                headerText=""
                            />

                            <ColumnDirective
                                columns={[
                                    {
                                        field: "Type(Platen_Orientation)",
                                        headerText: "Machine Type",
                                        textAlign: "center",
                                        width: "120",
                                        template: (props) => {
                                            return (
                                                <div>
                                                    {props["Type(Platen_Orientation)"].toLowerCase() ===
                                                        SelectedMold[0]?.Platen_Orientation.toLowerCase() ? (
                                                        <span
                                                            style={{ color: "green", fontWeight: "bold" }}
                                                        >
                                                            {" "}
                                                            {props["Type(Platen_Orientation)"]}{" "}
                                                        </span>
                                                    ) : (
                                                        <span style={{ color: "red", fontWeight: "bold" }}>
                                                            {" "}
                                                            {props["Type(Platen_Orientation)"]}{" "}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                headerText={SelectedMold[0]?.Platen_Orientation}
                                textAlign="center"
                            />

                            <ColumnDirective
                                columns={[
                                    {
                                        field: "Tonnage",
                                        headerText: "Tonnage",
                                        textAlign: "center",
                                        width: 150,
                                        template: (props) => {
                                            return (
                                                <div>
                                                    {!isNaN(parseFloat(props.Tonnage)) &&
                                                        !isNaN(parseFloat(SelectedMold[0]?.Tonnage)) ? (
                                                        parseFloat(props.Tonnage) >=
                                                            parseFloat(SelectedMold[0]?.Tonnage) ? (
                                                            <span
                                                                style={{ color: "green", fontWeight: "bold" }}
                                                            >
                                                                {props.Tonnage}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                {props.Tonnage}
                                                            </span>
                                                        )
                                                    ) : (
                                                        <span
                                                            style={{ fontWeight: "bold", fontSize: "20px" }}
                                                        >
                                                            {"*"}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                headerText={
                                    SelectedMold[0]?.Tonnage
                                        ? `${SelectedMold[0]?.Tonnage} US tons`
                                        : ""
                                }
                                textAlign="center"
                            />

                            <ColumnDirective
                                columns={[
                                    {
                                        field: "Max_shot_Capacity(Wt)",
                                        headerText: "Shot Wt (GPPS)",
                                        textAlign: "center",
                                        width: "120",
                                        template: (props) => {
                                            return (
                                                <div>
                                                    {!isNaN(parseFloat(props["Max_shot_Capacity(Wt)"])) &&
                                                        !isNaN(parseFloat(SelectedMold[0]?.Shot_Wt_GPPS)) ? (
                                                        parseFloat(props["Max_shot_Capacity(Wt)"]) >=
                                                            parseFloat(SelectedMold[0]?.Shot_Wt_GPPS) ? (
                                                            <span
                                                                style={{ color: "green", fontWeight: "bold" }}
                                                            >
                                                                {props["Max_shot_Capacity(Wt)"]}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                {props["Max_shot_Capacity(Wt)"]}
                                                            </span>
                                                        )
                                                    ) : (
                                                        <span
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize:
                                                                    props["Max_shot_Capacity(Wt)"] === "*"
                                                                        ? "20px"
                                                                        : null,
                                                            }}
                                                        >
                                                            {props["Max_shot_Capacity(Wt)"]}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                headerText={
                                    SelectedMold[0] && !isNaN(SelectedMold[0]?.Shot_Wt_GPPS)
                                        ? `${SelectedMold[0]?.Shot_Wt_GPPS} Gms`
                                        : "*"
                                }
                                textAlign="center"
                            />

                            <ColumnDirective
                                columns={[
                                    {
                                        field: "Shot_Wt_(ACT)",
                                        headerText: "Shot Wt (ACT)",
                                        textAlign: "center",
                                        width: "100",
                                        template: (props) => {
                                            return (
                                                <div>
                                                    {!isNaN(parseFloat(props["Shot_Wt_(ACT)"])) &&
                                                        !isNaN(parseFloat(SelectedMold[0]?.Shot_Wt_ACT)) ? (
                                                        parseFloat(props["Shot_Wt_(ACT)"]) >=
                                                            parseFloat(SelectedMold[0]?.Shot_Wt_ACT) ? (
                                                            <span
                                                                style={{ color: "green", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props["Shot_Wt_(ACT)"]}{" "}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props["Shot_Wt_(ACT)"]}{" "}
                                                            </span>
                                                        )
                                                    ) : (
                                                        <span
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize:
                                                                    props["Shot_Wt_(ACT)"] === "*"
                                                                        ? "20px"
                                                                        : null,
                                                            }}
                                                        >
                                                            {props["Shot_Wt_(ACT)"]}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                headerText={
                                    SelectedMold[0] && !isNaN(SelectedMold[0]?.Shot_Wt_ACT)
                                        ? `${SelectedMold[0]?.Shot_Wt_ACT} Gms`
                                        : "*"
                                }
                                textAlign="center"
                            />

                            <ColumnDirective
                                columns={[
                                    {
                                        field: "Shot_Wt_(PCT)",
                                        headerText: "% ShotSize",
                                        textAlign: "center",
                                        width: "100",
                                        template: (props) => {
                                            return (
                                                <div>
                                                    {!isNaN(parseFloat(props["Shot_Wt_(PCT)"])) &&
                                                        !isNaN(parseFloat(MMData.Specify_Min)) &&
                                                        !isNaN(parseFloat(MMData.Specify_Max)) ? (
                                                        parseFloat(props["Shot_Wt_(PCT)"]) >
                                                            parseInt(MMData.Specify_Min) &&
                                                            parseFloat(props["Shot_Wt_(PCT)"]) <
                                                            parseInt(MMData.Specify_Max) ? (
                                                            <span
                                                                style={{ color: "green", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props["Shot_Wt_(PCT)"]}{" "}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props["Shot_Wt_(PCT)"]}{" "}
                                                            </span>
                                                        )
                                                    ) : (
                                                        <span
                                                            style={{
                                                                fontWeight: "bold",
                                                                fontSize:
                                                                    props["Shot_Wt_(PCT)"] === "*"
                                                                        ? "20px"
                                                                        : null,
                                                            }}
                                                        >
                                                            {props["Shot_Wt_(PCT)"]}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                headerText="-"
                                textAlign="center"
                            />

                            <ColumnDirective
                                columns={[
                                    {
                                        field: "Res_Time_(MACH)",
                                        headerText: "Res Time (Mach)",
                                        textAlign: "center",
                                        width: "120",
                                        template: (props) => {
                                            return (
                                                <div>
                                                    {!isNaN(parseFloat(props["Res_Time_(MACH)"])) &&
                                                        !isNaN(
                                                            parseFloat(
                                                                SelectedMold[0]?.Material_Data.Max_Residence_Time
                                                            )
                                                        ) ? (
                                                        parseFloat(props["Res_Time_(MACH)"]) <
                                                            parseFloat(
                                                                SelectedMold[0]?.Material_Data.Max_Residence_Time
                                                            ) ? (
                                                            <span
                                                                style={{ color: "green", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props["Res_Time_(MACH)"]}{" "}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props["Res_Time_(MACH)"]}{" "}
                                                            </span>
                                                        )
                                                    ) : (
                                                        <span
                                                            style={{ fontWeight: "bold", fontSize: "20px" }}
                                                        >
                                                            {props["Res_Time_(MACH)"]}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        field: "Res_Time_(Hot_R)",
                                        headerText: "Res Time (Hot R)",
                                        textAlign: "center",
                                        width: "120",
                                        template: (props) => {
                                            return (
                                                <div>
                                                    {!isNaN(parseFloat(props["Res_Time_(Hot_R)"])) &&
                                                        !isNaN(
                                                            parseFloat(
                                                                SelectedMold[0]?.Material_Data.Max_Residence_Time
                                                            )
                                                        ) ? (
                                                        parseFloat(props["Res_Time_(Hot_R)"]) <
                                                            parseFloat(
                                                                SelectedMold[0]?.Material_Data.Max_Residence_Time
                                                            ) ? (
                                                            <span
                                                                style={{ color: "green", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props["Res_Time_(Hot_R)"]}{" "}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props["Res_Time_(Hot_R)"]}{" "}
                                                            </span>
                                                        )
                                                    ) : (
                                                        <span
                                                            style={{ fontWeight: "bold", fontSize: "20px" }}
                                                        >
                                                            {props["Res_Time_(Hot_R)"]}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        field: "Total_Res_Time",
                                        headerText: "Total Res Time",
                                        textAlign: "center",
                                        width: "100",
                                        template: (props) => {
                                            return (
                                                <div>
                                                    {!isNaN(parseFloat(props["Total_Res_Time"])) &&
                                                        !isNaN(
                                                            parseFloat(
                                                                SelectedMold[0]?.Material_Data.Max_Residence_Time
                                                            )
                                                        ) ? (
                                                        parseFloat(props["Total_Res_Time"]) <
                                                            parseFloat(
                                                                SelectedMold[0]?.Material_Data.Max_Residence_Time
                                                            ) ? (
                                                            <span
                                                                style={{ color: "green", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props["Total_Res_Time"]}{" "}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props["Total_Res_Time"]}{" "}
                                                            </span>
                                                        )
                                                    ) : (
                                                        <span
                                                            style={{ fontWeight: "bold", fontSize: "20px" }}
                                                        >
                                                            {props["Total_Res_Time"]}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                headerText={
                                    SelectedMold[0] &&
                                        SelectedMold[0].Material_Data.Max_Residence_Time
                                        ? `${SelectedMold[0].Material_Data.Max_Residence_Time} min`
                                        : "*"
                                }
                                textAlign="center"
                            />

                            <ColumnDirective
                                columns={[
                                    {
                                        field: "Min_allowable_Mold_Stack_Height",
                                        headerText: "Min Mold Stack Ht",
                                        textAlign: "center",
                                        width: "150",
                                        template: (props) => {
                                            return (
                                                <div>
                                                    {!isNaN(
                                                        parseFloat(props.Min_allowable_Mold_Stack_Height)
                                                    ) &&
                                                        !isNaN(
                                                            parseFloat(SelectedMold[0]?.Mold_Stack_Height)
                                                        ) ? (
                                                        parseFloat(props.Min_allowable_Mold_Stack_Height) <
                                                            parseFloat(SelectedMold[0]?.Mold_Stack_Height) ? (
                                                            <span
                                                                style={{ color: "green", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props.Min_allowable_Mold_Stack_Height}{" "}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props.Min_allowable_Mold_Stack_Height}{" "}
                                                            </span>
                                                        )
                                                    ) : !isNaN(
                                                        parseFloat(props.Min_allowable_Mold_Stack_Height)
                                                    ) ? (
                                                        <span style={{ fontWeight: "bold" }}>
                                                            {parseFloat(
                                                                props.Min_allowable_Mold_Stack_Height
                                                            ).toFixed(2)}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{ fontWeight: "bold", fontSize: "20px" }}
                                                        >
                                                            {"*"}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                    {
                                        field: "Max_allowable_Mold_Stack_Height",
                                        headerText: "Max Mold Stack Ht",
                                        textAlign: "center",
                                        width: "150",
                                        template: (props) => {
                                            return (
                                                <div>
                                                    {!isNaN(
                                                        parseFloat(props.Max_allowable_Mold_Stack_Height)
                                                    ) &&
                                                        !isNaN(
                                                            parseFloat(SelectedMold[0]?.Mold_Stack_Height)
                                                        ) ? (
                                                        parseFloat(props.Max_allowable_Mold_Stack_Height) >
                                                            parseFloat(SelectedMold[0]?.Mold_Stack_Height) ? (
                                                            <span
                                                                style={{ color: "green", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props.Max_allowable_Mold_Stack_Height}{" "}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props.Max_allowable_Mold_Stack_Height}{" "}
                                                            </span>
                                                        )
                                                    ) : !isNaN(
                                                        parseFloat(props.Max_allowable_Mold_Stack_Height)
                                                    ) ? (
                                                        <span style={{ fontWeight: "bold" }}>
                                                            {parseFloat(
                                                                props.Max_allowable_Mold_Stack_Height
                                                            ).toFixed(2)}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{ fontWeight: "bold", fontSize: "20px" }}
                                                        >
                                                            {"*"}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                headerText={
                                    SelectedMold[0]?.Mold_Stack_Height
                                        ? `${SelectedMold[0]?.Mold_Stack_Height} ${StoredUnits?.Unit_Data[1].find(
                                            (unit) =>
                                                unit.unit_id ===
                                                parseInt(
                                                    SelectedMold[0]?.Unit_Data.Mold_Stack_Height
                                                        .unit_id
                                                )
                                        )?.unit_name
                                        }`
                                        : ""
                                }
                                textAlign="center"
                            />

                            <ColumnDirective
                                columns={[
                                    {
                                        field: "Max_Mold_Open_Daylight",
                                        headerText: "Max Mold Daylight",
                                        textAlign: "center",
                                        width: "150",
                                        template: (props) => {
                                            return (
                                                <div>
                                                    {!isNaN(parseFloat(props.Max_Mold_Open_Daylight)) &&
                                                        !isNaN(
                                                            parseFloat(SelectedMold[0]?.Mold_Stack_Height) +
                                                            parseFloat(SelectedMold[0]?.Mold_Vertical_Height)
                                                        ) ? (
                                                        parseFloat(props.Max_Mold_Open_Daylight) >=
                                                            parseFloat(SelectedMold[0]?.Mold_Stack_Height) +
                                                            parseFloat(
                                                                SelectedMold[0]?.Mold_Vertical_Height
                                                            ) ? (
                                                            <span
                                                                style={{ color: "green", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props.Max_Mold_Open_Daylight}{" "}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props.Max_Mold_Open_Daylight}{" "}
                                                            </span>
                                                        )
                                                    ) : !isNaN(
                                                        parseFloat(props.Max_Mold_Open_Daylight)
                                                    ) ? (
                                                        <span style={{ fontWeight: "bold" }}>
                                                            {parseFloat(props.Max_Mold_Open_Daylight).toFixed(
                                                                2
                                                            )}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{ fontWeight: "bold", fontSize: "20px" }}
                                                        >
                                                            {"*"}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                headerText={
                                    isNaN(
                                        parseFloat(SelectedMold[0]?.Mold_Stack_Height) +
                                        parseFloat(SelectedMold[0]?.Mold_Vertical_Height)
                                    )
                                        ? ""
                                        : `${parseFloat(SelectedMold[0]?.Mold_Stack_Height) +
                                        parseFloat(SelectedMold[0]?.Mold_Vertical_Height)
                                        } ${StoredUnits?.Unit_Data[1].find(
                                            (unit) =>
                                                unit.unit_id ===
                                                parseInt(
                                                    SelectedMold[0]?.Unit_Data.Mold_Stack_Height
                                                        .unit_id
                                                )
                                        )?.unit_name
                                        }`
                                }
                                textAlign="center"
                            />

                            <ColumnDirective
                                columns={[
                                    {
                                        field: "Max_Mold_Width",
                                        headerText: "Mold Width",
                                        textAlign: "center",
                                        width: "110",
                                        template: (props) => {
                                            return (
                                                <div>
                                                    {!isNaN(parseFloat(props.Max_Mold_Width)) &&
                                                        !isNaN(parseFloat(SelectedMold[0]?.Mold_Width)) ? (
                                                        parseFloat(props.Max_Mold_Width) >
                                                            parseFloat(SelectedMold[0]?.Mold_Width) ? (
                                                            <span
                                                                style={{ color: "green", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props.Max_Mold_Width}{" "}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props.Max_Mold_Width}{" "}
                                                            </span>
                                                        )
                                                    ) : !isNaN(parseFloat(props.Max_Mold_Width)) ? (
                                                        <span style={{ fontWeight: "bold" }}>
                                                            {parseFloat(props.Max_Mold_Width).toFixed(2)}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{ fontWeight: "bold", fontSize: "20px" }}
                                                        >
                                                            {"*"}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                headerText={
                                    SelectedMold[0]
                                        ? `${SelectedMold[0]?.Mold_Width} ${StoredUnits?.Unit_Data[1].find(
                                            (unit) =>
                                                unit.unit_id ===
                                                parseInt(
                                                    SelectedMold[0]?.Unit_Data.Mold_Width.unit_id
                                                )
                                        )?.unit_name
                                        }`
                                        : ""
                                }
                                textAlign="center"
                            />

                            <ColumnDirective
                                columns={[
                                    {
                                        field: "Max_Mold_Vertical_Height",
                                        headerText: "Max Mold Vertical Height",
                                        textAlign: "center",
                                        width: "200",
                                        template: (props) => {
                                            return (
                                                <div>
                                                    {!isNaN(parseFloat(props.Max_Mold_Vertical_Height)) &&
                                                        !isNaN(
                                                            parseFloat(SelectedMold[0]?.Mold_Vertical_Height)
                                                        ) ? (
                                                        parseFloat(props.Max_Mold_Vertical_Height) >
                                                            parseFloat(
                                                                SelectedMold[0]?.Mold_Vertical_Height
                                                            ) ? (
                                                            <span
                                                                style={{ color: "green", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props.Max_Mold_Vertical_Height}{" "}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props.Max_Mold_Vertical_Height}{" "}
                                                            </span>
                                                        )
                                                    ) : !isNaN(
                                                        parseFloat(props.Max_Mold_Vertical_Height)
                                                    ) ? (
                                                        <span style={{ fontWeight: "bold" }}>
                                                            {parseFloat(
                                                                props.Max_Mold_Vertical_Height
                                                            ).toFixed(2)}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{ fontWeight: "bold", fontSize: "20px" }}
                                                        >
                                                            {"*"}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                headerText={
                                    SelectedMold[0]
                                        ? `${SelectedMold[0]?.Mold_Vertical_Height} ${StoredUnits?.Unit_Data[1].find(
                                            (unit) =>
                                                unit.unit_id ===
                                                parseInt(
                                                    SelectedMold[0]?.Unit_Data.Mold_Vertical_Height
                                                        .unit_id
                                                )
                                        )?.unit_name
                                        }`
                                        : ""
                                }
                                textAlign="center"
                            />
                            <ColumnDirective
                                columns={[
                                    {
                                        field: "Number_of_Core_Pulls",
                                        headerText: "Core Pulls",
                                        textAlign: "center",
                                        width: "100",
                                        template: (props) => {
                                            return (
                                                <div>
                                                    {!isNaN(parseFloat(props.Number_of_Core_Pulls)) &&
                                                        !isNaN(
                                                            parseFloat(SelectedMold[0]?.Number_of_Core_Pulls)
                                                        ) ? (
                                                        parseFloat(props.Number_of_Core_Pulls) >=
                                                            parseFloat(
                                                                SelectedMold[0]?.Number_of_Core_Pulls
                                                            ) ? (
                                                            <span
                                                                style={{ color: "green", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props.Number_of_Core_Pulls}{" "}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props.Number_of_Core_Pulls}{" "}
                                                            </span>
                                                        )
                                                    ) : !isNaN(parseFloat(props.Number_of_Core_Pulls)) &&
                                                        props.Number_of_Core_Pulls !== "" ? (
                                                        <span style={{ fontWeight: "bold" }}>
                                                            {props.Number_of_Core_Pulls}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{ fontWeight: "bold", fontSize: "20px" }}
                                                        >
                                                            {"*"}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                headerText={SelectedMold[0]?.Number_of_Core_Pulls}
                                textAlign="center"
                            />

                            <ColumnDirective
                                columns={[
                                    {
                                        field: "Material_Name",
                                        headerText: "Material",
                                        textAlign: "center",
                                        width: "130",
                                        template: (props) => {
                                            return (
                                                <div>
                                                    {props.Material_Id.length > 0 ? (
                                                        <span
                                                            style={{ color: "green", fontWeight: "bold" }}
                                                        >
                                                            {" "}
                                                            {props.Material_Name}{" "}
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{
                                                                color: "red",
                                                                fontWeight: "bold",
                                                                textDecoration: "line-through",
                                                            }}
                                                        >
                                                            {props.Material_Name}
                                                        </span>
                                                    )}
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                                headerText={SelectedMold[0]?.Material_Name}
                                textAlign="center"
                            />

                            {CustomColumnData.map((ColumnData, key) => (
                                <ColumnDirective
                                    key={key}
                                    columns={[
                                        {
                                            field: `${ColumnData.field}`,
                                            headerText: `${ColumnData.MoldHeader} ${ColumnData.Operator} ${ColumnData.headerText}`,
                                            textAlign: "center",
                                            width:
                                                `${ColumnData.MoldHeader} ${ColumnData.Operator} ${ColumnData.headerText}`
                                                    .length *
                                                    10 >
                                                    200
                                                    ? `${ColumnData.MoldHeader} ${ColumnData.Operator} ${ColumnData.headerText}`
                                                        .length * 10
                                                    : 200,
                                            template: (props) => {
                                                const operator = `${ColumnData.Operator}`;
                                                const fieldValue = parseFloat(
                                                    props[`${ColumnData.field}`]
                                                );
                                                const moldValue = SelectedMold[0]
                                                    ? parseFloat(SelectedMold[0][ColumnData.MoldField])
                                                    : "";

                                                const isConditionMet = compareValues(
                                                    operator,
                                                    fieldValue,
                                                    moldValue
                                                );

                                                return (
                                                    <div>
                                                        {isConditionMet ? (
                                                            <span
                                                                style={{ color: "green", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props[`${ColumnData.field}`]}{" "}
                                                            </span>
                                                        ) : (
                                                            <span
                                                                style={{ color: "red", fontWeight: "bold" }}
                                                            >
                                                                {" "}
                                                                {props[`${ColumnData.field}`]}{" "}
                                                            </span>
                                                        )}
                                                    </div>
                                                );
                                            },
                                        },
                                    ]}
                                    headerText={
                                        SelectedMold[0] ? SelectedMold[0][ColumnData.MoldField] : ""
                                    }
                                    textAlign="center"
                                />
                            ))}
                        </ColumnsDirective>

                        <Inject services={[Toolbar, Freeze, Sort]} />
                    </GridComponent>
                </div>
            ) : (
                <div
                    style={{
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: "1.5rem",
                    }}
                >
                    <span>
                        Select the Mold and Process Sheet session to view the Compatibility
                        here.
                    </span>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.user,
});

export default connect(mapStateToProps)(CompatibilityPrintable);
