import React, { useRef } from "react";
import "../../assets/custom-stylesheet/app2_style.css";
import "../../assets/custom-stylesheet/samplepage_style.css";
import "../App.css";
import "../../assets/custom-stylesheet/grid_stylecss.css";
import "../../../node_modules/@syncfusion/ej2-base/styles/material.css";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import ScientificMold from "./ScientificMold";
import SixSteps from "./SixSteps";
import MoldQualificatios from "./MoldQualification";
import { useHistory } from "react-router-dom";
import GeneralTrouble from "./GeneralTroubleshooting/GenralTrouble";
import { connect } from "react-redux";
import axios from "axios";
import { Button } from "reactstrap";

const LearningCenter = ({ user }) => {

  const history = useHistory();

  let headertext = [
    { text: "General TroubleShooting" },
    { text: "Scientific Molding Concepts" },
    { text: "6 Step Procedures" },
    { text: "Mold Qualification Flowchart" },
  ];

  const showAlert = useRef(false);

  function content0() {
    return (
      <div className="card p-3 ml-2 b-dark">
        <div>
          <button
            className="btn btn-primary btn-air-primary m-2"
            type="button"
            onClick={SavedModal}
          >
            Save
          </button>
        </div>
        <GeneralTrouble showAlert={showAlert} GeneralData={GeneralData} user={user} ToSaveGeneralData={ToSaveGeneralData} />
      </div>
    );
  }

  function content1() {
    return (
      <div className="card p-3 ml-2 b-dark">
        <ScientificMold />
      </div>
    );
  }

  function content2() {
    return (
      <div className="card p-3 ml-2 b-dark">
        <SixSteps />
      </div>
    );
  }

  function content3() {
    return (
      <div className="card p-3 ml-2 b-dark">
        <MoldQualificatios />
      </div>
    );
  }

  // Boolean variable to switch between the save and update button
  const ToSaveGeneralData = useRef(true)

  const GeneralData = useRef([]);

  // This function will be called when the user clicks on the save button of the study
  const SavedModal = () => {
    saveData().then(() => {
      const saveddialogBox = document.getElementById("SaveddialogBox");

      saveddialogBox.classList.remove("hidden");
    });
  };

  // Instance of dialog box which will be shown after saving
  const SavedandExitdialogBox = document.getElementById(
    "SavedandExitdialogBox"
  );

  // Event to close the saving confirmation dialog
  const closeDialog = () => {
    const dialogBox = document.getElementById("dialogBox");

    dialogBox.classList.add("hidden");
  };

  // Event to finally close and exit study after saving
  const closeandExitSavedDialog = () => {
    const saveddialogBox = document.getElementById("SavedandExitdialogBox");

    saveddialogBox.classList.add("hidden");

    history.push("/dashboard")
  };

  // Event to close the dialog which will be shown after saving
  const closeSavedDialog = () => {
    const saveddialogBox = document.getElementById("SaveddialogBox");

    saveddialogBox.classList.add("hidden");
  };

  const SaveandExit = () => {
    saveData().then(() => {
      closeDialog();
      SavedandExitdialogBox.classList.remove("hidden");
    });
  };

  // Convert content to a Blob and trigger download
  const saveAsBlob = async () => {
    const blob = new Blob([GeneralData.current], {
      type: "text/html",
    }); // Replace with your RTE content

    const formData = new FormData();
    formData.append("mold", user.id);
    formData.append("content", blob, "rte-content.html"); // Append blob to the form data

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/mold_session/upload_General_blob/`,
        {
          method: "POST",
          body: formData,
        }
      );

      ToSaveGeneralData.current = false;

      showAlert.current = false
    } catch (error) {
      // console.error("Error uploading blob:", error);
    }
  };

  // Convert content to a Blob and trigger download
  const UpdateBlob = async () => {
    const blob = new Blob([GeneralData.current], {
      type: "text/html",
    }); // Replace with your RTE content

    const formData = new FormData();
    formData.append("content", blob, "rte-content.html"); // Append blob to the form data

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/mold_session/update_General_content_by_mold/${user.id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      showAlert.current = false

      // console.log(response);
    } catch (error) {
      // console.error("Error uploading blob:", error);
    }
  };

  // Event to Call the POST request API and save the data
  const saveData = () => {
    return new Promise((resolve, reject) => {
      if (ToSaveGeneralData.current) {

        saveAsBlob();
      } else {

        UpdateBlob();
      }

      resolve();
    });
  }

  const BackToDashboard = () => {
    if (showAlert.current) {
      const dialogBox = document.getElementById("dialogBox");
      dialogBox.classList.remove("hidden");
    } else {
      history.push("/dashboard");
    }
  };

  return (
    <>

      {/* To alert while going back to sessions page */}
      <div id="dialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Do you want to save General Trouble Shooting Data ? </p>
          <Button className="mr-1" id="closeDialogBtn" onClick={SaveandExit}>
            {" "}
            Yes{" "}
          </Button>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeandExitSavedDialog}
          >
            {" "}
            No{" "}
          </Button>
          <Button className="mr-1" id="closeDialogBtn" onClick={closeDialog}>
            {" "}
            Cancel{" "}
          </Button>
        </div>
      </div>

      {/* To show when data is saved and when clicked on close button exit */}
      <div id="SavedandExitdialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Saved Successfully. </p>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeandExitSavedDialog}
          >
            {" "}
            Close{" "}
          </Button>
        </div>
      </div>

      {/* To show when data is saved, when clicked on saved button */}
      <div id="SaveddialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Saved Successfully. </p>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeSavedDialog}
          >
            {" "}
            Close{" "}
          </Button>
        </div>
      </div>

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex">

            <div>
              <span
                className="BreadCrum"
                onClick={BackToDashboard}
                style={{ fontSize: "14px", color: "blue" }}
              >
                {" "}
                Dashboard{" "}
              </span>
            </div>

            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>

            <div>
              <span style={{ fontSize: "14px" }}> Learning Center </span>
            </div>

          </div>
        </div>
      </div>

      <div>

        <div>
          <TabComponent
            heightAdjustMode="Auto"
            id="defaultTab"
          >
            <TabItemsDirective>
              <TabItemDirective header={headertext[0]} content={content0} />
              <TabItemDirective header={headertext[1]} content={content1} />
              <TabItemDirective header={headertext[2]} content={content2} />
              <TabItemDirective header={headertext[3]} content={content3} />
            </TabItemsDirective>
          </TabComponent>
        </div>

      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, {
})(LearningCenter);