import React, { useState } from 'react';
import "./Grid.css"; // Import CSS

const CavityGrid2 = ({ column, CavityGridData, Total, Average, MaxPart, MinPart, Percentage, Range }) => {

    const [columnWidths, setColumnWidths] = useState(Array(7).fill(100)); // Default column width (100px)

    const ResizeColumnWidth = (event, colIndex) => {

        event.preventDefault();

        let startX = event.clientX;
        let startWidth = columnWidths[colIndex];

        const handleMouseMove = (moveEvent) => {
            const newWidth = Math.max(50, startWidth + (moveEvent.clientX - startX)); // Prevent shrinking too much
            setColumnWidths((prevWidths) => {
                const updatedWidths = [...prevWidths];
                updatedWidths[colIndex] = newWidth;
                return updatedWidths;
            });

            document.querySelector("table").style.width = "auto"; // Allow table expansion
        };

        const handleMouseUp = () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };

        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);
    };

    return (
        <div>
            <div id="Cavity_Grid">
                <div className="table-container"
                >
                    <div className="table-scroll-wrapper">
                        <table id="Cavity_Sheet"
                            className="excel-table" bordered striped>
                            <thead>
                                <tr>
                                    {column.map((colName, colIndex) => (
                                        <th key={colIndex} style={{
                                            width: `${columnWidths[colIndex]}px`,
                                        }}>
                                            {colName.header}
                                            <div
                                                className="column-resizer"
                                                onMouseDown={(event) => ResizeColumnWidth(event, colIndex)}
                                            />
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ border: "1px solid white" }}>Total Fill</td>
                                    {Total.map((value, index) => <td key={index}>{isNaN(value) ? '-' : (value).toFixed(2) || '-'}</td>)}
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid white" }}>Average Fill</td>
                                    {Average.map((value, index) => <td key={index}>{value || '-'}</td>)}
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid white" }}>Range</td>
                                    {Range.map((value, index) => <td key={index}>{isFinite(value) ? value : '-'}</td>)}
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid white" }}>Max Part Wt.</td>
                                    {MaxPart.map((value, index) => <td key={index}>{isFinite(value) ? value : '-'}</td>)}
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid white" }}>Min Part Wt.</td>
                                    {MinPart.map((value, index) => <td key={index}>{isFinite(value) ? value : '-'}</td>)}
                                </tr>
                                <tr>
                                    <td style={{ border: "1px solid white" }}>% Variation from Average</td>
                                    {column.slice(1).map((_, index) => <td key={index}>--</td>)}
                                </tr>
                                {CavityGridData.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        <td style={{ border: "1px solid white" }}>{row["Cavity_No"]}</td>
                                        {Percentage.map((col, colIndex) => (
                                            <td key={colIndex}>{col[rowIndex] === 0 ? 0 : col[rowIndex] || '-'}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default CavityGrid2;
