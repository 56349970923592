import React, { useState, useRef, useEffect } from "react";
import "./Grid.css"; // Import CSS
import { useParams } from 'react-router-dom';

const CavityBalanceGrid = ({ data, setData, column, CavityGridData, setCavityGridData, setColToBeDeleted, setPercentage, setTotal, setAverage, setRange, setMaxPart, setMinPart, ToSaveCavityData }) => {

  const CavityTableRef = useRef(null);

  // To get the Session Id from url using useParams hook
  var { moldId, sessionId } = useParams();

  // Variable to store the a mold Id and Session Id
  const [mold_Id, setmold_Id] = useState();
  const [session_Id, setsessionId] = useState();

  // useEffect Hook to do the calculation of the data entered in the Grid
  useEffect(() => {

    setmold_Id(atob(moldId))
    setsessionId(atob(sessionId))

    const Total_Average = () => {

      const calculateAverage = (arr) => {
        if (arr.length === 0) {
          return 0;
        }

        const sum = arr.reduce((acc, num) => acc + num, 0);
        return sum;
      };

      // console.log( CavityGridData )

      let columnTotal = [], columnAverage = [], columnRange = [], columnMaxPart = [], columnMinPart = [], columnPercent = [];

      for (let i = 1; i < column.length; i++) {

        let total = 0, average = 0, range, Range_Array = [], Range_Array_for_Percent = [], max, min, percent = [];

        const compare = (a, b) => {
          return a - b;
        }

        for (let j = 1; j <= CavityGridData.length; j++) {

          if (CavityGridData[j - 1][`value${i}`] != null) {

            Range_Array_for_Percent.push(parseFloat(CavityGridData[j - 1][`value${i}`]))

          }

          if (CavityGridData[j - 1][`value${i}`] != null) {

            Range_Array.push(parseFloat(CavityGridData[j - 1][`value${i}`]))

          }
          else {

            Range_Array.push(0)

          }

          // console.log( Range_Array )

          total = calculateAverage(Range_Array)

          average = Number(parseFloat(total) / parseInt(Range_Array.length)).toFixed(3)

          const Sorted_Array = Range_Array.sort(compare)

          // console.log(Sorted_Array)

          max = Math.max(...Sorted_Array)

          min = Math.min(...Sorted_Array)

          range = Number(Math.max(...Sorted_Array) - Math.min(...Sorted_Array)).toFixed(2)

        }

        // console.log(Range_Array_for_Percent)

        for (let k = 1; k <= Range_Array_for_Percent.length; k++) {

          percent.push(isNaN((Range_Array_for_Percent[k - 1] - average) * 100 / average) ? 0 : Number(((Range_Array_for_Percent[k - 1] - average) * 100 / average).toFixed(3)))

        }

        // console.log(percent)

        columnPercent[i - 1] = percent
        setPercentage(columnPercent)

        columnTotal[i - 1] = total
        setTotal(columnTotal)

        columnAverage[i - 1] = parseFloat(average)
        setAverage(columnAverage)

        columnRange[i - 1] = parseFloat(range)
        setRange(columnRange)

        columnMaxPart[i - 1] = max
        setMaxPart(columnMaxPart)

        columnMinPart[i - 1] = min
        setMinPart(columnMinPart)

      }
    }

    Total_Average()

  }, [moldId, session_Id, mold_Id, sessionId, CavityGridData, column, column.length, setAverage, setMaxPart, setMinPart, setPercentage, setRange, setTotal])

  const table = CavityTableRef.current;

  useEffect(() => {

    // Focus the first cell (Row 0, Column 0) when the component mounts
    const firstCell = table?.querySelector(`[data-row="0"][data-col="1"]`);
    if (firstCell) {
      firstCell.focus()
    }

  }, [])

  useEffect(() => {

    setTimeout(() => {

      if (ToSaveCavityData.current && CavityGridData.length > 0 && CavityGridData[0]?.value1 === undefined) {
        const GridArrayData = CavityGridData.map(obj => [obj.Cavity_No, ""])
        setData(GridArrayData);
      }

    }, 1000)

  }, [CavityGridData])

  const [isSelecting, setIsSelecting] = useState(false);
  const [selectionRange, setSelectionRange] = useState({ startRow: null, startCol: null, endRow: null, endCol: null });

  // Handle Mouse Down (Start Selection)
  const handleMouseDown = (rowIndex, colIndex) => {
    if ([0].includes(colIndex)) return; // Prevent selecting non-editable columns

    setIsSelecting(true);
    setSelectionRange({ startRow: rowIndex, startCol: colIndex, endRow: rowIndex, endCol: colIndex });
  };

  // Handle Mouse Move (Continue Selection)
  const handleMouseMove = (rowIndex, colIndex) => {
    if (!isSelecting) return;
    if ([0].includes(colIndex)) return; // Ignore non-editable cells

    setSelectionRange((prev) => ({
      ...prev,
      endRow: rowIndex,
      endCol: colIndex
    }))
  }

  // Handle Mouse Up (Stop Selection)
  const handleMouseUp = () => {
    setIsSelecting(false);
  };

  const isCellSelected = (rowIndex, colIndex) => {
    const { startRow, startCol, endRow, endCol } = selectionRange;

    if (startRow === null || startCol === null || endRow === null || endCol === null) return false;

    const minRow = Math.min(startRow, endRow);
    const maxRow = Math.max(startRow, endRow);
    const minCol = Math.min(startCol, endCol);
    const maxCol = Math.max(startCol, endCol);

    return rowIndex >= minRow && rowIndex <= maxRow && colIndex >= minCol && colIndex <= maxCol;
  };

  const handlePaste = (event) => {
    event.preventDefault();
    const clipboardData = event.clipboardData.getData("text");

    // Convert clipboard data into rows and columns
    const rows = clipboardData.trim().split("\n").map(row => row.split("\t"));

    // Find the first selected cell
    const selectedCell = document.activeElement;
    const startRow = parseInt(selectedCell.dataset.row, 10);
    const startCol = parseInt(selectedCell.dataset.col, 10);

    setData((prevData) => {
      let newData = [...prevData];

      // **Insert Pasted Data**
      rows.forEach((row, rowIndex) => {
        row.forEach((cell, colIndex) => {
          const targetRow = startRow + rowIndex;
          const targetCol = startCol + colIndex;

          if (
            targetRow >= newData.length ||
            targetCol >= newData[0].length
          ) {
            return; // Skip out-of-bound pastes
          }

          // **Sanitize the pasted data**
          let sanitizedValue = cell.replace(/[^0-9.]/g, ""); // Remove non-numeric characters except '.'

          // Prevent multiple decimal points (e.g., "1..1" -> "1.1")
          const decimalCount = (sanitizedValue.match(/\./g) || []).length;
          if (decimalCount > 1) {
            sanitizedValue = sanitizedValue.split(".")[0] + "." + sanitizedValue.split(".").slice(1).join("");
          }

          // Prevent "." at the start (".123" -> "0.123")
          if (sanitizedValue.startsWith(".")) {
            sanitizedValue = "0" + sanitizedValue;
          }

          newData[targetRow][targetCol] = sanitizedValue; // Update with cleaned value

          const table = CavityTableRef.current;

          // **Move Cursor to End of Text**
          setTimeout(() => {
            const cellElement = table?.querySelector(`[data-row='${targetRow}'][data-col='${targetCol}']`);
            if (cellElement) {
              const range = document.createRange();
              const selection = window.getSelection();
              range.selectNodeContents(cellElement);
              range.collapse(false); // Move cursor to end
              selection.removeAllRanges();
              selection.addRange(range);
            }
          }, 0);
        });
      });

      // console.log(newData)

      return newData;
    });

  };

  // Handle copying data from table
  const handleCopy = (event) => {
    event.preventDefault();

    const table = CavityTableRef.current;
    const selectedCells = Array.from(table?.querySelectorAll(".selected"));

    if (selectedCells.length === 0) return;

    let copiedData = {};

    selectedCells.forEach((cell) => {
      const row = parseInt(cell.dataset.row, 10);
      const col = parseInt(cell.dataset.col, 10);

      if (!copiedData[row]) copiedData[row] = {};
      copiedData[row][col] = cell.innerText.trim();
    });

    let clipboardText = Object.keys(copiedData)
      .sort((a, b) => a - b)
      .map((rowIndex) => {
        const rowCells = copiedData[rowIndex];
        const cols = Object.keys(rowCells)
          .map(Number)
          .sort((a, b) => a - b);
        return cols.map((col) => rowCells[col] || "").join("\t");
      })
      .join("\n");

    event.clipboardData.setData("text/plain", clipboardText);
  };

  // Handle enter key to move to the next row
  const handleKeyDown = (event, rowIndex, colIndex) => {

    let nextRow = rowIndex;

    const table = CavityTableRef.current;

    // **Handle Enter Key (Move to Next Row)**
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent line break in contentEditable

      nextRow = rowIndex + 1; // Move to next row

      if (nextRow < data.length) {
        const nextCell = table?.querySelector(`[data-row='${nextRow}'][data-col='${colIndex}']`);

        if (nextCell) {
          nextCell.focus();
          return; // Stop further processing so arrow keys don’t interfere
        }
        event.preventDefault();
      }
    }

    // Get the current cell element
    const cell = event.target;
    const selection = window.getSelection();

    // Get cursor position (offset)
    const cursorPos = selection.focusOffset;

    // Get the text inside the cell
    const textLength = cell.innerText.length;

    // Handle Arrow Right (Move within text, then jump to next cell)
    if (event.key === "ArrowRight") {
      if (cursorPos < textLength) {
        return; // Allow cursor to move within text
      }
      // If at the end of text, move to the next cell
      const nextCell = table?.querySelector(`[data-row='${rowIndex}'][data-col='${colIndex + 1}']`);
      if (nextCell) {
        nextCell.focus();
      }
      event.preventDefault();
    }

    // Handle Arrow Left (Move within text, then jump to previous cell)
    if (event.key === "ArrowLeft") {
      if (cursorPos > 0) {
        return; // Allow cursor to move within text
      }
      // If at the beginning of text, move to the previous cell
      const prevCell = table?.querySelector(`[data-row='${rowIndex}'][data-col='${colIndex - 1}']`);
      if (prevCell) {
        prevCell.focus();
      }
      event.preventDefault();
    }

    // Handle Arrow Down (Move to the cell below)
    if (event.key === "ArrowDown") {
      const nextRowCell = table?.querySelector(`[data-row='${rowIndex + 1}'][data-col='${colIndex}']`);
      if (nextRowCell) {
        nextRowCell.focus();
      }
      event.preventDefault();
    }

    // Handle Arrow Up (Move to the cell above)
    if (event.key === "ArrowUp") {
      const prevRowCell = table?.querySelector(`[data-row='${rowIndex - 1}'][data-col='${colIndex}']`);
      if (prevRowCell) {
        prevRowCell.focus();
      }
      event.preventDefault();
    }

    // **Restrict input to numbers and one decimal**
    if (!/[0-9.]/.test(event.key) &&
      !["Backspace", "Delete", "ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown", "Tab", "Enter"].includes(event.key) &&
      !(event.ctrlKey || event.metaKey)) {
      event.preventDefault();
    }

    // Prevent multiple decimals
    if (event.key === "." && event.target.innerText.includes(".")) {
      event.preventDefault();
    }

    GetValues()

  };

  // Handle cell selection for copying
  const handleCellClick = (rowIndex, colIndex, event) => {

    if (colIndex !== 0) setColToBeDeleted(colIndex)

    const table = CavityTableRef.current;

    if (event.ctrlKey || event.metaKey) {
      event.target.classList.toggle("selected");
    } else {
      table?.querySelectorAll(".selected").forEach((cell) => {
        cell.classList.remove("selected");
      });
      event.target.classList.add("selected");
    }

  };

  const handleOnInput = (event) => {

    // Save cursor position before modifying text
    const selection = window.getSelection();
    const range = document.createRange();
    const cursorPosition = selection.focusOffset;

    // Restore cursor position to prevent text reversal
    range.setStart(event.target.childNodes[0] || event.target, cursorPosition);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  }

  const [columnWidths, setColumnWidths] = useState(Array(7).fill(100)); // Default column width (100px)

  const ResizeColumnWidth = (event, colIndex) => {

    event.preventDefault();

    let startX = event.clientX;
    let startWidth = columnWidths[colIndex];

    const handleMouseMove = (moveEvent) => {
      const newWidth = Math.max(50, startWidth + (moveEvent.clientX - startX)); // Prevent shrinking too much
      setColumnWidths((prevWidths) => {
        const updatedWidths = [...prevWidths];
        updatedWidths[colIndex] = newWidth;
        return updatedWidths;
      });

      document.querySelector("table").style.width = "auto"; // Allow table expansion
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const getCellElement = (rowIndex, colIndex) => {
    const table = CavityTableRef.current;
    return table?.querySelector(`[data-row="${rowIndex}"][data-col="${colIndex}"]`);
  };

  const GetValues = () => {
    let dataObj = {},
      dataArray = [];

    for (let i = 0; i < CavityGridData.length; i++) {
      for (let j = 0; j < column.length; j++) {
        if (j === 0) {
          dataObj["Cavity_No"] = getCellElement(i, j)?.innerText;
        } else {
          dataObj[`value${j}`] = getCellElement(i, j)?.innerText;
        }
      }

      dataArray.push(dataObj);

      dataObj = {};
    }

    setCavityGridData(dataArray)

    // console.log(CavityGridData)

  };

  return (
    <div id="Cavity_Grid">
      <div className="table-container"
      // onFocus={
      //     getData
      // }
      >
        <div className="table-scroll-wrapper">
          <table
            ref={CavityTableRef}
            id="Cavity_Sheet"
            className="excel-table"
            onPaste={handlePaste}
            onCopy={handleCopy}
            onMouseUp={handleMouseUp}
          >
            <thead>
              <tr>
                {column.map((colName, colIndex) => (
                  <th key={colIndex} style={{
                    width: `${columnWidths[colIndex]}px`,
                  }}>
                    {colName.header}
                    <div
                      className="column-resizer"
                      onMouseDown={(event) => ResizeColumnWidth(event, colIndex)}
                    />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {row.map((cell, colIndex) => (
                    <td
                      key={colIndex}
                      contentEditable={colIndex > 0}
                      data-row={rowIndex}
                      data-col={colIndex}
                      onInput={handleOnInput}
                      onBlur={GetValues}
                      onClick={(e) => handleCellClick(rowIndex, colIndex, e)}
                      onKeyDown={(e) => handleKeyDown(e, rowIndex, colIndex)}
                      className={isCellSelected(rowIndex, colIndex) ? "selected e-cell" : "e-cell"}
                      onMouseDown={() => handleMouseDown(rowIndex, colIndex)}
                      onMouseMove={() => handleMouseMove(rowIndex, colIndex)}
                      suppressContentEditableWarning={true}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default CavityBalanceGrid;