import React from "react";
import {
  ChartComponent,
  LineSeries,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  DataLabel,
} from "@syncfusion/ej2-react-charts";

import {
  HtmlEditor,
  RichTextEditorComponent,
} from "@syncfusion/ej2-react-richtexteditor";

import "../../assets/custom-stylesheet/excel_table.css";
import "./ViscosityPrint.css"; // Custom CSS file for styling

const ViscosityPrint = ({
  Injection_Speed_Units,
  IntensificationRatio,
  Pressure_Units,
  Injection_Speed,
  Mold_Name,
  Session_Name,
  Comment,
  ShearRateNiceNumbers,
  niceNumbers,
  InjectionniceNumbers,
  InjectionSpeedArray,
  YTitle,
}) => {
  return (
    <div className="print-container">
      {/* Header Section */}
      <div className="header">
        <h5>Scientific Molding Worksheet No. 1 - Viscosity Curve</h5>
      </div>

      {/* Input Details */}
      <div className="section">
        <h3>Input Details</h3>
        <table className="styled-table">
          <thead>
            <tr>
              <th>Mold ID</th>
              <th>Session Name</th>
              <th>Injection Speed Units</th>
              <th>Intensification Ratio</th>
              <th>Pressure Units</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{Mold_Name}</td>
              <td>{Session_Name}</td>
              <td>{Injection_Speed_Units ? Injection_Speed_Units : "in/sec"}</td>
              <td>{IntensificationRatio}</td>
              <td>{Pressure_Units ? Pressure_Units : "N/A"}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Grid/Table Section */}
      <div className="section">
        <h3>Data View</h3>
        <div className="excel-table">
          <table className="styled-table">
            <thead>
              <tr>
                <th>Injection Speed</th>
                <th>Fill Time</th>
                <th>Peak Inj Press</th>
                <th>Viscosity</th>
                <th>Shear Rate</th>
                <th>Absolute Drop Viscosity</th>
                <th>% Drop Viscosity</th>
              </tr>
            </thead>
            <tbody>
              {InjectionSpeedArray.map((NewRow, rowId) => (
                <tr key={rowId}>
                  <td>{NewRow["Injection Speed"]}</td>
                  <td>{NewRow["FillTime (sec)"]}</td>
                  <td>{NewRow["Peak Inj Press"]}</td>
                  <td>{NewRow["Viscosity"] ? NewRow["Viscosity"] : "-"}</td>
                  <td>{NewRow["Shear Rate"] ? NewRow["Shear Rate"] : "-"}</td>
                  <td>
                    {rowId === 0
                      ? "-"
                      : InjectionSpeedArray[rowId].Viscosity === "" ||
                        InjectionSpeedArray[rowId - 1].Viscosity === "" ||
                        InjectionSpeedArray[rowId - 1].Viscosity === 0
                        ? "-"
                        : Math.round(
                          InjectionSpeedArray[rowId - 1].Viscosity -
                          InjectionSpeedArray[rowId].Viscosity
                        )}
                  </td>
                  <td>
                    {rowId === 0
                      ? "-"
                      : InjectionSpeedArray[rowId].Viscosity === "" ||
                        InjectionSpeedArray[rowId - 1].Viscosity === "" ||
                        InjectionSpeedArray[rowId - 1].Viscosity === 0
                        ? "-"
                        : Number(
                          ((Math.round(
                            InjectionSpeedArray[rowId - 1].Viscosity -
                            InjectionSpeedArray[rowId].Viscosity
                          ) *
                            100) /
                            InjectionSpeedArray[rowId - 1].Viscosity).toFixed(1)
                        )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Charts Section */}
      <div className="section">
        <h3>Viscosity Chart</h3>
        <ChartComponent
          width="100%"
          title="Viscosity Curve"
          primaryXAxis={{
            title: `Injection Speed (${Injection_Speed_Units})`,
            valueType: "Double",
            lineStyle: { color: "black" },
            minimum: InjectionniceNumbers.min,
            maximum: InjectionniceNumbers.max,
            interval: InjectionniceNumbers.step,
          }}
          primaryYAxis={{
            title: YTitle === "" ? ` - sec` : `${YTitle} - sec`,
            lineStyle: { color: "black" },
            minimum: niceNumbers.min,
            maximum: niceNumbers.max,
            interval: niceNumbers.step,
          }}
        >
          <Inject services={[LineSeries, Category, DataLabel]} />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={InjectionSpeedArray}
              type="Line"
              xName="Injection Speed"
              yName="Viscosity"
              marker={{ visible: true }}
              width={2.5}
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>

      {/* Comment Section */}
      <div className="section">
        <h3>Comments</h3>
        <div className="comment-box">
          <RichTextEditorComponent value={Comment} height={160} width={700}>
            <Inject services={[HtmlEditor]} />
          </RichTextEditorComponent>
        </div>
      </div>
    </div>
  );
};

export default ViscosityPrint;
