export let Demodata = [
    {
        "id": 0,
        "header": "Time",
        "edit": true,
        "delete": false
    },
    {
        "id": 1,
        "header": "Weight 1",
        "edit": true,
        "delete": false
    }
]

export let data2 = [
    {
        "id": 0,
        "edit": true
    },
    {
        "id": 1,
        "edit": true
    },
    {
        "id": 2,
        "edit": true
    },
    {
        "id": 3,
        "edit": true
    },
    {
        "id": 4,
        "edit": true
    }
];