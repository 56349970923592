import React from "react";
import {
  ChartComponent,
  LineSeries,
  SeriesCollectionDirective,
  Inject,
  SeriesDirective,
  Category,
  DataLabel,
} from "@syncfusion/ej2-react-charts";
import { HtmlEditor, RichTextEditorComponent } from "@syncfusion/ej2-react-richtexteditor";

import "./PressurePrint.css"; // Custom CSS for styling

const PressurePrint = ({
  Units,
  Max_Press_Available,
  PressureGridRow,
  Mold_Name,
  Session_Name,
  MaxPressData,
  Comment,
  ChartData,
  niceNumbers,
}) => {
  return (
    <div className="print-container">
      {/* Header Section */}
      <div className="header">
        <h5> Scientific Molding Worksheet No. 3 - Pressure Drop Study</h5>
      </div>

      {/* Input Details */}
      <div className="section">
        <h3>Input Details</h3>
        <table className="styled-table">
          <thead>
            <tr>
              <th>Mold ID</th>
              <th>Session Name</th>
              <th>Units</th>
              <th>Max Pressure Available</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{Mold_Name}</td>
              <td>{Session_Name}</td>
              <td>{Units || "psi"}</td>
              <td>{Max_Press_Available}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Data Grid Section */}
      <div className="section">
        <h3>Data View</h3>
        <table className="styled-table">
          <thead>
            <tr>
              <th>Flow Area</th>
              <th>Peak Pressure</th>
              <th>% Maximum</th>
              <th>Delta P</th>
              <th>% Delta P</th>
            </tr>
          </thead>
          <tbody>
            {PressureGridRow.map((PressureRow, rowId) => (
              <tr key={rowId}>
                <td>{PressureRow.Flow_Area}</td>
                <td>{PressureRow.Peak_Pressure}</td>
                <td>
                  {PressureGridRow[rowId].Percent_Maximum === ""
                    ? "-"
                    : Number(PressureGridRow[rowId].Percent_Maximum).toFixed(3)}
                </td>
                <td>
                  {PressureRow.Peak_Pressure
                    ? rowId === 0
                      ? PressureGridRow[rowId].Peak_Pressure
                      : PressureGridRow[rowId].Peak_Pressure === ""
                        ? "-"
                        : Math.round(
                          PressureGridRow[rowId].Peak_Pressure -
                          PressureGridRow[rowId - 1].Peak_Pressure
                        )
                    : "--"}
                </td>
                <td>
                  {PressureRow.Peak_Pressure
                    ? rowId === 0
                      ? PressureGridRow[rowId].Percent_Maximum === ""
                        ? "-"
                        : Number(PressureGridRow[rowId].Percent_Maximum).toFixed(3)
                      : PressureGridRow[rowId].Peak_Pressure === ""
                        ? "-"
                        : Number(
                          (PressureGridRow[rowId].Peak_Pressure -
                            PressureGridRow[rowId - 1].Peak_Pressure) *
                          100 /
                          Max_Press_Available
                        ).toFixed(3)
                    : "--"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Chart Section */}
      <div className="section">
        <h3>Pressure Drop Study Chart</h3>
        <ChartComponent
          width="100%"
          title="Pressure Drop Study"
          primaryXAxis={{ valueType: "Category", title: "Flow Area" }}
          primaryYAxis={{
            title: "Peak Pressure",
            minimum: niceNumbers.min,
            maximum: niceNumbers.max,
            interval: niceNumbers.step,
          }}
        >
          <Inject services={[LineSeries, Category, DataLabel]} />
          <SeriesCollectionDirective>
            <SeriesDirective
              type="Line"
              dataSource={MaxPressData}
              xName="Flow_Area"
              yName="Max_Press_Available"
              fill="rgb(255,0,0)"
              width={2.5}
            />
            <SeriesDirective
              type="Line"
              dataSource={ChartData}
              xName="Flow_Area"
              yName="Peak_Pressure"
              marker={{ visible: true }}
              width={2.5}
            />
          </SeriesCollectionDirective>
        </ChartComponent>
      </div>

      {/* Comments Section */}
      <div className="section">
        <h3>Comments</h3>
        <div className="comment-box">
          <RichTextEditorComponent value={Comment} height={160} width={700}>
            <Inject services={[HtmlEditor]} />
          </RichTextEditorComponent>
        </div>
      </div>
    </div>
  );
};

export default PressurePrint;
