import React, { useState, useEffect, useRef } from "react";

// Chart from syncfusion
import {
  ChartComponent,
  LineSeries,
  Inject,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  DataLabel,
  ScrollBar,
  Zoom,
  Tooltip,
  Legend,
} from "@syncfusion/ej2-react-charts";

// Button From Bootstrap
import { Button } from "reactstrap";

// Modal To add column's
import Cavity from "../columns&rows/CavityAddColumn";

// To generate random Id
import { nanoid } from "nanoid";

// Grid in which the calculated data will be shown
import CavityGrid2 from "../Grids/CavityGrid2";

// CSS file
import "../App.css";

// Import TextEditor Functionality from Syncfusion
import {
  BLOCK_TAGS,
  HtmlEditor,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";

// Importing Modal Component from Bootstrap
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { columndata } from "../data/cavity_balance_data";

// Modal to Edit Column Header
import CavityEdit from "../modals/CavityEdit";

// Event having multiple method's to deal with the back-end
import DataService from "../../services/ApiService";

// Hook to get the parameter's from the URL
import { useParams } from "react-router-dom";

import CavityBalanceGrid from "../Grids/CavityBalanceGrid";

// An Instance of the syncfusion chart
export let chartInstance;

const CavityBalance = ({
  showAlert,
  CavityData,
  ToSaveCavityData,
  show_Print,
  CavityComponentRef
}) => {

  // State and Event for the Edit and Add Modal
  const [modal, setModal] = useState();
  const [modal2, setModal2] = useState();

  const toggle = () => {
    setModal(!modal);
  };

  const toggle2 = () => {
    setModal2(!modal2);
  };

  // State and Event for the comment modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Undo",
      "Redo",
    ],
  };

  // A state variable to store and set the value of textarea
  const [Comment, setComment] = useState("");

  // Event to set the data entered in the textarea
  const getComment = (e) => {
    if (e.target) {
      setComment(e.target.value);
    } else {
      setComment(e.value); // Get the RTE value
      showAlert.current = true;
    }
  };

  const row = useRef([]);
  const [header, setHeader] = useState();
  const [column, setColumn] = useState(columndata);
  const [toggleEdit, setToggleEdit] = useState(true);
  const [CavityGridData, setCavityGridData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [isColumnId, setIsColumnId] = useState(null);

  // States to store the calculated values
  let [Total, setTotal] = useState([]);
  let [Average, setAverage] = useState([]);
  let [Range, setRange] = useState([]);
  let [MaxPart, setMaxPart] = useState([]);
  let [MinPart, setMinPart] = useState([]);
  let [Percentage, setPercentage] = useState([]);

  const [data, setData] = useState([])

  const [Alert, setAlert] = useState(false);

  const [ColToBeDeleted, setColToBeDeleted] = useState();

  const [colCount, setColCount] = useState(column.length);

  const [ColHeader, seColHeader] = useState("");

  const addHeader = (e) => {
    e.preventDefault();

    setHeader(e.target.value);
  };

  const CheckForHeaderName = () => {
    return new Promise((resolve, reject) => {
      let HeaderExists;

      // console.log(column);

      HeaderExists = column.some(
        (headers) => headers.header.toLowerCase() === header.toLowerCase()
      );

      resolve(HeaderExists);

      // console.log(HeaderExists);
    });
  };

  const addColumn = () => {

    const addEmptyColumn = (data) => {
      return data.map(row => [...row, ""]);
    };

    if (!header) {
    } else {
      CheckForHeaderName().then((HeaderExists) => {

        if (!HeaderExists) {

          const newColumn = {
            id: nanoid(),
            header: header,
            edit: true,
            delete: true,
          };

          const updatedData = addEmptyColumn(data);

          setData(updatedData);

          setColumn([...column, newColumn]);

          setColCount(colCount + 1);

          showAlert.current = true;

          setTimeout(() => {
            setHeader("");

            setAlert(true);
          }, 500);
        } else {
          alert("Column header text cannot be duplicated");
        }
      });
    }
  }

  const editColumnHeader = () => {
    if (header && !toggleEdit) {
      setColumn(
        column.map((element) => {
          if (element.id === isColumnId) {
            return { ...element, header: header };
          }
          return element;
        })
      );

      setHeader("");

      setIsColumnId(null);

      showAlert.current = true;

      setAlert(true);
    } else {
    }
  };

  // State to Toggle the Delete Confirmation Modal.
  const [DeleteAlertToggle, setDeleteAlertToggle] = useState(false);
  const [DeleteConfirmToggle, setDeleteConfirmToggle] = useState(false);

  const DeleteModalAlertToggle = () => {
    setDeleteAlertToggle(!DeleteAlertToggle);
  };

  const DeleteModalConfirmToggle = () => {
    if (!ColToBeDeleted || column.length <= 2) {
      setDeleteAlertToggle(!DeleteAlertToggle);
    } else {
      setDeleteConfirmToggle(!DeleteConfirmToggle);
      seColHeader(column[ColToBeDeleted].header);
    }
  };

  const transformData = (input) => {
    return input.map(item => {
      const row = [item.Cavity_No];

      // Dynamically include all "value*" keys
      Object.keys(item).forEach(key => {
        if (key.startsWith("value")) {
          row.push(item[key]);
        }
      });

      return row;
    });
  };

  const deleteColumn = () => {

    const removeColumn = (data, colIndexToRemove) => {
      return data.map(row => row.filter((_, colIndex) => colIndex !== colIndexToRemove));
    };

    const updatedData = removeColumn(data, ColToBeDeleted);

    setData(updatedData);

    setColumn((prevArray) => {
      const newArray = [...prevArray];

      newArray.splice(ColToBeDeleted, 1);

      return newArray;
    });

    for (let j = 1; j < column.length; j++) {
      for (let i = 0; i < CavityGridData.length; i++) {
        delete CavityGridData[i][`value${ColToBeDeleted}`];
        // console.log(CavityGridData[i][`value${ColToBeDeleted}`])
      }
    }

    let GridArrayData = transformData(CavityGridData);

    setData(GridArrayData)

    setDeleteConfirmToggle(!DeleteConfirmToggle);

    // console.log(dataArray)

    showAlert.current = true;

    setAlert(true);
  };

  const editColumn = (id) => {
    setIsColumnId(id);
    setToggleEdit(false);
  };

  const editCancel = () => {
    setIsColumnId(null);
    setToggleEdit(true);
  };

  // To get Id's from url using useParams hook
  var { moldId, sessionId, moldName, sessionName } = useParams();

  // Variable to store the a Mold/Session Name
  const [Mold_Name, setMold_Name] = useState();
  const [Session_Name, setSession_Name] = useState();

  // Session Id getting from the URL
  const [SessionId, setSessionId] = useState();

  // To Store the Number of Parts in that Mold
  const [No_of_Parts, setNo_of_Parts] = useState();

  // Array to store the mold data fetched
  const Data = [];

  // Variable to store the a mold Id
  const [mold_Id, setmold_Id] = useState();

  useEffect(() => {
    // Event to generate the row's of cavity
    const fetchMold = () => {
      let code;

      // Array to store the mold data fetched
      const Data = [];

      // Checking for Mold Id
      if (mold_Id) {
        // Making the GET API call for getting current selected Mold's Detail's
        DataService.GetMold(mold_Id)
          .then((res) => {
            // Checking whether are getting any Data in response
            if (res.data) {
              // Storing the response Data in an Local Array
              Data.push(res.data);

              // console.log(res.data)

              setNo_of_Parts(Data[0].Number_Of_Parts);

              // 1st for loop will execute as many times as number of the part's in that mold
              for (let i = 1; i <= No_of_Parts; i++) {
                if (isNaN(Data[0].Part_Data[3][`Part${i}`])) {
                  // console.log('1st')

                  for (let j = 1; j <= Data[0].Part_Data[2][`Part${i}`]; j++) {
                    code = Data[0].Part_Data[3][`Part${i}`].charCodeAt(0);

                    // Here the part number along with Starting cavity number will get pushed inside the row array until the limit of number of cavities of that part exceeds
                    row.current.push({
                      id: nanoid(),
                      Cavity_No:
                        Data[0].Part_Data[1][`Part${i}`] +
                        "-" +
                        String.fromCharCode(code + (j - 1)),
                    });
                  }

                  code = null;
                } else {
                  // console.log('2nd')

                  // 2nd for loop will execute as many times as number of cavities in that part
                  for (let j = 1; j <= Data[0].Part_Data[2][`Part${i}`]; j++) {
                    // Here the part number along with Starting cavity number will get pushed inside the row array until the limit of number of cavities of that part exceeds
                    row.current.push({
                      id: nanoid(),
                      Cavity_No: isNaN(Data[0].Part_Data[1][`Part${i}`])
                        ? Data[0].Part_Data[1][`Part${i}`] +
                        "-" +
                        (parseInt(Data[0].Part_Data[3][`Part${i}`]) + (j - 1))
                        : Data[0].Part_Data[1][`Part${i}`] +
                        "--" +
                        (parseInt(Data[0].Part_Data[3][`Part${i}`]) +
                          (j - 1)),
                    });
                  }
                }
              }

            } else {
              setCavityGridData([]);
            }
          })
          .catch((err) => {
            setCavityGridData([]);
          });
      } else {
      }
    };

    fetchMold();

    // eslint-disable-next-line
  }, [No_of_Parts, mold_Id, row, setCavityGridData, setNo_of_Parts]);

  const [state, set] = useState(0);

  useEffect(() => {
    fn();
  }, [state]);

  function fn() {
    setTimeout(() => {
      set((prev) => prev + 1);
    }, 500);
  }

  // Event the GET current session's cavity data
  const handleGet = (SessionId) => {
    if (SessionId) {

      DataService.FetchCavity(SessionId)
        .then((res) => {

          if (res.data) {
            ToSaveCavityData.current = false;

            setColumn(res.data.Column_Details);
            setCavityGridData(res.data.Column_Data);
            let GridArrayData = transformData(res.data.Column_Data);
            setData(GridArrayData)
            setComment(res.data.Comment);
            setNo_of_Parts(res.data.Number_Of_Parts);
          } else {

            // const GridArrayData = row.current.map(obj => [obj.Cavity_No, ""])
            // setData(GridArrayData)

            setColumn(columndata);
            setCavityGridData(row.current)

          }
        })
        .catch((err) => { });
    } else {
      setColumn(columndata);
      setCavityGridData(row.current)
    }
  }

  useEffect(() => {

    const data = {
      session: SessionId,
      Column_Details: column,
      Column_Data: CavityGridData,
      Comment: Comment ? Comment : "N/A",
      Number_Of_Parts: No_of_Parts ? No_of_Parts : "",
      PrintData: {
        column: column,
        CavityGridData: CavityGridData,
        Total: Total,
        Average: Average,
        MaxPart: MaxPart,
        MinPart: MinPart,
        Percentage: Percentage,
        Range: Range,
        chartData: chartData,
        chartData2: chartData2,
        Mold_Name: Mold_Name,
        Session_Name: Session_Name,
        Comment: Comment,
      },
    };

    CavityData.current = data;

    // eslint-disable-next-line
  }, [CavityGridData, Comment, column]);

  useEffect(() => {
    // On load it decrypt's the session Id
    setSessionId(atob(sessionId));

    setMold_Name(atob(moldName));
    setSession_Name(atob(sessionName));

    // After that it call's this event to fetch the data
    handleGet(SessionId);

    // eslint-disable-next-line
  }, [sessionId, moldName, sessionName, SessionId, show_Print]);

  if (Alert) {
    window.onbeforeunload = function () {
      return "";
    };
  } else {
  }

  useEffect(() => {
    setmold_Id(atob(moldId));

    const handleChange = (e) => {
      if (chartInstance) chartInstance.refresh();
    };

    if (ToPlotChart) handleChange();

    // eslint-disable-next-line
  }, [CavityGridData, moldId]);

  const CreateChartData = () => {
    // An Array to store the Array's as per number of mold's and a variable used while getting the data the from CavityGridData
    let TotalArray = [],
      index = 0;

    // Checking for Mold Id
    if (mold_Id) {
      // Making the GET API call for getting current selected Mold's Detail's
      DataService.GetMold(mold_Id)
        .then((res) => {
          setToPlotChart(false);

          // Checking whether are getting any Data in response
          if (res.data) {
            // Storing the response Data in an Local Array
            Data.push(res.data);

            // 1st for loop will execute as many times as number of the part's in that mold
            for (let i = 1; i <= No_of_Parts; i++) {
              // As per parts, pushing an array in TotalArray to store the data got from CavityGridData Array of the respective parts
              TotalArray.push([]);

              // 2nd for loop will execute as many times as number of cavities in that part
              for (let j = 1; j <= Data[0].Part_Data[2][`Part${i}`]; j++) {
                // Data From CavityGridData is getting pushed in the TotalArray based on the index variable
                TotalArray[i - 1].push(CavityGridData[index]);

                // A variable based on which the object inside the CavityGridData will get pushed
                index++;
              }
            }

            setChartData2(TotalArray);

            // console.log(TotalArray)
          } else {
            setChartData2([]);
          }
        })
        .catch((err) => setChartData2([]));
    } else {
    }
  };

  // Event to Scroll to Chart
  const PlotChart = () => {
    CreateChartData();

    setChartData(column);
  };

  const [ToPlotChart, setToPlotChart] = useState(true);

  useEffect(() => {
    if (ToPlotChart) {
      setTimeout(() => {
        PlotChart();
      }, 1000);

      // CavitySpreadsheet.current.refresh()
    }

    // eslint-disable-next-line
  }, [PlotChart])

  return (
    <>
      <div className="cavity pb-2"
      // onMouseMove={GenerateRows}
      >
        <div>
          <Modal isOpen={DeleteAlertToggle} centered={true}>
            <ModalHeader toggle={DeleteModalAlertToggle}>
              Delete Alert.
            </ModalHeader>

            <ModalBody>
              {column.length > 2
                ? "Select a column to delete"
                : "Atleast one column is mandatory."}
            </ModalBody>

            <ModalFooter>
              <Button color="dark" onClick={DeleteModalAlertToggle}>
                {" "}
                Close{" "}
              </Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={DeleteConfirmToggle} centered={true}>
            <ModalHeader toggle={DeleteModalConfirmToggle}>
              Delete Confirmation.
            </ModalHeader>

            <ModalBody>
              {ColHeader
                ? `Are you sure you want to delete ${ColHeader} column ?`
                : ""}
            </ModalBody>

            <ModalFooter>
              <Button color="dark" onClick={deleteColumn}>
                {" "}
                Delete{" "}
              </Button>
              <Button color="dark" onClick={DeleteModalConfirmToggle}>
                {" "}
                Close{" "}
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        <div className="cavity-main-grid grid-chart-container">
          <section className="card sixstepCard p-3 ml-2">
            <div className="justify-content-between b-primary b-r-4 mb-1">
              <div className="row" style={{ display: BLOCK_TAGS }}>
                <div className="d-flex col-md-12">
                  <div className="grid_container_btn">
                    <Cavity
                      toggle2={toggle2}
                      modal2={modal2}
                      addHeader={addHeader}
                      addColumn={addColumn}
                    />
                  </div>

                  <div className="grid_container_btn">
                    <CavityEdit
                      modal={modal}
                      toggle={toggle}
                      column={column}
                      addHeader={addHeader}
                      editColumnHeader={editColumnHeader}
                      editCancel={editCancel}
                      editColumn={editColumn}
                    />
                  </div>

                  <div className="ml-2 grid_container_btn">
                    <button
                      className="btn btn-warning btn-air-warning"
                      type="button"
                      onClick={DeleteModalConfirmToggle}
                    >
                      {" "}
                      Delete Column{" "}
                    </button>

                    <button
                      className="btn btn-primary btn-air-primary ml-2"
                      type="button"
                      onClick={PlotChart}
                    >
                      {" "}
                      Show Graph{" "}
                    </button>

                    {/* <button
                      className="btn btn-secondary btn-air-secondary ml-2"
                      onClick={handlePrint}
                    >
                      {" "}
                      Print{" "}
                    </button> */}

                    <button
                      className="btn btn-primary btn-air-primary ml-2"
                      type="button"
                      onClick={handleShow}
                    >
                      {" "}
                      Comment{" "}
                    </button>
                  </div>
                </div>
              </div>

              <div className="row" style={{ display: BLOCK_TAGS }}>
                <div className="d-flex mb-1 col-md-12">
                  <div className="mb-1 col-md-6" width={"35%"}>
                    <div className="pt-2 pr-2 pb-2 cavityGrid">

                      <CavityBalanceGrid column={column} data={data} setData={setData}
                        CavityGridData={CavityGridData}
                        setCavityGridData={setCavityGridData}
                        setColToBeDeleted={setColToBeDeleted}
                        setPercentage={setPercentage}
                        setTotal={setTotal}
                        setAverage={setAverage}
                        setRange={setRange}
                        setMaxPart={setMaxPart}
                        setMinPart={setMinPart} ToSaveCavityData={ToSaveCavityData} />

                    </div>

                    <div className="mb-2 cavityGrid">
                      <CavityGrid2
                        column={column}
                        CavityGridData={CavityGridData}
                        PlotChart={PlotChart}
                        Total={Total}
                        Average={Average}
                        MaxPart={MaxPart}
                        MinPart={MinPart}
                        Percentage={Percentage}
                        Range={Range}
                        colCount={colCount}
                      />
                    </div>
                  </div>

                  <div className="mt-2 ml-1 col-md-6" width={"65%"}>
                    <ChartComponent
                      id="charts"
                      tooltip={{ enable: true }}
                      ref={(chart) => (chartInstance = chart)}
                      // width='700'
                      height="430"
                      border={{ width: 1, color: "darkblue" }}
                      title="Cavity Chart Analysis"
                      primaryXAxis={{
                        // zoomFactor: 0.7,
                        valueType: "Category",
                        title: "Cavity ID",
                        labelIntersectAction: "Rotate90",
                        lineStyle: { color: "black" },
                      }}
                      primaryYAxis={{
                        title: "Part Weight",
                        lineStyle: { color: "black" },
                      }}
                      legendSettings={{
                        visible: true,
                        position: "Top",
                      }}
                    // zoomSettings={zoomsettings}
                    // load={load}
                    >
                      <Inject
                        services={[
                          LineSeries,
                          Legend,
                          Category,
                          DataLabel,
                          ScrollBar,
                          Zoom,
                          Tooltip,
                        ]}
                      />

                      <SeriesCollectionDirective>
                        {chartData2.map((value1, key1) =>
                          chartData.map((value, key2) => (
                            <SeriesDirective
                              type="Line"
                              dataSource={chartData2[key1]}
                              xName="Cavity_No"
                              yName={`value${key2}`}
                              name={value1.Cavity_No}
                              marker={{ visible: true }}
                              // fill="rgb(2,0,4)"
                              width={2.5}
                            ></SeriesDirective>
                          ))
                        )}
                      </SeriesCollectionDirective>
                    </ChartComponent>
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <Modal isOpen={show} centered={true}>
                  <ModalHeader toggle={handleClose}>Add Comment</ModalHeader>
                  <ModalBody>
                    <RichTextEditorComponent
                      change={getComment}
                      value={Comment}
                      saveInterval="1"
                      toolbarSettings={toolbarSettings}
                      height={250}
                    >
                      <Inject services={[Toolbar, HtmlEditor]} />
                    </RichTextEditorComponent>
                  </ModalBody>
                  <ModalFooter>
                    <Button color="dark" onClick={handleClose}>
                      {" "}
                      Save & Close{" "}
                    </Button>
                  </ModalFooter>
                </Modal>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* ************************* Printable Part ********************* */}
      <section
        className={"Printable_Part"}
        ref={CavityComponentRef}
      >
        {/* <CavityPrint
          column={column}
          CavityGridData={CavityGridData}
          Total={Total}
          Average={Average}
          MaxPart={MaxPart}
          MinPart={MinPart}
          Percentage={Percentage}
          Range={Range}
          chartData={chartData}
          chartData2={chartData2}
          Mold_Name={Mold_Name}
          Session_Name={Session_Name}
          Comment={Comment}
        /> */}
      </section>
    </>
  );
};

export default CavityBalance;
