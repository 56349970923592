import React, { useEffect, useState } from "react";
import "../../App.css";
import "../../calculators/style.css";
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import Summary from "../Analysis/Summary";
import ContourStats from "../Contour/ContourStats";
import MainEffectChart from "../MainEffect_Plot/MainEffectChart";
import Tornado from "../Tornado/Tornado";
import EditExptData from "../EditExptData/EditExptData";
import SensitivityOutliers from "../SensitivityOutliers/Sensitivity_Outliers";
import {
  // useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import DataService from "../../../services/ApiService"
import NumericalStats from "../NumericalPrediction/NumericalStats";
import SuggestedDimStats from "../SuggestedDimChanges/SuggestedDimStats";
import ConfidenceRating from "../ConfidenceRating/ConfidenceRating";
import TornadoPrint from "../Printables/TornadoPrint";
import SensitivityOutliersPrintable from "../Printables/SensitivityOutliersPrint";
import NumericalPrintable from "../NumericalPrediction/NumericalPrintable";

const AnalysisTab = () => {

  const [analysisPerformed, setAnalysisPerformed] = useState(false); // Track whether analysis has been performed

  // const history = useHistory();

  const location = useLocation();

  const [noOfDimensions, setnoOfDimensions] = useState([]); //Select from Dropdown (Cavity Selection)

  const { sessionId } = useParams();

  var decrypted_session = atob(sessionId);

  // const handleTabChange = (tab) => {
  //   //  Tab index update
  //   if (activeTab !== tab) setActiveTab(tab);
  // };

  const mold = location.state.mold;

  // const handleEditButton = (session) => {
  //   setAnalysisPerformed(false);
  // };

  useEffect(() => {
    // console.log(session)

    // API call to get set of all cavities to render in the dropdown field.
    DataService.GetDefineResponse(decrypted_session, mold)
      .then((res) => {
        // console.log("getdefineresponse from the analysistab", res)

        const dimensionJSON = res.data["dimension"];

        const dataArray = Array.isArray(dimensionJSON)
          ? dimensionJSON
          : [dimensionJSON];

        setnoOfDimensions(dataArray);
      })
      .catch((err) => {
        console.error("Error sending data to Django:", err);
      });

    // console.log(noOfDimensions);
  }, [decrypted_session]);
  // console.log("mold from analysistab", mold)

  const handleAnalysis = () => {
    // console.log("clicked");
    // API call for posting the selected cavity from dropdown
    DataService.SelectDropdown(decrypted_session, {
      selected: noOfDimensions[0],
    })
      .then(
        DataService.Anova(decrypted_session)
          // .then((res) => {
          //   console.log("Anova API Response", res);
          // })
          .catch((err) => {
            console.error("Error sending data to Django:", err);
          })
      )
      .then(
        setAnalysisPerformed(true) // Set analysis performed to true
      );
  };
  // Declare the names of the tab's
  let headertext = [
    { text: "Edit Expt Data" },                                         //0
    { text: analysisPerformed ? "Sensitivity-Outliers" : "" },          //1
    { text: analysisPerformed ? "Tornado Chart" : "" },                 //2
    { text: analysisPerformed ? "Main Effects Plot" : "" },             //3
    { text: analysisPerformed ? "Dim Process Window" : "" },            //4
    { text: analysisPerformed ? "Visual - Numerical Predictions" : "" },//5
    { text: analysisPerformed ? "Suggested Dim Changes" : "" },         //6
    { text: analysisPerformed ? "Confidence Rating" : "" },             //7 
    { text: analysisPerformed ? "ANOVA" : "" },                         //8
  ];


  // Edit Expt Data
  function content0() {
    return (
      <div className="card doeAnalysistab p-3 ml-2">
        <EditExptData mold={mold} />
      </div>
    );
  }


  // Sensitivity-Outliers
  function content1() {
    return (
      <div className="card doeAnalysistab p-3 ml-2">
        <SensitivityOutliers session={decrypted_session} mold={mold} />
        {/* <SensitivityOutliersPrintable session={decrypted_session} mold={mold} /> */}
      </div>
    );
  }


  // Tornado Chart
  function content2() {
    return (
      <>
        {analysisPerformed && (
          <div className="card doeAnalysistab p-3 ml-2">
            <Tornado mold={mold} />
            {/* <TornadoPrint mold={mold} /> */}
          </div>
        )}
      </>
    );
  }


  // Main Effects Plot
  function content3() {
    return (
      <>
        {analysisPerformed && (
          <div className="card doeAnalysistab p-3 ml-2">
            <MainEffectChart mold={mold} />
          </div>
        )}
      </>
    );
  }



  // Dim Process Window
  function content4() {
    return (
      <>
        {analysisPerformed && (
          <div className="card doeAnalysistab p-3 ml-2">
            <ContourStats session={decrypted_session} mold={mold} />
          </div>
        )}
      </>
    );
  }


  // Visual - Numerical Predictions
  function content5() {
    return (
      <>
        {analysisPerformed && (
          <div className="card doeAnalysistab p-3 ml-2">
            <NumericalStats mold={mold} />
            {/* <NumericalPrintable mold={mold} /> */}
          </div>
        )}
      </>
    );
  }


  // Suggested Dim Changes 
  function content6() {
    return (
      <div className="card doeAnalysistab p-3 ml-2">
        <SuggestedDimStats />
      </div>
    );
  }


  // Confidence Rating
  function content7() {
    return (
      <div className="card doeAnalysistab p-3 ml-2">
        <ConfidenceRating />
      </div>
    );
  }


  // ANOVA
  function content8() {
    return (
      <div className="card doeAnalysistab p-3 ml-2">
        <Summary session={decrypted_session} mold={mold} />
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="d-flex pb-3 pt-3">
        <div className="col-md-10">
          {/* This is Syncfusion Tab control in which header attribute is to display the name of that tab and content attribute to display the content under that tab */}
          <TabComponent
            heightAdjustMode="Auto"
            widthAdjustMode="Auto"
            id="defaultTab"
            key={analysisPerformed}
          >
            <TabItemsDirective>
              <TabItemDirective header={headertext[0]} content={content0} />
              <TabItemDirective header={headertext[1]} content={content1} />
              <TabItemDirective header={headertext[2]} content={content2} />
              <TabItemDirective header={headertext[3]} content={content3} />
              {/* <TabItemDirective header={headertext[4]} content={content4} /> */}
              <TabItemDirective header={headertext[5]} content={content5} />
              <TabItemDirective header={headertext[6]} content={content6} />
              <TabItemDirective header={headertext[7]} content={content7} />
              <TabItemDirective header={headertext[8]} content={content8} />
            </TabItemsDirective>
          </TabComponent>
        </div>

        <div className="col-md-2">
          {/* Analysis Button */}
          <button
            className="btn btn-primary btn-air-primary mr-2"
            type="button"
            onClick={handleAnalysis}
          >
            Analysis
          </button>
        </div>

      </div>

    </div>
  );
};

export default AnalysisTab;
