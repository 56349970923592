import React, { useState, useEffect, useRef } from "react";
import {
  ChartComponent,
  LineSeries,
  Inject,
  SeriesCollectionDirective,
  ScatterSeries,
  Category,
  DataLabel,
  SeriesDirective,
  Tooltip,
} from "@syncfusion/ej2-react-charts";
import CosmeticGrid from "../Grids/CosmeticGrid";
import CosmeticEdit from "../modals/CosmeticEdit";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  HtmlEditor,
  RichTextEditorComponent,
  Toolbar,
} from "@syncfusion/ej2-react-richtexteditor";

// Event having multiple method's to deal with the back-end
import DataService from "../../services/ApiService";

// Hook to get the parameter's from the URL
import { useParams } from "react-router-dom";

import { useReactToPrint } from "react-to-print";
import CosmeticPrint from "../printables/CosmeticPrint";

import { toast } from "react-toastify";

import CosmeticProcessGrid from "../Grids/CosmeticProcessGrid";

export let chartInstance;

const CosmeticPressure = ({ showAlert, CosmeticData, ToSaveCosmeticData, showPrint, setShowPrint, CosmeticComponentRef }) => {
  let CosmeticSpreadsheet = useRef();

  const [modal, setModal] = useState();
  const [Melting, setMelting] = useState("Melt Temp");
  const [Hydraulic, setHydraulic] = useState("Hydraulic Pressure");
  const [CosmeticGridData, setCosmeticGridData] = useState([
    {
      "id": 1,
      "Melt_Temp": "",
      "Low": "",
      "High": ""
    },

    {
      "id": 2,
      "Melt_Temp": "",
      "Low": "",
      "High": ""
    }
  ]);

  var centerPoints = [];
  const [chartData, setChartData] = useState([]);

  const [PressureUnits, setPressureUnits] = useState(null);
  const [TemperatureUnits, setTemperatureUnits] = useState(null);

  const [Alert, setAlert] = useState(false);

  const [data, setData] = useState(
    Array.from({ length: 2 }, () => Array(3).fill(""))
  );

  const toggle = () => {
    if (Melting && Hydraulic) {
      setModal(!modal);

      // console.log(Melting, Hydraulic)

      CosmeticSpreadsheet.current.refresh();
    } else {
      alert("Header cannot be empty");
    }
  };

  const setHeader1 = (e) => {
    e.preventDefault();

    setMelting(e.target.value);
  };

  const setHeader2 = (e) => {
    e.preventDefault();

    setHydraulic(e.target.value);
  };

  const PopulateSheet = (SheetData) => {
    CosmeticSpreadsheet.current.updateCell(
      { value: SheetData[0]["Melt_Temp"] },
      `A1`
    );
    CosmeticSpreadsheet.current.updateCell(
      { value: SheetData[1]["Melt_Temp"] },
      `A2`
    );
    CosmeticSpreadsheet.current.updateCell(
      { value: SheetData[0]["Low"] },
      `B1`
    );
    CosmeticSpreadsheet.current.updateCell(
      { value: SheetData[1]["Low"] },
      `B2`
    );
    CosmeticSpreadsheet.current.updateCell(
      { value: SheetData[0]["High"] },
      `C1`
    );
    CosmeticSpreadsheet.current.updateCell(
      { value: SheetData[1]["High"] },
      `C2`
    );
  };

  // Create an object which will be used to plot the polygon
  const polygonData = [
    {
      x: parseFloat(CosmeticGridData[0]["Melt_Temp"]),
      y: parseFloat(CosmeticGridData[0]["Low"]),
    },
    {
      x: parseFloat(CosmeticGridData[1]["Melt_Temp"]),
      y: parseFloat(CosmeticGridData[1]["Low"]),
    },
    {
      x: parseFloat(CosmeticGridData[1]["Melt_Temp"]),
      y: parseFloat(CosmeticGridData[1]["High"]),
    },
    {
      x: parseFloat(CosmeticGridData[0]["Melt_Temp"]),
      y: parseFloat(CosmeticGridData[0]["High"]),
    },
    {
      x: parseFloat(CosmeticGridData[0]["Melt_Temp"]),
      y: parseFloat(CosmeticGridData[0]["Low"]),
    },
  ];

  var Coordinates = [
    {
      x: parseFloat(CosmeticGridData[0]["Melt_Temp"]),
      y: parseFloat(CosmeticGridData[0]["Low"]),
    },
    {
      x: parseFloat(CosmeticGridData[1]["Melt_Temp"]),
      y: parseFloat(CosmeticGridData[1]["Low"]),
    },
    {
      x: parseFloat(CosmeticGridData[1]["Melt_Temp"]),
      y: parseFloat(CosmeticGridData[1]["High"]),
    },
    {
      x: parseFloat(CosmeticGridData[0]["Melt_Temp"]),
      y: parseFloat(CosmeticGridData[0]["High"]),
    },
    {
      x: parseFloat(CosmeticGridData[0]["Melt_Temp"]),
      y: parseFloat(CosmeticGridData[0]["Low"]),
    },
  ];

  function calculateNiceNumbers(min, max, numTicks) {
    const range = max - min;
    const roughStep = range / numTicks;
    const magnitude = Math.floor(Math.log10(roughStep));
    const magnitudePow = Math.pow(10, magnitude);
    const mantissa = roughStep / magnitudePow;
    let niceStep;

    if (mantissa <= 1.0) {
      niceStep = 1 * magnitudePow;
    } else if (mantissa <= 2.0) {
      niceStep = 2 * magnitudePow;
    } else if (mantissa <= 5.0) {
      niceStep = 5 * magnitudePow;
    } else {
      niceStep = 10 * magnitudePow;
    }

    const niceMin = Math.floor(min / niceStep) * niceStep;
    const niceMax = Math.ceil(max / niceStep) * niceStep;

    const numNiceTicks = Math.floor((niceMax - niceMin) / niceStep) + 1;
    const niceTicks = Array.from(
      { length: numNiceTicks },
      (_, i) => niceMin + i * niceStep
    );

    return {
      min: niceMin,
      max: niceMax,
      step: niceStep,
      ticks: niceTicks,
    };
  }

  const [YniceNumbers, setYNiceNumbers] = useState({
    min: 0,
    max: 0,
    step: 0,
  });

  const [XniceNumbers, setXNiceNumbers] = useState({
    min: 0,
    max: 0,
    step: 0,
  })

  const setGraph = () => {
    let cosmeticArray = [];

    // This is the event to sort the data based on Injection Speed
    const Compare = (a, b) => {
      return a.Melt_Temp - b.Melt_Temp;
    };

    // Storing the main array in a local so that we can perform sorting on the local array
    for (let i = 0; i < CosmeticGridData.length; i++) {
      cosmeticArray.push(CosmeticGridData[i]);
    }

    // Sorting the Array
    cosmeticArray.sort(Compare);

    let margin =
      ((parseInt(cosmeticArray[1].Melt_Temp) -
        parseInt(cosmeticArray[0].Melt_Temp)) /
        100) *
      5;

    setXNiceNumbers(
      calculateNiceNumbers(
        cosmeticArray[0].Melt_Temp - margin,
        cosmeticArray[1].Melt_Temp + margin,
        5
      )
    );

    // console.log(Coordinates, centerPoints)

    if (
      parseInt(cosmeticArray[0].Melt_Temp) ===
      parseInt(cosmeticArray[1].Melt_Temp)
    ) {
      toast("Melt Temperature values cannot be same.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setChartData([]);
    }

    if (parseInt(cosmeticArray[0].Low) > parseInt(cosmeticArray[0].High)) {
      toast(
        "Low Hydraulic Pressure cannot be greater than High Hydraulic Pressure",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      setChartData([]);
    } else if (
      parseInt(cosmeticArray[1].Low) > parseInt(cosmeticArray[1].High)
    ) {
      toast(
        "Low Hydraulic Pressure cannot be greater than High Hydraulic Pressure",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      setChartData([]);
    } else if (
      parseInt(cosmeticArray[0].Low) === parseInt(cosmeticArray[0].High)
    ) {
      toast(
        "Low Hydraulic Pressure cannot be greater than High Hydraulic Pressure",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      setChartData([]);
    } else if (
      parseInt(cosmeticArray[1].Low) === parseInt(cosmeticArray[1].High)
    ) {
      toast(
        "Low Hydraulic Pressure cannot be greater than High Hydraulic Pressure",
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );

      setChartData([]);
    } else {
      setChartData(polygonData);

      // console.log(polygonData)
      // console.log(CosmeticGridData)
    }

    // console.log(cosmeticArray)

    let min = Math.min(cosmeticArray[0].Low, cosmeticArray[1].Low);
    let max = Math.max(cosmeticArray[0].High, cosmeticArray[1].High);

    setYNiceNumbers(calculateNiceNumbers(min, max + 10, 5));
  };

  function calculateCentroid(vertices) {
    let totalX = 0;
    let totalY = 0;

    // Summing up all x and y coordinates
    for (let i = 0; i < vertices.length - 1; i++) {
      totalX += vertices[i].x;
      totalY += vertices[i].y;
    }

    // Calculating the average of x and y coordinates
    const centerX = totalX / (vertices.length - 1);
    const centerY = totalY / (vertices.length - 1);

    return { x: Number(centerX).toFixed(2), y: Number(centerY).toFixed(2) };
  }

  centerPoints.push(calculateCentroid(Coordinates));

  // To get the Session Id from url using useParams hook
  var { sessionId, moldName, sessionName } = useParams();

  // Variable to store the a Mold/Session Name
  const [Mold_Name, setMold_Name] = useState();
  const [Session_Name, setSession_Name] = useState();

  // Session Id getting from the URL
  const [SessionId, setSessionId] = useState();

  // A state variable to store and set the value of textarea
  const [Comment, setComment] = useState("");

  // Event the current session's viscosity data
  const handleGet = (SessionId) => {
    if (SessionId) {
      DataService.FetchCosmetic(SessionId)
        .then((res) => {
          if (res.data) {
            ToSaveCosmeticData.current = false;

            setCosmeticGridData(res.data.Grid_Data);

            const orderedKeys = ["Melt_Temp", "Low", "High"];

            const result = res.data.Grid_Data.map(obj =>
              orderedKeys.map(key => Number(obj[key]))
            );

            setData(result)

            // PopulateSheet(res.data.Grid_Data);

            setComment(res.data.Comment);
            setPressureUnits(res.data.PressureUnits);
            setTemperatureUnits(res.data.TemperatureUnits);
            setMelting(res.data.Headers[0]);
            setHydraulic(res.data.Headers[1]);

            // CosmeticSpreadsheet.current.refresh();
          } else {
            setCosmeticGridData([
              {
                "id": 1,
                "Melt_Temp": "",
                "Low": "",
                "High": ""
              },

              {
                "id": 2,
                "Melt_Temp": "",
                "Low": "",
                "High": ""
              }
            ]);
          }
        })
        .catch((err) => { });
    } else {
      setCosmeticGridData([
        {
          "id": 1,
          "Melt_Temp": "",
          "Low": "",
          "High": ""
        },

        {
          "id": 2,
          "Melt_Temp": "",
          "Low": "",
          "High": ""
        }
      ]);
    }
  };

  useEffect(() => {
    const data = {
      session: SessionId,
      PressureUnits: PressureUnits ? PressureUnits : 0,
      TemperatureUnits: TemperatureUnits ? TemperatureUnits : 0,
      Grid_Data: CosmeticGridData,
      Headers: [Melting, Hydraulic],
      Comment: Comment ? Comment : "N/A",
      PrintData: {
        "Melting": Melting,
        "Hydraulic": Hydraulic,
        "CosmeticGridData": CosmeticGridData,
        "chartData": chartData,
        "centerPoints": centerPoints,
        "Mold_Name": Mold_Name,
        "Session_Name": Session_Name,
        "textRender": textRender,
        "Comment": Comment,
        "PressureUnits": PressureUnits,
        "TemperatureUnits": TemperatureUnits,
        "XniceNumbers": XniceNumbers,
        "YniceNumbers": YniceNumbers
      }
    };

    CosmeticData.current = data;

    // console.log(data)

    // eslint-disable-next-line
  }, [
    CosmeticGridData,
    PressureUnits,
    TemperatureUnits,
    Comment,
    Melting,
    Hydraulic,
  ]);

  // Event to Scroll to Chart
  const PlotChart = () => {
    const data = CosmeticGridData.filter((data) => {
      return data.Melt_Temp !== "";
    });

    // console.log(data)

    if (data.length > 0) {
      setGraph();
    } else if (!ToPlotChart) {
      toast("Insufficient Data to plot the chart", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const [ToPlotChart, setToPlotChart] = useState(true);

  useEffect(() => {
    if (ToPlotChart) PlotChart();

    // eslint-disable-next-line
  }, [CosmeticGridData]);

  useEffect(() => {
    // On load it decrypt's the session Id
    setSessionId(atob(sessionId));

    setMold_Name(atob(moldName));
    setSession_Name(atob(sessionName));

    // After that it call's this event to fetch the data
    handleGet(SessionId);

    // eslint-disable-next-line
  }, [sessionId, SessionId, moldName, sessionName]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let toolbarSettings = {
    items: [
      "Bold",
      "Italic",
      "Underline",
      "FontSize",
      "FontColor",
      "BackgroundColor",
      "Alignments",
      "OrderedList",
      "UnorderedList",
      "Undo",
      "Redo",
    ],
  };

  // Event to set the data entered in the textarea
  const getComment = (e) => {
    if (e.target) {
      setComment(e.target.value);
    } else {
      setComment(e.value); // Get the RTE value
      showAlert.current = true;
      setAlert(true);
    }
  };

  const printPage = useReactToPrint({
    content: () => CosmeticComponentRef.current,
    onBeforePrint: () => setShowPrint(true),
    onAfterPrint: () => setShowPrint(false),
  });

  const handlePrint = () => {
    setShowPrint(true);

    setTimeout(() => {
      printPage();
    }, 100);
  };

  const textRender = (args) => {
    // alert(JSON.stringify(args))

    args.text =
      "Center of window(X : " + args.point.x + ", Y : " + args.point.y + ")";
  };

  const CosmeticTableRef = useRef(null);

  const getCellElement = (rowIndex, colIndex) => {
    const table = CosmeticTableRef.current;
    return table?.querySelector(`[data-row="${rowIndex}"][data-col="${colIndex}"]`);
  };

  const GetValues = () => {

    let TempArray = [];

    for (let i = 0; i < 2; i++) {

      TempArray.push({
        "Melt_Temp": parseFloat(getCellElement(i, 0)?.innerText),

        "Low": parseFloat(getCellElement(i, 1)?.innerText),

        "High": parseFloat(getCellElement(i, 2)?.innerText)
      })

    }

    setCosmeticGridData(TempArray)

    // console.log(TempArray)
  };

  return (
    <>
      <div>
        <div className="card sixstepCard p-3 ml-2">
          <div className="b-primary b-r-4 mb-2">
            <div className="d-flex">
              <div className="d-flex  pt-2 pb-1 pl-2">
                <div>
                  <label
                    htmlFor="Nozzle_Length"
                    className="lbl_design pr-2"
                    style={{ fontSize: 12 }}
                  >
                    Pressure Units:{" "}
                  </label>
                </div>
                <div>
                  <input
                    className="viscosity-inputs"
                    id="Pressure_Units"
                    type="text"
                    defaultValue={PressureUnits}
                    onChange={(e) => setPressureUnits(e.target.value)}
                    style={{ height: 22 }}
                  />
                </div>
              </div>

              <div className="d-flex pt-2 pb-1 pl-2">
                <div>
                  <label
                    htmlFor="Nozzle_Length"
                    className="lbl_design pl-2 pr-2"
                    style={{ fontSize: 12 }}
                  >
                    Temperature Units:{" "}
                  </label>
                </div>
                <div>
                  <input
                    className="viscosity-inputs"
                    id="Temperature_Units"
                    type="text"
                    defaultValue={TemperatureUnits}
                    onChange={(e) => setTemperatureUnits(e.target.value)}
                    style={{ height: 22 }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="b-primary b-r-4 mb-2 pt-2 pb-2">
            <div className="mt-1">
              <div className="d-flex mr-0">
                <div>
                  <div className="form-group">
                    <CosmeticEdit
                      toggle={toggle}
                      modal={modal}
                      setHeader1={setHeader1}
                      setHeader2={setHeader2}
                      Melting={Melting}
                      Hydraulic={Hydraulic}
                    />
                  </div>
                </div>

                <div>
                  <button
                    className="btn btn-primary btn-air-primary mr-2 ml-0"
                    type="button"
                    onClick={handleShow}
                  >
                    Comment
                  </button>

                  <button
                    className="btn btn-primary btn-air-primary mr-2"
                    onClick={PlotChart}
                  >
                    {" "}
                    Show Graph{" "}
                  </button>

                  {/* <button
                    className="btn btn-secondary btn-air-secondary mr-2"
                    type="button"
                    onClick={handlePrint}
                  >
                    {" "}
                    Print{" "}
                  </button> */}

                  <Modal isOpen={show} centered>
                    <ModalHeader toggle={handleClose}>Add Comment</ModalHeader>
                    <ModalBody>
                      <RichTextEditorComponent
                        change={getComment}
                        value={Comment}
                        saveInterval="1"
                        toolbarSettings={toolbarSettings}
                        height={250}
                      >
                        <Inject services={[Toolbar, HtmlEditor]} />
                      </RichTextEditorComponent>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="dark" onClick={handleClose}>
                        {" "}
                        Save & Close{" "}
                      </Button>
                    </ModalFooter>
                  </Modal>
                </div>
              </div>

              <div className="d-flex p-2">
                <div className="mb-1 col-md-6" width={"35%"}>
                  <div onClick={() => setToPlotChart(false)}>
                    {/* <CosmeticGrid
                      Melting={Melting}
                      Hydraulic={Hydraulic}
                      CosmeticGridData={CosmeticGridData}
                      Alert={Alert}
                      CosmeticSpreadsheet={CosmeticSpreadsheet}
                      setCosmeticGridData={setCosmeticGridData}
                      showAlert={showAlert}
                    /> */}

                    <CosmeticProcessGrid
                      data={data}
                      setData={setData}
                      Melting={Melting}
                      Hydraulic={Hydraulic}
                      GetValues={GetValues} CosmeticTableRef={CosmeticTableRef}
                    />
                  </div>
                </div>

                <div className="mb-1 col-md-6" width={"65%"}>
                  <div className="ml-2">
                    <ChartComponent
                      width="100%"
                      height="400"
                      border={{ width: 1, color: "darkblue" }}
                      title="Process Window"
                      primaryXAxis={{
                        title: `${Melting}`,
                        minimum: XniceNumbers.min,
                        maximum: XniceNumbers.max,
                        interval: XniceNumbers.step,
                        lineStyle: { color: "black" },
                      }}
                      primaryYAxis={{
                        title: `${Hydraulic}`,
                        minimum: YniceNumbers.min,
                        maximum: YniceNumbers.max,
                        interval: YniceNumbers.step,
                        lineStyle: { color: "black" },
                      }}
                      tooltip={{ enable: true }}
                      textRender={textRender}
                    >
                      <Inject
                        services={[
                          LineSeries,
                          Category,
                          DataLabel,
                          ScatterSeries,
                          Tooltip,
                        ]}
                      />

                      <SeriesCollectionDirective>
                        <SeriesDirective
                          type="Line"
                          dataSource={chartData}
                          xName="x"
                          yName="y"
                          marker={{ visible: true }}
                          fill="rgb(255,0,0)"
                          width={2.5}
                        ></SeriesDirective>

                        <SeriesDirective
                          dataSource={centerPoints}
                          xName="x"
                          yName="y"
                          width={2}
                          marker={{
                            dataLabel: { visible: true },
                            shape: "Diamond",
                            width: 15,
                            height: 15,
                          }}
                          type="Scatter"
                        ></SeriesDirective>
                      </SeriesCollectionDirective>
                    </ChartComponent>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ******************** Printable Part ******************** */}
      <section
        className={showPrint ? "Printable_Part" : "Showable_Part"}
        ref={CosmeticComponentRef}
      >
        <CosmeticPrint
          Melting={Melting}
          Hydraulic={Hydraulic}
          CosmeticGridData={CosmeticGridData}
          chartData={chartData}
          centerPoints={centerPoints}
          Mold_Name={Mold_Name}
          Session_Name={Session_Name}
          textRender={textRender}
          Comment={Comment}
          PressureUnits={PressureUnits}
          TemperatureUnits={TemperatureUnits}
          XniceNumbers={XniceNumbers}
          YniceNumbers={YniceNumbers}
        />
      </section>
    </>
  );
};

export default CosmeticPressure;
