import React, { useState, useEffect, useRef } from "react";
import DataService from "../../../services/ApiService";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import NumericalPrediction from "./NumericalPrediction";
import QuantitativeGrid from "./QuantitativeGrid";
import NumericalPredictionGrid from "./NumericalPredictionGrid";
import { Button } from "reactstrap";
import CavitySelection from "./CavitySelection";
import "../../App.css";
import "../Splitter.css";

const NumericalStats = (mold) => {
  
  const { sessionId } = useParams();

  const decry = atob(sessionId);

  const [NumericalPredictionResponse, setNumericalPredictionResponse] = useState([])

  const [SpinnerData, setSpinnerData] = useState([]);

  const [ResponseName, setResponseName] = useState([]);

  const [ChartTitle, setChartTitle] = useState([]);

  const [LowValues, setLowValues] = useState();

  const [Limit, setLimit] = useState([]);

  const [rangeColumnData, setRangeColumnData] = useState([]);

  const [ShowGoldSpot, setShowGoldSpot] = useState(false);

  const [GoldSpotIndex, setGoldSpotIndex] = useState();

  const [GoldSpotSetting, setGoldSpotSetting] = useState();

  const [ShowMaxDim, setShowMaxDim] = useState(false);

  const [MaxDimIndex, setMaxDimIndex] = useState();

  const [MaxDimSetting, setMaxDimSetting] = useState();

  const [selectedOption, setSelectedOption] = useState("");

  const [PredictedValues, setPredictedValues] = useState([]);

  const [Specifications, setSpecifications] = useState();

  const [Units, setUnits] = useState([]);

  const [showDialog, setShowDialog] = React.useState(false);

  const [excludeDisplay, setExcludeDisplay] = useState([]);

  const [excludeCalculation, setExcludeCalculation] = useState([]);

  useEffect(() => {
    const fetchNumericalAPI = async () => {
      try {
        const res = await DataService.NumericalPrediction(decry);
        const responseData = res.data;
  
        // Set state with API data
        setNumericalPredictionResponse(responseData);
        setSpinnerData(responseData.spinner_data);
        setLowValues(responseData.low);
        setResponseName(responseData.range_column_data);
        setChartTitle(responseData.title);
        setRangeColumnData(responseData.range_column_data);
        setLimit(responseData.limit);
        setGoldSpotIndex(responseData.gold_spot_index);
        setGoldSpotSetting(responseData.gold_spot_setting);
        setMaxDimIndex(responseData.max_dim_index);
        setMaxDimSetting(responseData.max_dim_setting);
        setUnits(responseData.units);
        setSpecifications(responseData.specifications);
        setExcludeCalculation(responseData.excludeCalculation);
        setExcludeDisplay(responseData.excludeDisplay);
  
        let PredictedValuesArray = [];
        for (let i = 0; i < responseData.dimension.length; i++) {
          PredictedValuesArray.push(responseData.dimension[i].dimension[0]);
        }
        setPredictedValues(PredictedValuesArray);
      } catch (error) {
        console.error("Error fetching numerical prediction:", error);
      }
    };
  
    const getCavities = async () => {
      try {
        const res = await DataService.GetDefineResponse(decry, mold.mold);
        const dimensionJSON = res.data["dimension"];
        return Array.isArray(dimensionJSON) ? dimensionJSON : [dimensionJSON];
      } catch (error) {
        console.error("Error fetching cavities:", error);
        return [];
      }
    };
  
    // Combine both operations in an async function
    const initialize = async () => {
      try {
        const cavities = await getCavities();
        const data = {
          session_id: decry,
          display: cavities,
          excludeDisplay: [],
          calculate: cavities,
          excludeCalaulate: [],
        };
  
        // console.log(data)
        // Post the data once we have the cavities
        await DataService.PostNumericalPrediction(decry, data);
        
        // Fetch numerical prediction data
        await fetchNumericalAPI();
      } catch (error) {
        console.error("Error initializing data:", error);
      }
    };
  
    // Run the async initialization on component mount
    initialize();
    
  }, []); // Empty dependency array ensures this runs only on first render
  

  // Step 1: Group data by the dimension name
  const grouped_data = {};

  rangeColumnData.forEach((item) => {
    const [dimension, high, low] = item;

    const parts = dimension.split("~");

    const title = dimension.split("-");

    const titleValue = title[2];

    const cavity_info = parts[1]; // Extract the cavity info (e.g., "cav-4-ABC")

    if (!grouped_data[titleValue]) {
      grouped_data[titleValue] = [];
    }
    grouped_data[titleValue].push({ cavity_info, titleValue, high, low });
  });
  // Step 2: Format the grouped data into the desired structure
  const formatted_data = {};

  Object.keys(grouped_data).forEach((key) => {
    formatted_data[key] = [];
    grouped_data[key].forEach((item) => {
      const { cavity_info, titleValue, high, low } = item;
      try {
        // Extract the cavity number from the cavity info (e.g., "cav-4-ABC")
        const cavity_number = parseInt(cavity_info.split("-")[1], 10);
        formatted_data[key].push({
          title: titleValue,
          x: cavity_number,
          high: high,
          low: low,
        });
      } catch (error) {}
    });
  });

  // Step 3: Collect the formatted data into an array
  const range_col = [];
  Object.values(formatted_data).forEach((value) => {
    range_col.push(value);
  });
  const newRange = [];
  ChartTitle.forEach((title) => {
    const baseKey = title.split("-")[0]; // Extract the part before '-'
    if (formatted_data[baseKey]) {
      newRange.push(formatted_data[baseKey]);
    }
  });
// console.log(newRange)
  // Function to check and replace high/low with "-"
  function excludeDisplayCheck(rangeData, excludedData) {
    // Loop over the newRange array
    rangeData.forEach((rangeItem) => {
      rangeItem.forEach((data) => {
        // Create the format for comparison like "cav-x-title"
        const rangeKey = `cav-${data.x}-${data.title}`;

        // Check if the rangeKey is present in excludedisplay
        const isExcluded = excludedData.some((excludedGroup) =>
          excludedGroup.includes(rangeKey)
        );

        // If found in excludedisplay, replace high/low with "-"
        if (isExcluded) {
          data.high = "-";
          data.low = "-";
        }
      });
    });

    return rangeData;
  }

  // Call the function
  const updatedRange = excludeDisplayCheck(newRange, excludeDisplay);

  let x_axis = range_col.map((subArray) => [
    subArray[0].x,
    subArray[subArray.length - 1].x,
  ]);

  let x = x_axis[0];

  let scalled_x = range_col.map((subArray) => [
    subArray[0].x - 1,
    subArray[subArray.length - 1].x + 1,
  ]);

  let x_limits = scalled_x[0];

  let numGroup = 0;
  if (x !== undefined) {
    for (let val = x[0]; val <= x[1]; val++) {
      numGroup++;
    }
  }

  const res_array = Limit.map((entry) => {
    const entryResults = [];
    for (let val = x_limits[0]; val <= x_limits[1]; val++) {
      entryResults.push({
        X: val,
        usl: entry.usl,
        nom: entry.nom,
        lsl: entry.lsl,
      });
    }
    return entryResults;
  });

  // Calculate the number of groups based on the length of PredictedValues and x array
  let numGroups;
  if (x !== undefined) {
    numGroups = PredictedValues.length / numGroup;
  }

  let newscatterData = [];
  let scatter_j_array = [];
  if (x) {
    for (let i = x[0]; i <= x[1]; i++) {
      scatter_j_array.push(i);
    }
  }

  for (let i = 0; i < numGroups; i++) {
    let group = [];
    for (let j = 0; j < scatter_j_array.length; j++) {
      group.push({
        x: scatter_j_array[j],
        y: PredictedValues[i * scatter_j_array.length + j],
      });
    }
    newscatterData.push(group);
  }

  let PredictedValuesArray_goldspot = [];
  let newgoldspotData = [];

  const exclude_GS = [];

  if (NumericalPredictionResponse) {
    for (let i = 0; i < PredictedValues.length; i++) {
      PredictedValuesArray_goldspot.push(
        NumericalPredictionResponse.dimension[i].dimension[GoldSpotIndex]
      );

      // Get the keys of the current object
      const currentDimensionObject = NumericalPredictionResponse.dimension[i];
      const dimensionKeys = Object.keys(currentDimensionObject);

      dimensionKeys.forEach((key) => {
        if (key.startsWith("dimension~")) {
          // Push the dimension key (e.g., 'dimension~cav-4-ABC~Length') to some_Array
          exclude_GS.push([
            key.replace("dimension~", ""),
            NumericalPredictionResponse.dimension[i].dimension[GoldSpotIndex],
          ]);
        }
      });
    }
  }

  let newExcludeGS = [...exclude_GS];

  // Loop through exclude_GS and replace the number with '-' if found in excludeDisplay
  newExcludeGS = exclude_GS.map((item) => {
    // Check if the first element of the item (e.g., "cav-4-ABC~Length") is in excludeDisplay[0]
    if (excludeCalculation[0].includes(item[0])) {
      // Replace the second element (number) with '-'
      return [item[0], "-"];
    }
    return item;
  });

  // Flatten the array to get only values
  let finalValues = newExcludeGS.map(([_, value]) => value);

  for (let i = 0; i < numGroups; i++) {
    let group_goldspot = [];
    for (let j = 0; j < scatter_j_array.length; j++) {
      group_goldspot.push({
        x: scatter_j_array[j],
        y: finalValues[i * scatter_j_array.length + j],
      });
    }
    newgoldspotData.push(group_goldspot);
  }

  // Ensure dimension exists and is an array before filtering

  let PredictedValuesArray_maxdim = [];
  let newMaxDimData = [];
  const excludeMD = [];

  if (NumericalPredictionResponse) {
    for (let i = 0; i < PredictedValues.length; i++) {
      PredictedValuesArray_maxdim.push(
        NumericalPredictionResponse.dimension[i].dimension[MaxDimIndex]
      );

      // Get the keys of the current object
      const currentDimensionObject = NumericalPredictionResponse.dimension[i];
      const dimensionKeys = Object.keys(currentDimensionObject);

      dimensionKeys.forEach((key) => {
        if (key.startsWith("dimension~")) {
          // Push the dimension key (e.g., 'dimension~cav-4-ABC~Length') to some_Array
          excludeMD.push([
            key.replace("dimension~", ""),
            NumericalPredictionResponse.dimension[i].dimension[MaxDimIndex],
          ]);
        }
      });
    }
  }

  let newExcludeMD = [...excludeMD];

  // Loop through exclude_GS and replace the number with '-' if found in excludeDisplay
  newExcludeMD = excludeMD.map((item) => {
    // Check if the first element of the item (e.g., "cav-4-ABC~Length") is in excludeDisplay[0]
    if (excludeCalculation[0].includes(item[0])) {
      // Replace the second element (number) with '-'
      return [item[0], "-"];
    }
    return item;
  });

  // Flatten the array to get only values
  let finalValuesMD = newExcludeMD.map(([_, value]) => value);

  for (let i = 0; i < numGroups; i++) {
    let group_maxdim = [];
    for (let j = 0; j < scatter_j_array.length; j++) {
      group_maxdim.push({
        x: scatter_j_array[j],
        y: finalValuesMD[i * scatter_j_array.length + j],
      });
    }
    newMaxDimData.push(group_maxdim);
  }

  const Headerkeys = Array.from(
    new Set(SpinnerData?.flatMap((item) => Object.keys(item)))
  );

  let GoldSpotMessage = {};
  if (GoldSpotSetting && Units) {
    for (let i = 0; i < Headerkeys.length; i++) {
      const formattedKey = Headerkeys[i].replace(/_/g, " ");
      // Assign each key in HeaderKeys to its corresponding value in goldspotsetting
      GoldSpotMessage[formattedKey] = GoldSpotSetting[i] + " " + Units[i];
    }
  }

  let MaxDimMessage = {};
  if (MaxDimSetting && Units) {
    for (let i = 0; i < Headerkeys.length; i++) {
      const formattedKey = Headerkeys[i].replace(/_/g, " ");
      // Assign each key in HeaderKeys to its corresponding value in maxdimsetting
      MaxDimMessage[formattedKey] = MaxDimSetting[i] + " " + Units[i];
    }
  }
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    setShowGoldSpot(false);
    setShowMaxDim(false);
  };

  const handleCalculate = () => {
    setShowDialog(true);
  };

  // Function to be called after the dialog is closed
  const onDialogClose = () => {
    setShowDialog(false); // Hide the dialog

    DataService.NumericalPrediction(decry).then((res) => {
      const responseData = res.data;

      setGoldSpotIndex(responseData.gold_spot_index);

      setGoldSpotSetting(responseData.gold_spot_setting);

      setMaxDimIndex(responseData.max_dim_index);

      setMaxDimSetting(responseData.max_dim_setting);

      setExcludeDisplay(responseData.excludeDisplay);

      setExcludeCalculation(responseData.excludeCalculation);
    });

    // Process the selected radio button option
    if (selectedOption === "goldspot") {
      setShowMaxDim(false);
      let PredictedValuesArray = [];
      for (let i = 0; i < NumericalPredictionResponse.dimension.length; i++) {
        PredictedValuesArray.push(
          NumericalPredictionResponse.dimension[i].dimension[GoldSpotIndex]
        );
      }
      setPredictedValues(PredictedValuesArray);
      setShowGoldSpot(true);
    } else if (selectedOption === "max_dim_acceptance") {
      setShowGoldSpot(false);
      let PredictedValuesArray = [];
      for (let i = 0; i < NumericalPredictionResponse.dimension.length; i++) {
        PredictedValuesArray.push(
          NumericalPredictionResponse.dimension[i].dimension[MaxDimIndex]
        );
      }
      setPredictedValues(PredictedValuesArray);
      setShowMaxDim(true);
    } else {
      setShowGoldSpot(false);
      setShowMaxDim(false);
    }
  };

  const [pane1Width, setPane1Width] = useState(50);
  const [pane2Width, setPane2Width] = useState(50);
  const draggingRef = useRef(null);

  const handleMouseDown = (e, paneIndex) => {
    draggingRef.current = { startX: e.clientX, paneIndex };
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    if (draggingRef.current) {
      const deltaX = e.clientX - draggingRef.current.startX;
      const totalWidth = window.innerWidth;
      const newWidthPercentage = (deltaX / totalWidth) * 100;

      if (draggingRef.current.paneIndex === 1) {
        setPane1Width((prev) => Math.max(5, prev + newWidthPercentage));
        setPane2Width((prev) => Math.max(5, prev - newWidthPercentage));
      } else if (draggingRef.current.paneIndex === 2) {
        setPane2Width((prev) => Math.max(5, prev + newWidthPercentage));
      }

      draggingRef.current.startX = e.clientX;
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    draggingRef.current = null;
  };

  const updatedTitle = ChartTitle.map((titleItem, index) => {
    const rangeData = updatedRange[index];

    // Check if rangeData is defined and is an array
    if (Array.isArray(rangeData)) {
      // Check if all high and low values in the rangeData are "-"
      const allAreDashes = rangeData.every(
        (item) => item.high === "-" && item.low === "-"
      );

      // If all values are "-", replace the title at this index with "-"
      return allAreDashes ? "-" : titleItem;
    } else {
      // If rangeData is undefined or not an array, return the original title
      return titleItem;
    }
  });

  return (
    <>
      <div className="b-primary">
        {/* <section className="card"> */}

        <div className="splitter">
          <div className="pane" style={{ width: `${pane1Width}%` }}>
            <div className="content">
              <div className="m-1 o-auto b-primary">
                <div className="p-2">
                  <span style={{ textDecoration: "underline" }}>
                    <b> Settings </b>
                  </span>
                </div>

                <div className="mb-4 p-2 d-flex">
                  <div>
                    <input
                      type="radio"
                      id="goldspot"
                      name="options"
                      value="goldspot"
                      onChange={handleOptionChange}
                    />
                    <label htmlFor="goldspot" className="ml-2 mr-2">
                      Gold Spot
                    </label>
                  </div>

                  <div>
                    <input
                      type="radio"
                      id="max_dim_acceptance"
                      name="options"
                      value="max_dim_acceptance"
                      onChange={handleOptionChange}
                    />
                    <label htmlFor="optionB" className="ml-2 mr-4">
                      Max Dim Acceptance
                    </label>
                  </div>

                  <div>
                    <Button id="next-button" onClick={handleCalculate}>
                      Calculate
                    </Button>

                    {showDialog && selectedOption && (
                      <CavitySelection
                        session={decry}
                        Cavities={ResponseName}
                        onClose={onDialogClose}
                      />
                    )}
                  </div>
                </div>

                {ShowGoldSpot ? (
                  <>
                    <div className="p-2">
                      <span style={{ textDecoration: "underline" }}>
                        <b>
                          {" "}
                          Predicted Process at Gold Spot at Selected Dimension:{" "}
                        </b>
                      </span>
                    </div>
                    <div
                      className="m-2 p-2"
                      style={{
                        border: "1px solid #573DAC",
                        fontSize: "1rem",
                      }}
                    >
                      {Object.entries(GoldSpotMessage).map(
                        ([key, value], index) => (
                          <span key={index} style={{ marginRight: "20px" }}>
                            {key} = {value}
                          </span>
                        )
                      )}
                    </div>{" "}
                  </>
                ) : (
                  <></>
                )}

                {ShowMaxDim ? (
                  <>
                    <div className="p-2">
                      <span style={{ textDecoration: "underline" }}>
                        <b> Predicted Process at Max Dimension Acceptance: </b>
                      </span>
                    </div>
                    <div
                      className="m-2 p-2"
                      style={{
                        border: "1px solid #573DAC",
                        fontSize: "1rem",
                      }}
                    >
                      {Object.entries(MaxDimMessage).map(
                        ([key, value], index) => (
                          <span key={index} style={{ marginRight: "20px" }}>
                            {key} = {value}
                          </span>
                        )
                      )}
                    </div>{" "}
                  </>
                ) : (
                  <></>
                )}

                <div className="p-2">
                  <span style={{ textDecoration: "underline" }}>
                    <b> Simulated Process </b>
                  </span>
                </div>

                <div className="mb-4 p-2">
                  <QuantitativeGrid
                    Low={LowValues}
                    SpinnerData={SpinnerData}
                    NumericalPrediction={NumericalPredictionResponse}
                    setPredictedValues={setPredictedValues}
                    ShowGoldSpot={ShowGoldSpot}
                    GoldSpotSetting={GoldSpotSetting}
                    ShowMaxDim={ShowMaxDim}
                    MaxDimSetting={MaxDimSetting}
                  />
                </div>

                <div className="p-2">
                  <span style={{ textDecoration: "underline" }}>
                    {" "}
                    <b> Predicted Dimensions </b>{" "}
                  </span>
                </div>

                <div className="p-2">
                  <NumericalPredictionGrid
                    PredictedValues={PredictedValues}
                    ResponseName={ResponseName}
                    Specifications={Specifications}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className="resizer"
            onMouseDown={(e) => handleMouseDown(e, 1)}
          ></div>

          <div className="pane" style={{ width: `${pane2Width}%` }}>
            <div className="content">
              <section className="m-1 b-primary">
                <div
                  className="m-2"
                  style={{ overflow: "auto", height: "100vh" }}
                >
                  <NumericalPrediction
                    width="100%"
                    className="numricalPreChar"
                    title={updatedTitle}
                    Result={res_array}
                    RangeColumnData={updatedRange}
                    ScatterData={newscatterData}
                    x_axis={x}
                    ShowGoldSpot={ShowGoldSpot}
                    GoldSpot={newgoldspotData}
                    ShowMaxDim={ShowMaxDim}
                    MaxDim={newMaxDimData}
                  />
                </div>
              </section>
            </div>
          </div>
        </div>

        {/* </section> */}
      </div>
    </>
  );
};

export default NumericalStats;
