import React from "react";
import {
  ChartComponent,
  LineSeries,
  SeriesCollectionDirective,
  SeriesDirective,
  Category,
  DataLabel,
  Inject,
} from "@syncfusion/ej2-react-charts";
import { HtmlEditor, RichTextEditorComponent } from "@syncfusion/ej2-react-richtexteditor";

import "./ColdPrint.css"; // Custom CSS for styling

const ColdPrint = ({
  column,
  ColdGridData,
  grid2,
  Mold_Name,
  Session_Name,
  ColdGridHeader,
  Comment,
  chartData,
  TimeniceNumbers,
  WeightniceNumbers,
}) => {
  return (
    <div className="print-container">
      {/* Header Section */}
      <div className="header">
        <h1>Cold Runner Report</h1>
      </div>

      {/* Input Details */}
      <div className="section">
        <h3>Input Details</h3>
        <table className="styled-table">
          <thead>
            <tr>
              <th>Mold ID</th>
              <th>Session Name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{Mold_Name}</td>
              <td>{Session_Name}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Data Grid Section */}
      <div className="section">
        <h3>Data View</h3>
        <table className="styled-table">
          <thead>
            <tr>
              {column.map((value) => (
                <th key={value.id}>{value.header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {ColdGridData.map((value, key1) => (
              <tr key={key1}>
                {column.map((index, key2) => (
                  <td key={key2}>
                    <input
                      type="text"
                      name={index.header}
                      className="form-control"
                      value={ColdGridData[key1][`value${key2}`] || ""}
                      readOnly
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Additional Data Grid */}
      <div className="section">
        <h3>Weight Increase Data</h3>
        <table className="styled-table">
          <thead>
            <tr>
              <th>Weight Increase</th>
              <th>% Weight Increase</th>
            </tr>
          </thead>
          <tbody>
            {ColdGridData.map((value, key1) => (
              <tr key={key1}>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={
                      ColdGridData[key1][`${grid2}`]
                        ? key1 === 0
                          ? "-"
                          : isNaN(ColdGridData[key1][`${grid2}`])
                            ? "-"
                            : Number(
                              ColdGridData[key1][`${grid2}`] -
                              ColdGridData[key1 - 1][`${grid2}`]
                            ).toFixed(1)
                        : "--"
                    }
                    readOnly
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control"
                    value={
                      ColdGridData[key1][`${grid2}`]
                        ? key1 === 0
                          ? "-"
                          : isNaN(ColdGridData[key1][`${grid2}`])
                            ? "-"
                            : Number(
                              ((ColdGridData[key1][`${grid2}`] -
                                ColdGridData[key1 - 1][`${grid2}`]) /
                                ColdGridData[key1 - 1][`${grid2}`]) *
                              100
                            ).toFixed(3)
                        : "--"
                    }
                    readOnly
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Chart Section */}
      <div className="section">
        <h3>Cold Runner Chart</h3>
        {/* <ChartComponent
          width="80%"
          title="Cold Runner"
          primaryXAxis={{
            title: "Time",
            minimum: TimeniceNumbers.min,
            maximum: TimeniceNumbers.max,
            interval: TimeniceNumbers.step,
          }}
          primaryYAxis={{
            title: ColdGridHeader === "Time" ? "" : ColdGridHeader,
            minimum: WeightniceNumbers.min,
            maximum: WeightniceNumbers.max,
            interval: WeightniceNumbers.step,
          }}
        >
          <Inject services={[LineSeries, Category, DataLabel]} />
          <SeriesCollectionDirective>
            <SeriesDirective
              type="Line"
              fill="rgb(2,0,4)"
              width={2.5}
              dataSource={chartData}
              xName="value0"
              yName={grid2 ? grid2 : "value1"}
              marker={{ visible: true }}
            />
          </SeriesCollectionDirective>
        </ChartComponent> */}
      </div>

      {/* Comments Section */}
      <div className="section">
        <h3>Comments</h3>
        <div className="comment-box">
          <RichTextEditorComponent value={Comment} height={160} width={700}>
            <Inject services={[HtmlEditor]} />
          </RichTextEditorComponent>
        </div>
      </div>
    </div>
  );
};

export default ColdPrint;


