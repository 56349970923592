import React, { useEffect } from "react";
import Header2 from "../common/header-component/header2";
import MainModuleCard from "./MainModuleCard"
import Compatibility from "../../assets/Icons/CompabilityIcon.png";
import Database from "../../assets/Icons/database.png";
import EquipmentQualification from "../../assets/Icons/EquipmentQualification.png";
import Projects from "../../assets/Icons/Projects.png";
import Calculator from "../../assets/Icons/Calculator.png";
import Conversion from "../../assets/Icons/Conversion.png";
import Settings from "../../assets/Icons/Options.png";
import LearningCenter from "../../assets/Icons/LearningCenter.png";
import All_Project_Status from "../../assets/Icons/All_Project_Status.png";
import "./card.css";

const MainDashboard = () => {

  const NameTitleObj = [
    {
      Path: "/database/Database",
      Title: "Databases",
      Icon: Database,
    },
    {
      Path: "/compatibility/Compatible",
      Title: "Compatibility",
      Icon: Compatibility,
    },
    {
      Path: "/mold",
      Title: "Mold",
      Icon: Projects,
    },
    {
      Path: "/sessions/equipmentqualification",
      Title: "Equipment Qualification",
      Icon: EquipmentQualification,
    },
    {
      Path: "/calculators",
      Title: "Calculators",
      Icon: Calculator,
    },
    {
      Path: "/conversions",
      Title: "Conversions",
      Icon: Conversion,
    },
    {
      Path: "/optionnsettings/OptionSetting",
      Title: "Options",
      Icon: Settings,
    },
    {
      Path: "/LearningCenter",
      Title: "Learning Center",
      Icon: LearningCenter,
    },
    {
      Path: "/MoldSessionsDetails",
      Title: "Mold Activity Details",
      Icon: All_Project_Status
    },
  ]

  useEffect(() => {
    sessionStorage.removeItem("SelectedMoldData");
  }, [])

  return (
    <>
      <Header2 Title="Nautilus Web" />

      <div className="mt-2 container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex ml-4">
            <div>
              <h3> Dashboard </h3>
            </div>
          </div>
        </div>

        <div id="main-dashboard" className="d-flex flex-wrap">
          {NameTitleObj.map((item, index) => (
            <div className="col-md-3 mb-4" key={index}>
              <MainModuleCard
                ModuleTitle={item.Title}
                ModulePath={item.Path}
                Icon={item.Icon}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default MainDashboard;
