// ************************************************** Units Data Object *****************************************************
export let Units = {
  TimeUnit: "",
  WeightUnit: "",
  DistanceUnit: "",
  PressureUnit: "",
  VelosityUnit: "",
  TemperatureUnit: "",
};

// ************************************************** HRSett Data Object *****************************************************
export let HRSett = {
  HrNotes: "",
  fldZones: [
    [
      1,
      "",
      25,
      "",
      49,
      "",
      73,
      "",
      97,
      "",
      121,
      ""
    ],
    [
      2,
      "",
      26,
      "",
      50,
      "",
      74,
      "",
      98,
      "",
      122,
      ""
    ],
    [
      3,
      "",
      27,
      "",
      51,
      "",
      75,
      "",
      99,
      "",
      123,
      ""
    ],
    [
      4,
      "",
      28,
      "",
      52,
      "",
      76,
      "",
      100,
      "",
      124,
      ""
    ],
    [
      5,
      "",
      29,
      "",
      53,
      "",
      77,
      "",
      101,
      "",
      125,
      ""
    ],
    [
      6,
      "",
      30,
      "",
      54,
      "",
      78,
      "",
      102,
      "",
      126,
      ""
    ],
    [
      7,
      "",
      31,
      "",
      55,
      "",
      79,
      "",
      103,
      "",
      127,
      ""
    ],
    [
      8,
      "",
      32,
      "",
      56,
      "",
      80,
      "",
      104,
      "",
      128,
      ""
    ],
    [
      9,
      "",
      33,
      "",
      57,
      "",
      81,
      "",
      105,
      "",
      129,
      ""
    ],
    [
      10,
      "",
      34,
      "",
      58,
      "",
      82,
      "",
      106,
      "",
      130,
      ""
    ],
    [
      11,
      "",
      35,
      "",
      59,
      "",
      83,
      "",
      107,
      "",
      131,
      ""
    ],
    [
      12,
      "",
      36,
      "",
      60,
      "",
      84,
      "",
      108,
      "",
      132,
      ""
    ],
    [
      13,
      "",
      37,
      "",
      61,
      "",
      85,
      "",
      109,
      "",
      133,
      ""
    ],
    [
      14,
      "",
      38,
      "",
      62,
      "",
      86,
      "",
      110,
      "",
      134,
      ""
    ],
    [
      15,
      "",
      39,
      "",
      63,
      "",
      87,
      "",
      111,
      "",
      135,
      ""
    ],
    [
      16,
      "",
      40,
      "",
      64,
      "",
      88,
      "",
      112,
      "",
      136,
      ""
    ],
    [
      17,
      "",
      41,
      "",
      65,
      "",
      89,
      "",
      113,
      "",
      137,
      ""
    ],
    [
      18,
      "",
      42,
      "",
      66,
      "",
      90,
      "",
      114,
      "",
      138,
      ""
    ],
    [
      19,
      "",
      43,
      "",
      67,
      "",
      91,
      "",
      115,
      "",
      139,
      ""
    ],
    [
      20,
      "",
      44,
      "",
      68,
      "",
      92,
      "",
      116,
      "",
      140,
      ""
    ],
    [
      21,
      "",
      45,
      "",
      69,
      "",
      93,
      "",
      117,
      "",
      141,
      ""
    ],
    [
      22,
      "",
      46,
      "",
      70,
      "",
      94,
      "",
      118,
      "",
      142,
      ""
    ],
    [
      23,
      "",
      47,
      "",
      71,
      "",
      95,
      "",
      119,
      "",
      143,
      ""
    ],
    [
      24,
      "",
      48,
      "",
      72,
      "",
      96,
      "",
      120,
      "",
      144,
      ""
    ]
  ],
  NozzleType: "",
  NozzleLength: "",
  NozzleOrificeSize: "",
};

// Inputs Tab
// ************************************************** PHTime Data Object *****************************************************
export let PHTime = {
  fldPHTime1: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldPHTime2: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldPHTime3: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldPHTime4: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldPHTime5: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldPHTime6: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
};

// ************************************************** PHPress Data Object *****************************************************
export let PHPress = {
  fldPHPress1: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldPHPress2: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldPHPress3: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldPHPress4: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldPHPress5: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldPHPress6: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
};

// ************************************************** PHVeloc Data Object *****************************************************
export let PHVeloc = {
  fldPHVeloc1: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldPHVeloc2: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldPHVeloc3: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldPHVeloc4: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldPHVeloc5: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldPHVeloc6: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
};

// ************************************************** InjPress Data Object *****************************************************
export let InjPress = {
  fldInjPress1: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldInjPress2: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldInjPress3: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldInjPress4: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldInjPress5: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
};

// ************************************************** InjSpeed Data Object *****************************************************
export let InjSpeed = {
  fldInjSpeed1: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldInjSpeed2: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldInjSpeed3: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldInjSpeed4: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldInjSpeed5: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
};

// ************************************************** fldShotSize Data Object *****************************************************
export let fldShotSize = {
  paramID: "",
  paramName: "",
  paramUnit: "",
  paramValue: "",
};

// ************************************************** fldBackPress Data Object *****************************************************
export let fldBackPress = {
  paramID: "",
  paramName: "",
  paramUnit: "",
  paramValue: "",
};

// ************************************************** Decompression Data Object *****************************************************
export let Decompression = {
  fldSpeed: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldDistance: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
};

// ************************************************** MoldTempASide Data Object *****************************************************
export let MoldTempASide = {
  "fldMoldTempASide1": {
    "paramID": "44",
    "paramName": "fldMoldTempASide1",
    "paramUnit": 17,
    "paramValue": ""
  },
  "fldMoldTempASide2": {
    "paramID": "45",
    "paramName": "fldMoldTempASide2",
    "paramUnit": 17,
    "paramValue": ""
  },
  "fldMoldTempASide3": {
    "paramID": "46",
    "paramName": "fldMoldTempASide3",
    "paramUnit": 17,
    "paramValue": ""
  }
}

// ************************************************** MoldTempBSide Data Object *****************************************************
export let MoldTempBSide = {
  "fldMoldTempBSide1": {
    "paramID": "47",
    "paramName": "fldMoldTempBSide1",
    "paramUnit": 17,
    "paramValue": ""
  },
  "fldMoldTempBSide2": {
    "paramID": "48",
    "paramName": "fldMoldTempBSide2",
    "paramUnit": 17,
    "paramValue": ""
  },
  "fldMoldTempBSide3": {
    "paramID": "49",
    "paramName": "fldMoldTempBSide3",
    "paramUnit": 17,
    "paramValue": ""
  }
}

// ************************************************** MoldTempNotes Data Object *****************************************************
export let MoldTempNotes = {
  fldMoldTempNotes1: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldMoldTempNotes2: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldMoldTempNotes3: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
};

// ************************************************** fldCoolingTime Data Object *****************************************************
export let fldCoolingTime = {
  paramID: "",
  paramName: "",
  paramUnit: "",
  paramValue: "",
};

// ************************************************** fldObtainedCushion Data Object **************************************************
export let fldObtainedCushion = {
  paramID: "",
  paramName: "",
  paramUnit: "",
  paramValue: "",
};

// ************************************************** fldScrewRotationSpeed Data Object ***********************************************
export let fldScrewRotationSpeed = {
  paramID: "",
  paramName: "",
  paramUnit: "",
  paramValue: "",
};

// ************************************************** TransferPosition Data Object ***************************************************
export let TransferPosition = {
  fldTransferPosition1: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldTransferPosition2: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldTransferPosition3: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldTransferPosition4: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldTransferPosition5: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
};

// ************************************************** BarrelTempSetting Data Object **************************************************
export let BarrelTempSetting = {
  fldH1: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldH2: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldH3: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldH4: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldH5: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldH6: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldH7: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldH8: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldH9: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldH10: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldNozzle: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
};

// ************************************************** Outputs Data Object **************************************************
export let Output = {
  fldFillTime: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldMeltTemp: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldMoldTemp: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldCycleTime: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldCushionValue: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldInjPressPeak: {
    paramID: "",
    paramName: "",
    paramUnit: "3",
    paramValue: "",
  },
  fldInjPressTransfer: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldInjOnlyShotWeight: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldScrewRotationTime: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
  fldPartsAndRunnerWeight: {
    paramID: "",
    paramName: "",
    paramUnit: "",
    paramValue: "",
  },
};
