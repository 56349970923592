import React, { useEffect, useState, useRef } from "react";
import "../App.css";

// Tab view component from syncfusion to navigate through six steps study
import {
  TabComponent,
  TabItemDirective,
  TabItemsDirective,
} from "@syncfusion/ej2-react-navigations";

import { useParams, useHistory } from "react-router-dom";

// Event having multiple method's to deal with the back-end
import DataService from "../../services/ApiService";

// Import all the six step's component which are created outside this file in same folder to code complexity
import ViscocityCurve from "./Viscosity Curve/ViscocityCurve";
import CavityBalance from "./CavityBalance";
import PressureDropStudy from "./PressureDropStudy";
import CosmeticPressure from "./CosmeticPressure";
import CoolingTimeStudy from "./CoolingTimeStudy";
import ColdRunner from "./ColdRunner";

import { ToastContainer } from "react-toastify";
import Header2 from "../common/header-component/header2";

import { Button } from "reactstrap";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";

import ViscosityPrint from "../printables/ViscosityPrint";
import CavityPrint from "../printables/CavityPrint";
import PressurePrint from "../printables/PressurePrint";
import CosmeticPrint from "../printables/CosmeticPrint";
import CoolingPrint from "../printables/CoolingPrint";
import ColdPrint from "../printables/ColdPrint";

// import { useReactToPrint } from "react-to-print";

// Using a Function Component
const SixStepStudy = () => {
  const history = useHistory();

  const showAlert = useRef(false);

  // To get the Mold Id from url using useParams hook
  var { moldId, moldName, sessionName, sessionId } = useParams();

  // Variable to store the a Mold/Session Name
  const [Mold_Name, setMold_Name] = useState();
  const [Mold_Id, setMold_Id] = useState();
  const [Session_Name, setSession_Name] = useState();

  const GoToMolds = useRef(false);

  // Instance of dialog box Asking for saving data before leaving study
  const dialogBox = document.getElementById("dialogBox");

  // Instance of dialog box which will be shown after saving
  const SavedandExitdialogBox = document.getElementById(
    "SavedandExitdialogBox"
  );

  // Event to get back to Session's
  const BackToMold = () => {
    if (showAlert.current) {
      dialogBox.classList.remove("hidden");
    } else {
      if (GoToMolds.current) {
        history.push("/dashboard");
      } else {

        history.push(`/mold`);
      }
    }
  };

  const BackToDashboard = () => {
    if (showAlert.current) {
      dialogBox.classList.remove("hidden");
      GoToMolds.current = true;
    } else {
      history.push("/dashboard");
    }
  };

  // Declare the names of the tab's
  let headertext = [
    { text: "Viscosity Curve" },
    { text: "Cavity Balance" },
    { text: "Pressure Drop Study" },
    { text: "Cosmetic Process Window" },
    { text: "Gate Seal Study" },
    { text: "Cooling Time Study" },
  ];

  // Session Id getting from the URL
  const [SessionId, setSessionId] = useState();

  // ****************** States and functions for all the study ***************************

  // Boolean variable to switch between the save and update button
  const ToSaveViscosityData = useRef(true);
  const ToSaveCavityData = useRef(true);
  const ToSavePressureData = useRef(true);
  const ToSaveCosmeticData = useRef(true);
  const ToSaveColdData = useRef(true);
  const ToSaveCoolingData = useRef(true);

  const ViscosityData = useRef([]);
  const CavityData = useRef([]);
  const PressureData = useRef([]);
  const CosmeticData = useRef([]);
  const ColdData = useRef([]);
  const CoolingData = useRef([]);

  const ViscosityPrintData = useRef({
    Injection_Speed_Units: "",
    IntensificationRatio: "",
    Pressure_Units: "",
    Injection_Speed: "",
    Mold_Name: "",
    Session_Name: "",
    Comment: "",
    niceNumbers: [],
    InjectionniceNumbers: [],
    ShearRateNiceNumbers: [],
    InjectionSpeedArray: [],
    Grid_Data: [],
    YTitle: "",
  });
  const CavityPrintData = useRef(
    {
      column: [],
      CavityGridData: [],
      Total: [],
      Average: [],
      MaxPart: [],
      MinPart: [],
      Percentage: [],
      Range: [],
      chartData: [],
      chartData2: [],
      Mold_Name: "",
      Session_Name: "",
      Comment: "",
    }
  );
  const PressurePrintData = useRef({
    Units: [],
    Max_Press_Available: "",
    PressureGridRow: [],
    Mold_Name: "",
    Session_Name: "",
    niceNumbers: {},
    Comment: "",
    ChartData: [],
    MaxPressData: [],
  });
  const CosmeticPrintData = useRef({
    "Melting": "",
    "Hydraulic": "",
    "CosmeticGridData": [],
    "chartData": [],
    "centerPoints": [],
    "Mold_Name": "",
    "Session_Name": "",
    "textRender": {},
    "Comment": "",
    "PressureUnits": "",
    "TemperatureUnits": "",
    "XniceNumbers": {},
    "YniceNumbers": {}
  });
  const ColdPrintData = useRef({
    column: [],
    ColdGridData: [],
    grid2: [],
    Mold_Name: "",
    Session_Name: "",
    ColdGridHeader: [],
    Comment: "",
    chartData: [],
    WeightniceNumbers: [],
    TimeniceNumbers: [],
  });
  const CoolingPrintData = useRef({
    column: [],
    CoolingTimeGridData: [],
    grid2: [],
    Mold_Name: "",
    Session_Name: "",
    CoolingGridHeader: [],
    Comment: "",
    chartData: [],
    TimeniceNumbers: {},
    DimniceNumbers: {},
  });

  // Event to Call the POST request API and save the data
  const saveData = () => {
    return new Promise((resolve, reject) => {

      if (ToSaveViscosityData.current) {

        // console.log(ToSaveViscosityData.current)

        DataService.SaveViscosity(ViscosityData.current)
          .then((res) => {

            // console.log(res)

            if (res.data.message !== "Please Enter a valid Data") {

              ToSaveViscosityData.current = false;

              showAlert.current = false;

            }
          })
          .catch((err) => { });
      } else {

        // console.log(ToSaveViscosityData.current)

        DataService.UpdateViscosity(SessionId, ViscosityData.current)
          .then((res) => {

            // console.log(res)

            if (res.data.message !== "Please Enter a valid Data") {

              ToSaveViscosityData.current = false;

              showAlert.current = false;
            }
          })
          .catch((err) => { });
      }

      if (ToSaveCavityData.current) {

        // console.log(ToSaveCavityData.current)

        DataService.SaveCavity(CavityData.current)
          .then((res) => {

            // console.log(res)

            if (res.data.message !== "Please Enter a valid Data") {

              ToSaveCavityData.current = false;

              showAlert.current = false;
            }
          })
          .catch((err) => { });

      } else {

        // console.log(ToSaveCavityData.current)

        DataService.UpdateCavity(SessionId, CavityData.current)
          .then((res) => {

            // console.log(res)

            if (res.data.message !== "Please Enter a valid Data") {

              ToSaveCavityData.current = false;

              showAlert.current = false;

            }
          })
          .catch((err) => { });
      }

      if (ToSavePressureData.current) {

        // console.log(ToSavePressureData.current, 'saved')

        DataService.SavePressure(PressureData.current)
          .then((res) => {

            // console.log(res)

            if (res.data.message !== "Please Enter a valid Data") {

              ToSavePressureData.current = false;

              showAlert.current = false;

            }
          })
          .catch((err) => { });
      } else {

        // console.log(PressureData.current, 'updated')

        DataService.UpdatePressure(SessionId, PressureData.current)
          .then((res) => {

            // console.log(res)

            if (res.data.message !== "Please Enter a valid Data") {

              ToSavePressureData.current = false;

              showAlert.current = false;

            }
          })
          .catch((err) => { });
      }

      if (ToSaveCosmeticData.current) {

        // console.log(ToSaveCosmeticData.current)

        DataService.SaveCosmetic(CosmeticData.current)
          .then((res) => {

            // console.log(res)

            if (res.data.message !== "Please Enter a valid Data") {

              ToSaveCosmeticData.current = false;

              showAlert.current = false;

            }
          })
          .catch((err) => { });
      } else {

        // console.log(ToSaveCosmeticData.current)

        DataService.UpdateCosmetic(SessionId, CosmeticData.current)
          .then((res) => {

            // console.log(res)

            if (res.data.message !== "Please Enter a valid Data") {

              ToSaveCosmeticData.current = false;

              showAlert.current = false;

            }
          })
          .catch((err) => { });
      }

      if (ToSaveColdData.current) {

        // console.log(ToSaveColdData.current)

        DataService.SaveGateSeal(ColdData.current)
          .then((res) => {

            // console.log(res)

            if (res.data.message !== "Please Enter a valid Data") {

              ToSaveColdData.current = false;

              showAlert.current = false;

            }
          })
          .catch((err) => { });
      } else {

        // console.log(ToSaveColdData.current)

        DataService.UpdateGateSeal(SessionId, ColdData.current)
          .then((res) => {

            // console.log(res)

            if (res.data.message !== "Please Enter a valid Data") {

              ToSaveColdData.current = false;

              showAlert.current = false;

            }
          })
          .catch((err) => { });
      }

      if (ToSaveCoolingData.current) {

        // console.log(ToSaveCoolingData.current)

        DataService.SaveCooling(CoolingData.current)
          .then((res) => {

            // console.log(res)

            if (res.data.message !== "Please Enter a valid Data") {

              ToSaveCoolingData.current = false;

              showAlert.current = false;

            }
          })
          .catch((err) => { })

      } else {

        // console.log(ToSaveCoolingData.current)

        DataService.UpdateCooling(SessionId, CoolingData.current)
          .then((res) => {

            // console.log(res)

            if (res.data.message !== "Please Enter a valid Data") {

              ToSaveCoolingData.current = false;

              showAlert.current = false;

            }
          })
          .catch((err) => { });
      }

      resolve();
    });
  };

  const [state, set] = useState(0);

  // Event to GET the current session's viscosity data
  const handleGet = (SessionId) => {
    if (SessionId) {

      DataService.FetchViscosity(SessionId)
        .then((res) => {
          set((prev) => prev + 1);
          if (res.data) {
            ViscosityPrintData.current = res.data.PrintData
          } else {
          }
        })
        .catch((err) => { });

      DataService.FetchCavity(SessionId)
        .then((res) => {
          set((prev) => prev + 1);
          if (res.data) {
            CavityPrintData.current = res.data.PrintData
          } else {
          }
        })
        .catch((err) => { });

      DataService.FetchCosmetic(SessionId)
        .then((res) => {
          set((prev) => prev + 1);
          if (res.data) {
            CosmeticPrintData.current = res.data.PrintData
          } else {
          }
        })
        .catch((err) => { });

      DataService.FetchPressure(SessionId)
        .then((res) => {
          set((prev) => prev + 1);
          if (res.data) {
            PressurePrintData.current = res.data.PrintData
          } else {
          }
        })
        .catch((err) => { });

      DataService.FetchGateSeal(SessionId)
        .then((res) => {
          set((prev) => prev + 1);
          if (res.data) {
            ColdPrintData.current = res.data.PrintData
          } else {
          }
        })
        .catch((err) => { });

      DataService.FetchCooling(SessionId)
        .then((res) => {
          set((prev) => prev + 1);
          if (res.data) {
            CoolingPrintData.current = res.data.PrintData
          } else {
          }
        })
        .catch((err) => { });
    } else {

    }
  };

  // Event that will be called as soon as the Viscosity Curve Page load's so that if there is data available will get fetched
  useEffect(() => {

    // After that it call's this event to fetch the data
    if (SessionId) handleGet(SessionId);

    // console.log(SessionId);

    // eslint-disable-next-line
  }, [SessionId]);

  // This function will be called when the user clicks on the save button of the confirmation dialog box before exiting the study
  const SaveandExit = () => {
    saveData().then(() => {
      closeDialog();
      SavedandExitdialogBox.classList.remove("hidden");
    });
  };

  // This function will be called when the user clicks on the save button of the study
  const SavedModal = () => {
    saveData().then(() => {
      const saveddialogBox = document.getElementById("SaveddialogBox");

      saveddialogBox.classList.remove("hidden");
    });
  };

  // Event to close the saving confirmation dialog
  const closeDialog = () => {
    const dialogBox = document.getElementById("dialogBox");

    dialogBox.classList.add("hidden");
  };

  // Event to finally close and exit study after saving
  const closeandExitSavedDialog = () => {
    const saveddialogBox = document.getElementById("SavedandExitdialogBox");

    saveddialogBox.classList.add("hidden");

    if (GoToMolds.current) {
      history.push("/dashboard");
    } else {

      history.push(`/mold`);
    }
  };

  // Event to close the dialog which will be shown after saving
  const closeSavedDialog = () => {
    const saveddialogBox = document.getElementById("SaveddialogBox");

    saveddialogBox.classList.add("hidden");
  };

  // Hooks and Events for the Print Control
  const [showPrint, setShowPrint] = useState(false);

  const ViscosityComponentRef = useRef();
  const CavityComponentRef = useRef();
  const PressureComponentRef = useRef();
  const CosmeticComponentRef = useRef();
  const ColdComponentRef = useRef();
  const CoolingComponentRef = useRef();

  // These are the event's which will get called when clicked on the respective step's tab and once they are called they render the component of that respective tab which we have imported above
  function content0() {
    return (
      <div>

        <ViscocityCurve
          showAlert={showAlert}
          ViscosityData={ViscosityData}
          ToSaveViscosityData={ToSaveViscosityData}
          showPrint={showPrint}
          setShowPrint={setShowPrint}
          ViscosityComponentRef={ViscosityComponentRef}
        />

        {/* <section
          ref={ViscosityComponentRef}
        >
          <ViscosityPrint
            Injection_Speed_Units={ViscosityPrintData.current.Injection_Speed_Units}
            IntensificationRatio={ViscosityPrintData.current.IntensificationRatio}
            Pressure_Units={ViscosityPrintData.current.Pressure_Units}
            Injection_Speed={ViscosityPrintData.current.Injection_Speed}
            Mold_Name={ViscosityPrintData.current.Mold_Name}
            Session_Name={ViscosityPrintData.current.Session_Name}
            Comment={ViscosityPrintData.current.Comment}
            niceNumbers={ViscosityPrintData.current.niceNumbers}
            InjectionniceNumbers={ViscosityPrintData.current.InjectionniceNumbers}
            ShearRateNiceNumbers={ViscosityPrintData.current.ShearRateNiceNumbers}
            InjectionSpeedArray={ViscosityPrintData.current.InjectionSpeedArray}
            YTitle={ViscosityPrintData.current.YTitle}
          />
        </section>

        <section ref={CavityComponentRef}>
          <CavityPrint
            column={CavityPrintData.current.column}
            CavityGridData={CavityPrintData.current.CavityGridData}
            Total={CavityPrintData.current.Total}
            Average={CavityPrintData.current.Average}
            MaxPart={CavityPrintData.current.MaxPart}
            MinPart={CavityPrintData.current.MinPart}
            Percentage={CavityPrintData.current.Percentage}
            Range={CavityPrintData.current.Range}
            chartData={CavityPrintData.current.chartData}
            chartData2={CavityPrintData.current.chartData2}
            Mold_Name={CavityPrintData.current.Mold_Name}
            Session_Name={CavityPrintData.current.Session_Name}
            Comment={CavityPrintData.current.Comment}
          />
        </section>

        <section ref={PressureComponentRef}>
          <PressurePrint
            Units={PressurePrintData.current.Units}
            Max_Press_Available={PressurePrintData.current.Max_Press_Available}
            PressureGridRow={PressurePrintData.current.PressureGridRow}
            Mold_Name={PressurePrintData.current.Mold_Name}
            Session_Name={PressurePrintData.current.Session_Name}
            niceNumbers={PressurePrintData.current.niceNumbers}
            Comment={PressurePrintData.current.Comment}
            ChartData={PressurePrintData.current.ChartData}
            MaxPressData={PressurePrintData.current.MaxPressData}
          />
        </section>

        <section ref={CosmeticComponentRef}>
          <CosmeticPrint
            Melting={CosmeticPrintData.current.Melting}
            Hydraulic={CosmeticPrintData.current.Hydraulic}
            CosmeticGridData={CosmeticPrintData.current.CosmeticGridData}
            chartData={CosmeticPrintData.current.chartData}
            centerPoints={CosmeticPrintData.current.centerPoints}
            Mold_Name={CosmeticPrintData.current.Mold_Name}
            Session_Name={CosmeticPrintData.current.Session_Name}
            textRender={CosmeticPrintData.current.textRender}
            Comment={CosmeticPrintData.current.Comment}
            PressureUnits={CosmeticPrintData.current.PressureUnits}
            TemperatureUnits={CosmeticPrintData.current.TemperatureUnits}
            XniceNumbers={CosmeticPrintData.current.XniceNumbers}
            YniceNumbers={CosmeticPrintData.current.YniceNumbers}
          />
        </section>

        <section
          ref={ColdComponentRef}
        >
          <ColdPrint
            column={ColdPrintData.current.column}
            ColdGridData={ColdPrintData.current.ColdGridData}
            grid2={ColdPrintData.current.grid2}
            Mold_Name={ColdPrintData.current.Mold_Name}
            Session_Name={ColdPrintData.current.Session_Name}
            ColdGridHeader={ColdPrintData.current.ColdGridHeader}
            Comment={ColdPrintData.current.Comment}
            chartData={ColdPrintData.current.chartData}
            WeightniceNumbers={ColdPrintData.current.WeightniceNumbers}
            TimeniceNumbers={ColdPrintData.current.TimeniceNumbers}
          />
        </section>

        <section
          ref={CoolingComponentRef}
        >
          <CoolingPrint
            column={CoolingPrintData.current.column}
            CoolingTimeGridData={CoolingPrintData.current.CoolingTimeGridData}
            grid2={CoolingPrintData.current.grid2}
            Mold_Name={CoolingPrintData.current.Mold_Name}
            Session_Name={CoolingPrintData.current.Session_Name}
            CoolingGridHeader={CoolingPrintData.current.CoolingGridHeader}
            Comment={CoolingPrintData.current.Comment}
            chartData={CoolingPrintData.current.chartData}
            TimeniceNumbers={CoolingPrintData.current.TimeniceNumbers}
            DimniceNumbers={CoolingPrintData.current.DimniceNumbers}
          />
        </section> */}

      </div>
    );
  }

  function content1() {
    return (
      <div>
        <CavityBalance
          showAlert={showAlert}
          CavityData={CavityData}
          ToSaveCavityData={ToSaveCavityData}
          CavityComponentRef={CavityComponentRef}
        />
      </div>
    );
  }

  function content2() {
    return (
      <div>
        <PressureDropStudy
          showAlert={showAlert}
          PressureData={PressureData}
          ToSavePressureData={ToSavePressureData}
        />


      </div>
    );
  }

  function content3() {
    return (
      <div>
        <CosmeticPressure
          showAlert={showAlert}
          CosmeticData={CosmeticData}
          ToSaveCosmeticData={ToSaveCosmeticData}
        />
      </div>
    );
  }

  function content4() {
    return (
      <div>
        <ColdRunner
          showAlert={showAlert}
          ColdData={ColdData}
          ToSaveColdData={ToSaveColdData}
        />
      </div>
    );
  }

  function content5() {
    return (
      <div>
        <CoolingTimeStudy
          showAlert={showAlert}
          CoolingData={CoolingData}
          ToSaveCoolingData={ToSaveCoolingData}
        />
      </div>
    );
  }

  useEffect(() => {
    // Using the "atob" method we are decrypting the values getting from the URL
    setMold_Name(atob(moldName));
    setSession_Name(atob(sessionName));
    setMold_Id(atob(moldId));
    setSessionId(atob(sessionId));

    // console.log(Mold_Name)
  }, [moldName, sessionName, moldId, sessionId, SessionId]);

  const tabInstance = useRef(null);

  const GetDataForPrint = async () => {

    setTimeout(async () => {

      const pdf = new jsPDF("p", "mm", "a4");
      let yOffset = 10; // Keeps track of Y position

      const captureAndAddImage = async (ref, label) => {
        if (ref.current) {
          try {
            ref.current.style.display = "block"; // Ensure visibility
            await new Promise((resolve) => setTimeout(resolve, 200)); // Allow rendering

            const canvas = await html2canvas(ref.current, { scale: 2 });
            const imgData = canvas.toDataURL("image/JPEG");

            const imgWidth = 180; // Fixed width
            const imgHeight = (canvas.height / canvas.width) * imgWidth; // Dynamic height

            // Ensure it doesn't overflow the page
            if (yOffset + imgHeight > 280) { // A4 height limit
              pdf.addPage();
              yOffset = 10;
            }

            // pdf.text(label, 10, yOffset - 5);

            pdf.addImage(imgData, "JPEG", 10, yOffset, imgWidth, imgHeight);
            yOffset += imgHeight + 10; // Adjust spacing
          } catch (error) {
            console.error(`Error capturing ${label}:`, error);
          }
        }
      };

      // console.log(ViscosityComponentRef, CavityComponentRef)

      // Capture each component sequentially
      await captureAndAddImage(ViscosityComponentRef, "Viscosity Chart");
      await captureAndAddImage(CavityComponentRef, "Cavity Chart");
      await captureAndAddImage(PressureComponentRef, "Pressure Drop Chart");
      await captureAndAddImage(CosmeticComponentRef, "Cosmetic Chart");
      await captureAndAddImage(ColdComponentRef, "Cosmetic Chart");
      await captureAndAddImage(CoolingComponentRef, "Cosmetic Chart");

      // Optional: Add table below charts
      // pdf.autoTable({ startY: yOffset, head: [...], body: [...] });

      pdf.save("6 Step Study.pdf");

    }, 500)

  };

  return (
    <>
      {/* ************************************************ Saving Modals *******************************************/}
      {/* To alert while going back to sessions page */}
      <div id="dialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Do you want to save 6 step study ? </p>
          <div className="button-row">
            <div className="m-1">
              <Button className="mr-1" id="closeDialogBtn" onClick={SaveandExit}>
                {" "}
                Yes{" "}
              </Button>
            </div>
            <div className="m-1">
              <Button
                className="mr-1"
                id="closeDialogBtn"
                onClick={closeandExitSavedDialog}
              >
                {" "}
                No{" "}
              </Button>
            </div>
            <div className="m-1">
              <Button className="mr-1" id="closeDialogBtn" onClick={closeDialog}>
                {" "}
                Cancel{" "}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* To show when data is saved and when clicked on close button exit */}
      <div id="SavedandExitdialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Saved Successfully. </p>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeandExitSavedDialog}
          >
            {" "}
            Close{" "}
          </Button>
        </div>
      </div>

      {/* To show when data is saved, when clicked on saved button */}
      <div id="SaveddialogBox" className="hidden">
        <h5> Nautilus </h5>
        <hr></hr>
        <div className="dialog-content">
          <p> Saved Successfully. </p>
          <Button
            className="mr-1"
            id="closeDialogBtn"
            onClick={closeSavedDialog}
          >
            {" "}
            Close{" "}
          </Button>
        </div>
      </div>

      {/* ***********************************************************************************************************  */}
      <Header2
        Title="Six Step Study"
        Mold_Name={Mold_Name}
        Session_Name={Session_Name}
      />

      <div className="container-fluid">
        <div className="d-flex justify-content-between ml-3 pt-3 pb-3">
          <div className="d-flex ">
            <div>
              <span
                className="BreadCrum"
                onClick={BackToDashboard}
                style={{ fontSize: "14px" }}
              >
                {" "}
                Dashboard{" "}
              </span>
            </div>

            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span
                className="BreadCrum"
                onClick={BackToMold}
                style={{ fontSize: "14px" }}
              >
                {" "}
                Mold{" "}
              </span>
            </div>
            <div>
              <span className="BreadCrum" style={{ fontSize: "16px" }}>
                {" "}
                {">"}{" "}
              </span>
            </div>
            <div>
              <span style={{ fontSize: "14px" }}>
                {" "}
                Six Step Study{" "}
              </span>
            </div>
          </div>

          <div className="d-flex b-primary b-r-4 pt-1">
            {/* <div className="ml-2">
              <span className="Mold-Session"> Mold Id :  </span>
            </div> */}

            <div>
              <span className="Mold-Session p-2">
                {" "}
                Session Name : {Session_Name}
              </span>
            </div>
          </div>
        </div>

        {/* <div className="study-container" id="Container" className="container-fluid"> */}

        <div className="d-flex">
          <div className="col-md-10">
            {/* This is Syncfusion Tab control in which header attribute is to display the name of that tab and content attribute to display the content under that tab */}
            <TabComponent
              ref={tabInstance}
              heightAdjustMode="Auto"
              widthAdjustMode="Auto"
              id="defaultTab"
            >
              <TabItemsDirective>
                <TabItemDirective header={headertext[0]} content={content0} />
                <TabItemDirective header={headertext[1]} content={content1} />
                <TabItemDirective header={headertext[2]} content={content2} />
                <TabItemDirective header={headertext[3]} content={content3} />
                <TabItemDirective header={headertext[4]} content={content4} />
                <TabItemDirective header={headertext[5]} content={content5} />
              </TabItemsDirective>
            </TabComponent>
          </div>

          <div className="col-md-2">
            <button
              className="btn btn-secondary btn-air-secondary m-0"
              type="button"
              onClick={SavedModal}
            >
              {" "}
              Save{" "}
            </button>

            {/* <button
              className="btn btn-secondary btn-air-secondary mr-2 ml-2"
              type="button"
              onClick={GetDataForPrint}
            >
              Print
            </button> */}
          </div>
        </div>
        {/* </div> */}
      </div>
      <ToastContainer />

    </>
  );
};

export default SixStepStudy;
