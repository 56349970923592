import React from "react";

const Outputs = ({ PPS_Data, Machine_Unit_Data, showAlert }) => {
  const CreateInputData = (event) => {
    const { name, value, dataset } = event.target;

    let data = {
      paramID: dataset.id,
      paramName: name,
      paramUnit:
        dataset.section === "weight"
          ? Machine_Unit_Data.weight.unit_id
          : dataset.section === "press"
            ? Machine_Unit_Data.pressure.unit_id
            : dataset.section === "time"
              ? Machine_Unit_Data.time.unit_id
              : dataset.section === "temperature"
                ? Machine_Unit_Data.temperature.unit_id
                : "",
      paramValue: value,
    };

    // console.log(data)

    PPS_Data.Outputs[0][name] = data;

    showAlert.current = true;
  };

  // useEffect(() => {
  //   setTabIdx(4);
  // }, []);

  return (
    <div
      className="p-2"
      style={{ border: "1px solid black", backgroundColor: "#d2dce6" }}
    >
      <div
        className="d-flex flex-column align-items-left"
        style={{ width: "30vw" }}
      >
        <div className="mb-2 d-flex align-items-left justify-content-between">

          {/* <div>
            <span className="mr-1">1.</span>
            <span>Melt Temperature:</span>
          </div>
          <div>
            <input
              className="form-control"
              onKeyPress={(event) => {
                if (!/[-0.0-9.0]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              data-id={"55"}
              data-section={"temperature"}
              name="fldMeltTemp"
              onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldMeltTemp?.paramValue}
            />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">2.</span>
            <span>Mold Temp:</span>
          </div>
          <div>
            <input
              className="form-control"
              onKeyPress={(event) => {
                if (!/[-0.0-9.0]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              data-id={"56"}
              data-section={"temperature"}
              name="fldMoldTemp"
              onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldMoldTemp?.paramValue}
            />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">3.</span>
            <span>Fill Time:</span>
          </div>
          <div>
            <input
              className="form-control"
              onKeyPress={(event) => {
                if (!/[-0.0-9.0]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              data-id={"57"}
              data-section={"time"}
              name="fldFillTime"
              onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldFillTime?.paramValue}
            />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">4.</span>
            <span>Actual Peak Injection Pressure:</span>
          </div>
          <div>
            <input
              className="form-control"
              onKeyPress={(event) => {
                if (!/[-0.0-9.0]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              data-id={"58"}
              data-section={"press"}
              name="fldInjPressPeak"
              onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldInjPressPeak?.paramValue}
            />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">5.</span>
            <span>Press At Transfer:</span>
          </div>
          <div>
            <input
              className="form-control"
              onKeyPress={(event) => {
                if (!/[-0.0-9.0]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              data-id={"59"}
              name="fldInjPressTransfer"
              onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldInjPressTransfer?.paramValue}
            />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">6.</span>
            <span>Cushion Value:</span>
          </div>
          <div>
            <input
              className="form-control"
              onKeyPress={(event) => {
                if (!/[-0.0-9.0]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              data-id={"60"}
              name="fldCushionValue"
              onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldCushionValue?.paramValue}
            />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">7.</span>
            <span>Screw Rotation Time:</span>
          </div>
          <div>
            <input
              className="form-control"
              onKeyPress={(event) => {
                if (!/[-0.0-9.0]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              data-id={"61"}
              name="fldScrewRotationTime"
              onChange={CreateInputData}
              defaultValue={
                PPS_Data.Outputs[0].fldScrewRotationTime?.paramValue
              }
            />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">8.</span>
            <span>Cycle Time:</span>
          </div>
          <div>
            <input
              className="form-control"
              onKeyPress={(event) => {
                if (!/[-0.0-9.0]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              data-id={"62"}
              data-section={"time"}
              name="fldCycleTime"
              onChange={CreateInputData}
              defaultValue={PPS_Data.Outputs[0].fldCycleTime?.paramValue}
            />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">9.</span>
            <span>Inj Only Shot Weight:</span>
          </div>
          <div>
            <input
              className="form-control"
              onKeyPress={(event) => {
                if (!/[-0.0-9.0]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              data-id={"63"}
              data-section={"weight"}
              name="fldInjOnlyShotWeight"
              onChange={CreateInputData}
              defaultValue={
                PPS_Data.Outputs[0].fldInjOnlyShotWeight?.paramValue
              }
            />
          </div>
        </div>

        <div className="mb-2 d-flex align-items-left justify-content-between">
          <div>
            <span className="mr-1">10.</span>
            <span>Part and Runner Weight:</span>
          </div>
          <div>
            <input
              className="form-control"
              onKeyPress={(event) => {
                if (!/[-0.0-9.0]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              data-id={"64"}
              name="fldPartsAndRunnerWeight"
              onChange={CreateInputData}
              defaultValue={
                PPS_Data.Outputs[0].fldPartsAndRunnerWeight?.paramValue
              }
            />
          </div> */}

          <tr>
            {[
              { label: "Melt Temperature:", name: "fldMeltTemp", value: PPS_Data.Outputs[0].fldMeltTemp?.paramValue, id: "55", section: "temperature" },
              { label: "Mold Temp:", name: "fldMoldTemp", value: PPS_Data.Outputs[0].fldMoldTemp?.paramValue, id: "56", section: "temperature" },
              { label: "Fill Time:", name: "fldFillTime", value: PPS_Data.Outputs[0].fldFillTime?.paramValue, id: "57", section: "time" },
              { label: "Actual Peak Injection Pressure:", name: "fldInjPressPeak", value: PPS_Data.Outputs[0].fldInjPressPeak?.paramValue, id: "58", section: "press" },
              { label: "Press At Transfer:", name: "fldInjPressTransfer", value: PPS_Data.Outputs[0].fldInjPressTransfer?.paramValue, id: "59", section: "press" },
              { label: "Cushion Value:", name: "fldCushionValue", value: PPS_Data.Outputs[0].fldCushionValue?.paramValue, id: "60", section: "press" },
              { label: "Screw Rotation Time:", name: "fldScrewRotationTime", value: PPS_Data.Outputs[0].fldScrewRotationTime?.paramValue, id: "61", section: "time" },
              { label: "Cycle Time:", name: "fldCycleTime", value: PPS_Data.Outputs[0].fldCycleTime?.paramValue, id: "62", section: "time" },
              { label: "Inj Only Shot Weight:", name: "fldInjOnlyShotWeight", value: PPS_Data.Outputs[0].fldInjOnlyShotWeight?.paramValue, id: "63", section: "weight" },
              { label: "Part and Runner Weight:", name: "fldPartsAndRunnerWeight", value: PPS_Data.Outputs[0].fldPartsAndRunnerWeight?.paramValue, id: "64", section: "weight" }
            ].map(({ label, name, value, id, section }, index) => (
              <div className="mb-2 d-flex align-items-left justify-content-between" key={index}>
                <div>
                  <span className="mr-1">{index + 1}.</span>
                  <span>{label}</span>
                </div>
                <div>
                  <input
                    className="form-control"
                    tabIndex={1}
                    onKeyPress={(event) => {
                      if (!/[-0.0-9.0]/.test(event.key)) {
                        event.preventDefault(); // Only numbers, negative sign and period are allowed
                      }
                    }}
                    onPaste={(event) => {
                      const pastedData = event.clipboardData.getData("text");

                      // Prevent pasting if the data contains any alphabetic characters
                      if (/[a-zA-Z]/.test(pastedData)) {
                        event.preventDefault(); // Block paste if alphanumeric characters are found
                      }
                    }}
                    data-id={id}
                    data-section={section}
                    name={name}
                    onChange={CreateInputData}
                    defaultValue={value}
                  />
                </div>
              </div>
            ))}
          </tr>

        </div>
      </div>

    </div>
  );
};

export default Outputs;
