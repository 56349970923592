import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Table } from "reactstrap";
import "../../App.css";
import DataService from "../../../services/ApiService";

const CavitySelection = ({ session, Cavities, onClose }) => {
  const [modalOpen, setModalOpen] = useState(true); // Default open modal
  const [checkboxes, setCheckboxes] = useState(
    Cavities.map(() => ({
      all: true,
      calculate: true,
      display: true,
    }))
  );

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (onClose) onClose();
  };

  const handleSubmit = () => {
    const selectedDisplay = [];
    const excludedDisplay = [];
    const selectedCalculate = [];
    const excludedCalculate = [];

    Cavities.forEach((item, index) => {
      const unit = item[0].replace("dimension~", "");
      if (checkboxes[index].display) selectedDisplay.push(unit);
      else excludedDisplay.push(unit);

      if (checkboxes[index].calculate) selectedCalculate.push(unit);
      else excludedCalculate.push(unit);
    });

    const data = {
      session_id: session,
      display: selectedDisplay,
      excludeDisplay: excludedDisplay,
      calculate: selectedCalculate,
      excludeCalaulate: excludedCalculate,
    };

    DataService.PostNumericalPrediction(session, data);
    toggleModal();
  };

  const handleAllChange = (index) => {
    setCheckboxes((prev) => {
      const newCheckboxes = [...prev];
      const allChecked = !newCheckboxes[index].all;

      newCheckboxes[index] = {
        all: allChecked,
        calculate: allChecked,
        display: allChecked,
      };

      return newCheckboxes;
    });
  };

  const handleCheckChange = (index, type) => {
    setCheckboxes((prev) => {
      const newCheckboxes = [...prev];
      newCheckboxes[index][type] = !newCheckboxes[index][type];

      // Check if both 'calculate' and 'display' are checked
      newCheckboxes[index].all = newCheckboxes[index].calculate && newCheckboxes[index].display;

      return newCheckboxes;
    });
  };

  const handleSelectAll = () => {
    setCheckboxes(
      Cavities.map(() => ({
        all: true,
        calculate: true,
        display: true,
      }))
    );
  };

  return (
    <Modal isOpen={modalOpen} toggle={toggleModal} size="lg" centered>
      <ModalHeader toggle={toggleModal}>Dimension and Cavity Selection</ModalHeader>
      <ModalBody>
        <Button color="secondary" onClick={handleSelectAll} className="mb-3">
          Select All
        </Button>
        <Table bordered hover>
          <thead>
            <tr>
              <th>Dimension</th>
              <th style={{ textAlign: "center" }}>All</th>
              <th style={{ textAlign: "center" }}>Calculate</th>
              <th style={{ textAlign: "center" }}>Display</th>
            </tr>
          </thead>
          <tbody>
            {Cavities.map((item, index) => (
              <tr key={index}>
                <td>
                  {item[0].replace(/dimension~/g, "").charAt(0).toUpperCase() +
                    item[0].replace(/dimension~/g, "").slice(1)}
                </td>
                <td style={{ textAlign: "center" }}>
                  <input
                    type="checkbox"
                    id={`all-${index}`}
                    checked={checkboxes[index]?.all || false}
                    onChange={() => handleAllChange(index)}
                  />
                </td>
                <td style={{ textAlign: "center" }}>
                  <input
                    type="checkbox"
                    id={`calculate-${index}`}
                    checked={checkboxes[index]?.calculate || false}
                    onChange={() => handleCheckChange(index, "calculate")}
                  />
                </td>
                <td style={{ textAlign: "center" }}>
                  <input
                    type="checkbox"
                    id={`display-${index}`}
                    checked={checkboxes[index]?.display || false}
                    onChange={() => handleCheckChange(index, "display")}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSubmit}>
          Submit
        </Button>
        <Button color="secondary" onClick={toggleModal}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CavitySelection;
